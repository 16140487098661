export class BookmarkFolder {
  id: number;
  name: string;
  documentsCount: number;
  lastModified: Date;
  containsDocument?: Boolean;

  constructor(id: number,
              name: string,
              documentsCount: number,
              lastModified: Date) {
    this.id = id;
    this.name = name;
    this.documentsCount = documentsCount;
    this.lastModified = lastModified;
  }

}

export enum OrderByBookmarkFolder {
  ENTITY_LABEL = 'entity_label',
  LAST_MODIFIED = 'last_modified',
  DOCUMENTS_COUNT = 'documents_count'
}

export enum BookmarkEntityType {
  FOLDER = 'folder',
  ARTICLE = 'article',
  ADMIN_DOC = 'admin_doc'
}
