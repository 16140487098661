import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { DbUserInteraction, DtoUserInteractionCreate } from '../../../models/user-interaction';
import { firstValueFrom } from 'rxjs';

export enum UserInteractionEvent {
  FEEDBACK = 'suggestion_feedback',
}

@Injectable({
  providedIn: 'root'
})
export class ApiUserInteractionService {

  constructor(
    private httpHeadersService: HttpHeadersService,
    private http: HttpClient
  ) { }

  createUserInteraction(uid: string, body: DtoUserInteractionCreate) {
    return firstValueFrom(this.http
      .put<{ identifiers: { uid: string, user_id: number }, message: string }>(
        `${environment.explainApiUrl}user_interactions`,
        {
          user_id: +(localStorage.getItem("user_id") ?? 0),
          uid,
          ...body
        } as DbUserInteraction,
        this.httpHeadersService.httpHeaders
      ));
  }

  deleteUserInteraction(uid: string) {
    return firstValueFrom(this.http
      .delete(
        `${environment.explainApiUrl}user_interactions`,
        {
          headers: this.httpHeadersService.httpHeaders.headers,
          params: {
            user_id: +(localStorage.getItem("user_id") ?? 0),
            uid
          }
        },
      ));
  }
}
