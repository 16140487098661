import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explicative-block-one',
  templateUrl: './explicative-block-one.component.html',
  styleUrls: ['./explicative-block-one.component.scss']
})
export class ExplicativeBlockOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
