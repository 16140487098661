import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[exEmptyStateContent]'
})
export class ExEmptyStateContentDirective {
  constructor(public template: TemplateRef<{ $implicit: TemplateRef<any> }>) {
  }
}

@Component({
  selector: 'ex-empty-state',
  templateUrl: './ex-empty-state.component.html',
  styleUrls: ['./ex-empty-state.component.scss']
})
export class ExEmptyStateComponent {
  @ContentChild(ExEmptyStateContentDirective) contentTemplate?: ExEmptyStateContentDirective;

  @Input({required: true}) svgIcon!: string;
  @Input({required: true}) title!: string;
  @Input() iconColor?: string = 'var(--basic-100)'
  @Input() paddingTop?: 'unset' | 'small' | 'medium' | 'large' = 'medium';

  protected readonly toolbar = toolbar;
}
