import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-near-type-guide-template',
  templateUrl: './near-type-guide-template.component.html',
  styleUrls: ['./near-type-guide-template.component.scss']
})
export class NearTypeGuideTemplateComponent implements OnInit {
  examples: Array<{text: string, correct: boolean}>;

  constructor() {
    this.examples = [
      {
        text: 'template.near-example-1',
        correct: true
      },
      {
        text: 'template.near-example-2',
        correct: true
      },
      {
        text: 'template.near-example-3',
        correct: false
      },
      {
        text: 'template.near-example-4',
        correct: false
      }
    ];
  }

  ngOnInit(): void {
  }

}
