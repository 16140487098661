import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-combination-type-guide-template',
  templateUrl: './combination-type-guide-template.component.html',
  styleUrls: ['./combination-type-guide-template.component.scss']
})
export class CombinationTypeGuideTemplateComponent implements OnInit {
  examples: Array<{text: string, correct: boolean}>;

  constructor() {
    this.examples = [
      {
        text: 'template.combination-example-1',
        correct: true
      },
      {
        text: 'template.combination-example-2',
        correct: true
      },
      {
        text: 'template.combination-example-3',
        correct: true
      },
      {
        text: 'template.combination-example-4',
        correct: false
      },
      {
        text: 'template.combination-example-5',
        correct: false
      },
      {
        text: 'template.combination-example-6',
        correct: false
      },
    ];
  }

  ngOnInit(): void {
  }

}
