<ex-dropdown noInput size="small">
  <ng-template exDropdownDisplay>
    <div class="perimeter-display" [ngClass]="{'perimeter-placeholder': !selectionCount}">
      <span>{{ 'tenders.filter.perimeter' | translate }}</span>
      <span class="perimeter-display-count">
        ({{
          (selectionCount ? 'tenders.filter.selected' : 'tenders.filter.all')
            | translate: {count: selectionCount, total: maxCount}
        }})
      </span>
    </div>
  </ng-template>
  <ng-template *ngIf="!alternativeDisplay; else errorRetrievingTerritories"
               exDropdownContent>
    <div class="territories-dp-content">
      <ngx-treeview [itemTemplate]="itemTemplate"
                    [headerTemplate]="headerTemplate"
                    [config]="config"
                    [items]="[items]"
                    [textChange]="filterText"
                    (selectedChange)="onFunctionChange()">
      </ngx-treeview>
    </div>
  </ng-template>

  <ng-template #errorRetrievingTerritories
               exDropdownContent>
    <div class="error-territories-message">
      {{ alternativeDisplay | translate }}
    </div>
  </ng-template>

</ex-dropdown>

<ng-template #headerTemplate let-config="config" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="filter-search-container">
    <input class="form-control location-search" type="text"
           [placeholder]="'tenders.search' | translate"
           [(ngModel)]="filterText"
           (ngModelChange)="onFilterTextChange($event)"/>
    <img *ngIf="!filterText" src="/assets/images/search.svg" class="icon-filter filter-basic-600" alt="search-icon"/>
    <img *ngIf="filterText" src="/assets/images/icon-close-circle-fill.svg" class="icon-filter cursor-pointer"
         (click)="$event.stopPropagation(); onFilterTextChange(''); filterText=''" alt="clear-icon"/>
  </div>
  <div class="filter-reset-container">
    <div class="perimeter-display">
      <span>{{ 'tenders.filter.perimeter' | translate }}</span>
      <span class="perimeter-display-count">
      ({{
          (selectionCount ? 'tenders.filter.selected-perimeter' + (selectionCount > 1 ? 's' : '') : 'tenders.filter.all')
            | translate: {count: selectionCount, total: maxCount}
        }})
    </span>
    </div>
    <div class="items-reset" [ngClass]="{'disabled': !selectionCount}" (click)="resetPerimeter()">
      <mat-icon svgIcon="refresh"/>
      <span>{{ 'tenders.filter.reset' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #itemTemplate
             let-territoryItem="item"
             let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange">
  <div class="row-item no-root"
       [hidden]="hideItem(territoryItem)"
       [ngClass]="{'root-item': territoryItem.isRoot}">
    <div class="left-group">
      <i *ngIf="territoryItem.children"
         (click)="$event.stopPropagation(); onCollapseExpand()" aria-hidden="true"
         [ngSwitch]="territoryItem.collapsed">
        <img *ngSwitchCase="true" src="assets/images/arrow-ios-right.svg" alt="arrow-ios-right"/>
        <img *ngSwitchCase="false" src="assets/images/arrow-ios-down.svg" alt="arrow-ios-down"/>
      </i>
      <div *ngIf="!territoryItem.isRoot" class="treeview-path"></div>
    </div>
    <div class="form-check" [ngClass]="{'node-root': territoryItem.isRoot}">
      <label  class="align-self-center checkbox"
              [ngClass]="{'last-node': !territoryItem.children}">

        <input type="checkbox" class="form-check-input"
               [checked]="territoryItem.checked" [(ngModel)]="territoryItem.checked" (change)="onCheckedChange();"
               [disabled]="territoryItem.disabled" [indeterminate]="territoryItem.indeterminate"
        />

        <span class="checkbox-text"
              [ngClass]=""
              [innerHTML]="territoryItem.text | emphasizeFilter: filterText">
                  </span>

        <span class="checkmark"
              ngbTooltip="{{'alert.tooltip-all-select' | translate}}"
              placement="auto" container="body" [disableTooltip]="!territoryItem.isRoot"></span>
      </label>
    </div>
  </div>
</ng-template>
