import { Injectable } from '@angular/core';
import { ApiArticleService } from './articles/api-article.service';
import { ApiAdminDocService } from './api-admin-doc.service';
import { ApiImpacterService } from './impacter/api-impacter.service';
import { ApiShareService } from './share/api-share.service';
import { ApiTerritoryService } from './territory/api-territory.service';
import { ApiBookmarkService } from './bookmark-service/api-bookmark.service';
import { ApiTerritoryWatchService } from './territory-watches/api-territory-watch.service';
import { ApiTopicService } from './topic/api-topic.service';
import { ApiExStatisticsService } from './ex-statistics/api-ex-statistics.service';
import { ApiUserService } from './user/api-user.service';
import { ApiUserTerritoryWatchService } from './user-territory-watches/api-user-territory-watches.service';
import { ApiTerritoryWatchSendingService } from './territory-watches-sending/api-territory-watches-sending.service';
import { ApiExportAdminDocService } from './export/api-export-admin-doc.service';
import { ApiProjectService } from './projects/api-project.service';
import { ApiAccountService } from "./account/api-account.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private _articleService: ApiArticleService,
    private _adminDocService: ApiAdminDocService,
    private _impacterService: ApiImpacterService,
    private _territoryService: ApiTerritoryService,
    private _shareService: ApiShareService,
    private _bookmarkService: ApiBookmarkService,
    private _territoryWatchService: ApiTerritoryWatchService,
    private _topicService: ApiTopicService,
    private _apiExStatistics: ApiExStatisticsService,
    private _apiUserService: ApiUserService,
    private _apiUserTerritoryWatchesService: ApiUserTerritoryWatchService,
    private _apiTerritoryWatchSendingService: ApiTerritoryWatchSendingService,
    private _apiExportAdminDoc: ApiExportAdminDocService,
    private _apiProject: ApiProjectService,
    private _apiAccountService: ApiAccountService
  ) {
  }

  get article(): ApiArticleService {
    return this._articleService;
  }

  get adminDoc(): ApiAdminDocService {
    return this._adminDocService;
  }

  get impacter(): ApiImpacterService {
    return this._impacterService;
  }

  get territory(): ApiTerritoryService {
    return this._territoryService;
  }

  get share(): ApiShareService {
    return this._shareService;
  }

  get bookmark(): ApiBookmarkService {
    return this._bookmarkService;
  }

  get territoryWatch(): ApiTerritoryWatchService {
    return this._territoryWatchService;
  }

  get topic(): ApiTopicService {
    return this._topicService;
  }

  get exStatistics(): ApiExStatisticsService {
    return this._apiExStatistics;
  }

  get user(): ApiUserService {
    return this._apiUserService;
  }

  get userTerritoryWatches(): ApiUserTerritoryWatchService {
    return this._apiUserTerritoryWatchesService;
  }

  get territoryWatchSending(): ApiTerritoryWatchSendingService {
    return this._apiTerritoryWatchSendingService;
  }

  get exportAdminDoc(): ApiExportAdminDocService {
    return this._apiExportAdminDoc;
  }

  get project(): ApiProjectService {
    return this._apiProject;
  }

  get account(): ApiAccountService {
    return this._apiAccountService;
  }

}
