import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import {
  DbMarketWatch,
  DtoMarketWatchCreate,
  DtoMarketWatchUpdate
} from '../../../models/market-watch';
import { MarketWatchAppearance } from "../../../models/tender";


@Injectable({
  providedIn: 'root'
})
export class ApiMarketWatchService {

  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) { }

  getUserMarketWatches(fields: string[], filters: {}): Promise<DbMarketWatch[]> {
    return firstValueFrom(this.http
      .post<{ data: DbMarketWatch[] }>(
        `${environment.explainApiUrl}market_watches`,
        {fields, filters},
        this.httpHeadersService.httpHeaders
      ).pipe(map(response => response.data))
    );

  }

  updateMarketWatch(marketWatchId: number, body: DtoMarketWatchUpdate) {
    return firstValueFrom(this.http
      .patch(
        `${environment.explainApiUrl}market_watches`,
        {
          id: marketWatchId,
          ...body
        },
        this.httpHeadersService.httpHeaders
      ));
  }

  retrieveOwnerUserIdentity(id: number): Promise<{name: string, email: string}> {
    return firstValueFrom(this.http
      .post<{data: {name: string, email: string}[]}>(
        `${environment.explainApiUrl}user`,
        { fields: ["name", "email"], filters: { id } },
        this.httpHeadersService.httpHeaders
      ).pipe(map(response => response.data[0]))
    );
  }

  createMarketWatch(body: DtoMarketWatchCreate) {
    return firstValueFrom(this.http
      .put<{market_watch_id: number, message: string}>(
        `${environment.explainApiUrl}market_watches`,
        body,
        this.httpHeadersService.httpHeaders
      ));
  }

  deleteMarketWatch(marketWatchId: number) {
    return firstValueFrom(this.http
      .delete(
        `${environment.explainApiUrl}market_watches`,
        {
          headers: this.httpHeadersService.httpHeaders.headers,
          params: {
            id: marketWatchId.toString()
          }
        }
      ));
  }

  deleteUserMarketWatch(marketWatchId: number) {
    return firstValueFrom(this.http
      .delete(
        `${environment.explainApiUrl}user_market_watches`,
        {
          headers: this.httpHeadersService.httpHeaders.headers,
          params: {
            market_watch_id: marketWatchId.toString(),
            user_id: localStorage.getItem('user_id') ?? 0
          }
        }
      ));
  }

  tenderAppearedInMarketWatches(tenderId: string) {
    const body = {
      fields: ["id", "tender_uid", "appeared_in_watch"],
      filters: { tender_uid: tenderId },
      limit: 1,
      offset: 0,
      paginate: false
    }

    return firstValueFrom(this.http
      .post<{data: [{appeared_in_watch: MarketWatchAppearance, id: number, tender_uid: string}]}>(
        `${environment.explainApiUrl}market_watch_tenders`,
        body,
        this.httpHeadersService.httpHeaders
      ))
      .catch(() => ({
        data: [
          {
            appeared_in_watch: null,
            id: null,
            tender_uid: tenderId
          }]
      }));
  }

}
