
export interface DbMention {
  article_section: string;
  name: string;
  start_offset: number;
  end_offset: number;
}

export class Mention {

  articleSection: string;
  name: string;
  startOffset: number;
  endOffset: number;

  constructor(dbMention: DbMention) {
    this.articleSection = dbMention.article_section;
    this.name = dbMention.name;
    this.startOffset = dbMention.start_offset;
    this.endOffset = dbMention.end_offset;
  }
}
