<ex-dropdown
  #dropdownComponent
  class="filter-text-and-items-container"
  [inputText]="displayText"
  [placeholder]="placeholder"
  [forceToggle$]="forceToggle$"
  (dropdownToggle)="onToggleChange($event)"
  (textChange)="textChangeFromDropdown($event)"
  boldInputText
  inputCursor="text"
  normalPlaceholderFontWeight="{{searchText.length}}"
  setCursorToEnd
  shadowBorder
  size="small"
  smallLeftMarginInput
  strictWidth
>

  <ng-template exDropdownDisplayLeftPart>
    <div class="d-flex">
      <i class="ph ph-magnifying-glass d-flex flex-center-center"></i>
      <div *ngIf="displayText.length && !dropdownComponent.show && selectedText.value" class="pdl-4px">{{ 'common.filters.text-and-items.text' | translate }} :</div>
      <div *ngIf="displayText.length && !dropdownComponent.show && selectedItem.value" class="pdl-4px">{{ itemsSectionName | articleTitleCase }} : </div>
    </div>
  </ng-template>

  <ng-template exDropdownDisplayClearIcon>
    <div class="d-flex">
      <i class="ph ph-x d-flex flex-center-center"></i>
    </div>
  </ng-template>

  <ng-template exDropdownContent>
    <div class="dropdown-search-content d-flex flex-column">
      <div class="section-title">{{ 'common.filters.text-and-items.text' | translate | uppercase}}</div>
      <div class="search-section selectable-entity one-line-ellipsis"
           [ngClass]="{'focused': focusIndex == -1}"
           (click)="selectText(searchText); dropdownComponent.show = false">
        <span class="fw-600">{{ 'common.filters.text-and-items.search' | translate }}</span>
        <span [innerHTML]="searchText | emphasizeFilter: searchText"></span>
      </div>
      <div class="vertical-line w-100"></div>
      <div #itemsContainer class="scrollable-section d-flex flex-column">
        <div class="section-title">{{ itemsSectionName | uppercase }}</div>
        <div class="selectable-entity" *ngFor="let item of filteredItems; let i = index"
             [ngClass]="{'focused': focusIndex == i}"
             (click)="selectItem(item); dropdownComponent.show = false">
          <div class="one-line-ellipsis fw-600"
               [innerHTML]="item.name | emphasizeFilter: searchText"
               overflowTooltip
               tooltipClass="tender-title-tooltip"
               [openDelay]="500"
               [ngbTooltip]="item.name"
               placement="bottom auto"
          ></div>
          <div *ngIf="item.description"
               class="one-line-ellipsis fst-italic"
               [innerHTML]="item.description | emphasizeFilter: searchText"
               overflowTooltip
               tooltipClass="tender-title-tooltip"
               [openDelay]="500"
               [ngbTooltip]="item.description"
               placement="bottom auto"
          ></div>
        </div>
        <div *ngIf="filteredItems?.length === 0" class="no-result color-basic-600">
          {{'common.filters.text-and-items.no-result-masculine' | translate : {entityName: itemsSectionName.slice(0, -1)} | articleTitleCase }}</div>
      </div>
    </div>
  </ng-template>


</ex-dropdown>
