export interface DbTerritoryWatchStatus
{
  id: number;
  territory_watch_name: string;
  territory_watch_message: string;
}

export class TerritoryWatchStatus {
  id: number;
  name: string;
  message: string;

  constructor(
    territory_watch_id: number,
    territory_watch_name: string,
    message: string) {
    this.id = territory_watch_id;
    this.name = territory_watch_name;
    this.message = message;
  }

}
