<ex-dropdown placeholder="{{'tenders.topic-filter-placeholder' | translate}}"
             noInput size="small" [flatSide]="multiSearchView ? 'left' : undefined"
             [forceToggle$]="forceToggle$" [shadowBorder]="multiSearchView">
  <ng-template exDropdownDisplay>
    <div class="topic-display w-100" [ngClass]="{'topic-placeholder': !selectedIds.length}">
      <span class="flex-grow-1" *ngIf="!selectedIds.length">{{'tenders.topic-filter-placeholder' | translate}}</span>
      <span class="flex-grow-1 w-100 topic-name"
            *ngIf="selectedIds.length">{{ firstSelectedTopicName }}</span>
      <span *ngIf="selectedIds.length > 1" class="topic-display-count flex-grow-1">
        (+{{selectedIds.length - 1}})
      </span>
    </div>
  </ng-template>
  <ng-template exDropdownContent>
    <div class="topics-dp-content">
      <ngx-treeview [itemTemplate]="itemTemplate"
                    [headerTemplate]="headerTemplate"
                    [config]="config"
                    [items]="treeviewItems"
                    [textChange]="filterText"
                    (selectedChange)="onFunctionChange()">
      </ngx-treeview>
    </div>
  </ng-template>

</ex-dropdown>


<ng-template #headerTemplate let-config="config" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="filter-search-container">
    <input class="form-control topic-search" type="text"
           [placeholder]="'tenders.search' | translate"
           [(ngModel)]="filterText"
           (ngModelChange)="onFilterTextChange($event)"/>
    <img *ngIf="!filterText" src="/assets/images/search.svg" class="icon-filter filter-basic-600" alt="search-icon"/>
    <img *ngIf="filterText" src="/assets/images/icon-close-circle-fill.svg" class="icon-filter cursor-pointer"
         (click)="$event.stopPropagation(); onFilterTextChange(''); filterText=''" alt="clear-icon"/>
  </div>
  <div class="filter-reset-container">
    <div class="topic-display">
      <span>{{ 'tenders.filter.topics' | translate }} </span>
      <span class="topic-display-count">
       ({{ 'tenders.filter.selected-topic' + (selectedIds.length > 1 ? 's' : '') | translate: {count: selectedIds.length, total: this.NUMBER_TOPICS_MAX} }})
    </span>
    </div>
    <div class="items-reset" [ngClass]="{'disabled': !selectedIds.length}"
         (click)="reset()">
      <mat-icon svgIcon="refresh"/>
      <span>{{ 'tenders.filter.reset' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #itemTemplate
             let-topicItem="item"
             let-onCheckedChange="onCheckedChange">
  <div class="wrapper row-item w-100"
       [ngClass]="{'item-disabled': topicItem.disabled}"
       [hidden]="hideItem(topicItem)"
       (click)="$event.stopPropagation(); onCheckedChange();">
    <div class="d-flex align-items-center wrapped-content w-100">
      <label class="checkbox d-flex w-100">
        <input type="checkbox"
               class="form-check-input"
               [(ngModel)]="topicItem.checked"
               [checked]="topicItem.checked"
               [disabled]="topicItem.disabled"
               [indeterminate]="topicItem.indeterminate"
               (change)="onCheckedChange()"
        />
        <span class="checkmark"></span>
        <span class="form-check-label text-truncate"
              [ngClass]="{'fst-italic': !topicItem.checked && maxTopicsReached}"
              [innerHTML]="topicItem.text | emphasizeFilter: filterText"></span>
      </label>
    </div>
  </div>
</ng-template>
