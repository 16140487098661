import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOutlineNone]'
})
export class OutlineNoneDirective {

  constructor(ref: ElementRef) {
    ref.nativeElement.style.outline = 'none';
  }

}
