import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegexpSearch } from "../../../shared/helpers/regex";
import { MW_MAX_USERS } from "../../../tenders/models/market-watch";

export interface WatchUser {
  id: number;
  name: string;
  email?: string;
  is_market_watch_user?: boolean;
}
@Component({
  selector: 'ex-watch-users-selector',
  templateUrl: './ex-watch-users-selector.component.html',
  styleUrls: ['./ex-watch-users-selector.component.scss']
})
export class ExWatchUsersSelectorComponent {
  protected readonly MW_MAX_USERS = MW_MAX_USERS;
  filterText = '';
  @Input({required: true}) marketWatchUsers!: WatchUser[];
  @Output() selectedUsersUpdate = new EventEmitter<WatchUser[]>();

  get eligibleUsers(): WatchUser[] {
    return this.marketWatchUsers.filter(user => !user.is_market_watch_user);
  }
  get selectedUsers(): WatchUser[] {
    return this.marketWatchUsers.filter(user => user.is_market_watch_user);
  }

  get filteredEligibleUsers(): WatchUser[] {
    return this.eligibleUsers.filter(user => this.searchFunction(user)).sort((a, b) => a.name.localeCompare(b.name));
  }

  get filteredSelectedUsers(): WatchUser[] {
    return this.selectedUsers.filter(user => this.searchFunction(user)).sort((a, b) => a.name.localeCompare(b.name));
  }

  searchFunction(user: WatchUser): boolean {
    return RegexpSearch.normalizedSearchWithAccents(`${user.name}`, this.filterText) ||
      RegexpSearch.normalizedSearchWithAccents(`${user.email}`, this.filterText);
  }

  addWatchUser(user: WatchUser) {
    this.marketWatchUsers[this.marketWatchUsers.indexOf(user)].is_market_watch_user = true;
    this.selectedUsersUpdate.emit(this.marketWatchUsers)
  }
  removeWatchUser(user: WatchUser) {
    this.marketWatchUsers[this.marketWatchUsers.indexOf(user)].is_market_watch_user = false;
    this.selectedUsersUpdate.emit(this.marketWatchUsers)
  }

}
