import { DbTenderTerritory } from '../../models/tender-territory';
import { TreeItem, TreeviewItem } from '../../../lib/ngx-treeview';
import { TerritoryKind } from '../../../core/territory-kind/territory-kind.enum';

export function buildTenderTerritoriesTreeview(tenderTerritory: DbTenderTerritory): TreeviewItem {
  const treeviewItem = new TreeviewItem(
    {text: tenderTerritory.name || '', value: tenderTerritory.kind + tenderTerritory.code}
  );
  console.log(treeviewItem);
  if (tenderTerritory?.territories?.length) {
    treeviewItem.children = tenderTerritory?.territories.map(dbTenderTerritory => {
      return buildTenderTerritoriesTreeview(dbTenderTerritory);
    });
    console.log(treeviewItem);
  }
  return treeviewItem;
}

export function buildTenderTerritoriesTreeItems(tenderTerritory: DbTenderTerritory, checked?: boolean): TreeItem {
  let displayName = (tenderTerritory.code + ' - ' + tenderTerritory.name) || '';
  if (tenderTerritory.kind === TerritoryKind.REGION) {
    displayName = tenderTerritory.name || '';
  }
  const treeItem: TreeItem = {
    text: displayName,
    value: tenderTerritory.kind + tenderTerritory.code,
    collapsed: true,
    checked: checked,
  };
  if (tenderTerritory?.territories?.length) {
    treeItem.children = tenderTerritory?.territories.map(dbTenderTerritory => {
      return buildTenderTerritoriesTreeItems(dbTenderTerritory, checked);
    });
  }
  return treeItem;
}
