<div class="expansion-content">
  <div class="w-100">
    <div class="padding-top-16 sub-title">
      {{'share.addressee' | translate | uppercase}}
    </div>
    <div class="input-container" appClickOutside (clickOutside)="clickOutside()" (click)="toggleDropdown(true, true, $event)">
      <div class="input input-no-focus" data-cy="share-panel-input-user" [ngClass]="{'input-focus':inputFocus}">
        <ng-container *ngIf="inputFocus" (click)="toggleDropdown()">
          <span [ngClass]="{
            'user-infos': index !== indexAddressToEdit,
            'user-selected-no-focus' : index === indexAddressToEdit
            }" (click)="changeEditMode(user, index, $event)" *ngFor="let user of selectedUsers let index = index">
            <ng-container *ngIf=" index !== indexAddressToEdit">
              <span  class="user-selected-focus">{{user.email}}</span>
              <span  class="user-remove" (click)="removeRecipient(user.email,$event)">
                <img class="remove-plus-icon" src="assets/images/icon-close.svg" alt="icon-close"/>
              </span>
            </ng-container>
            <ng-container *ngIf=" index === indexAddressToEdit">
              <span appClickOutside (clickOutside)="searchUser()">
                <input #edit
                       autofocus
                       appOutlineNone
                       type="text"
                       value="{{user.email}}"
                       (input)="onChange(true)"
                       (keyup.space)="searchUser($event)"
                       (keyup.enter)="searchUser($event)"
                       class="addressee-input edit-user"
                       data-cy="sharing-panel-addressee-input-edit-selector"
                       [size]="edit.value.length+1"
                />
              </span>
            </ng-container>
          </span>
        </ng-container>
        <ng-container *ngIf="!inputFocus" (click)="toggleDropdown()">
          <span *ngIf="selectedUsers.length>0" class="user-selected-no-focus">{{selectedUsers[0].email}}</span>
          <span *ngIf="selectedUsers.length===2" class="user-selected-no-focus">,</span>
          <span *ngIf="selectedUsers.length===2" class="user-selected-no-focus">{{selectedUsers[1].email}}</span>
          <span *ngIf="selectedUsers.length>2"
                class="more-selected-no-focus"
                [ngbTooltip]="tooltipMoreEmails"
                tooltipClass="custom-tooltip-emails-list"
                placement="right"
                container="body"
          >
            + {{selectedUsers.length-1}} {{'share.more-recipients' | translate }}
          </span>
        </ng-container>
        <input #autoInput
               appOutlineNone
               type="text"
               (keydown)="onKeyDown($event)"
               (input)="onChange(false)"
               (click)="toggleDropdown(true)"
               (keyup.enter)="searchUser($event)"
               (keyup.space)="searchUser($event)"
               (keyup.arrowDown)="move('down')"
               (keyup.arrowUp)="move('up')"
               [placeholder]="!selectedUsers.length ? ('share.addressee-example' | translate) : ''"
               class="addressee-input"
               data-cy="sharing-panel-addressee-input-auto-input-selector"
        />
      </div>
      <ng-template #tooltipInfo>
        <span>{{'share.tooltipInfo1' | translate}}</span>
        <br/>
        <br/>
        <span>{{'share.tooltipInfo2' | translate}}</span>
      </ng-template>
      <table aria-roledescription="list of possible addressee" *ngIf="addresseeDropdownMenuDisplayed" class="user-table">
        <thead class="user-table-header">
        <tr class="user-table-row" data-cy="share-panel-user-table-row">
          <th *ngIf="noGuestUser" class="d-flex align-items-center">
            {{'share.addressees' | translate | uppercase}}
            <mat-icon class="icon-info d-flex" svgIcon="info"
                      [ngbTooltip]="'share.explain-only' | translate"
                      tooltipClass="share-tooltip-default"
                      placement="bottom auto"
                      container="body">
            </mat-icon>
          </th>
          <ng-container *ngIf="!noGuestUser">
            <th>
              {{'share.addressees' | translate | uppercase}}
            </th>
            <th class="d-flex flex-row align-items-center">
              {{'share.credits' | translate | uppercase}}
              <mat-icon class="icon-info d-flex" svgIcon="info"
                        [ngbTooltip]="tooltipInfo"
                        tooltipClass="tooltip-bottom-middle-right"
                        placement="bottom-right"
                        container="body">
              </mat-icon>
            </th>
          </ng-container>
        </tr>
        </thead>
        <tbody #users id="users" data-cy="tbody-users">
        <div *ngIf="!noGuestUser" (click)="searchUser()" class="new-dest"   [ngClass]="{
          'active': indexSelected === -1 ,
          'new-addressee-disabled' : indexSelected >= 0
           }">
          <div class="new-addressee-label">
            <span>{{'share.new-addressee' | translate | uppercase}}</span>
          </div>
          <div class="new-addressee-value-credits">
            <div class="new-addressee">
              <div data-cy="sharing-panel-new-addressee-value" class="new-addressee-value">
                <span class="user-main-infos">{{inputText}}</span>
                <span class="new-addressee-placeholder" *ngIf="!inputText">{{'share.addressee-example' | translate }}</span>
                <span class="new-addressee-placeholder" *ngIf="inputText && !checkAddressContainsAtSymbol(inputText)">{{'share.addressee-example-after-at-symbol' | translate }}</span>
              </div>
            </div>
            <div *ngIf="errors.email" class="d-inline-flex align-items-center invalid-email">
              <span>{{'share.invalid-email' | translate}}</span>
            </div>
            <div *ngIf="errors.duplicatedEmail" class="d-inline-flex align-items-center invalid-email">
              <span>{{'share.existing-email' | translate}}</span>
            </div>
            <div class="user-credits"
                 [ngbTooltip]="getCreditTooltip(newUser)"
                 tooltipClass="share-tooltip-default tooltip-fixed-226"
                 placement="bottom"
                 container="body"
                 *ngIf="newUser"
            >
                <span *ngIf="newUser.newDest; else existingAddressee" class="unlimited-credit">
                  {{'share.new-addressee-short' | translate}}
                </span>
              <ng-template #existingAddressee>
                <ng-container *ngIf="isInSameCompany(newUser) || isGuestUser(newUser); else differentCompany">
                    <span *ngIf="isSimpleUser(newUser)" class="unlimited-credit">
                      {{'share.unlimited-credits-subscriber' | translate}}
                    </span>
                  <span *ngIf="isGuestUser(newUser)" [ngClass]="{
                       'credit-span': true,
                       'remaining-credit-color': newUser.userLevel.remaining_credits > 0,
                       'no-credit-color': newUser.userLevel.remaining_credits < 1
                     }">
                      {{newUser.userLevel.remaining_credits}}/{{newUser.userLevel.max_credit}}
                    </span>
                </ng-container>
                <ng-template #differentCompany>
                    <span class="unavailable-credit credit-span">
                      {{'share.unavailable' | translate}}
                    </span>
                </ng-template>
              </ng-template>
            </div>
            <div class="add-remove-column new-addressee-value">
              <span></span>
              <span *ngIf="newUser" class="add-user" [ngClass]="{'add-user-disabled': errors.email}" (click)="onSelectAddressee(newUser,$event)">
                  <img class="align-self-center remove-plus-icon filter-basic-600" src="assets/images/icon-plus-outline.svg" alt="icon-plus"/>
                </span>
              <span *ngIf="errors.email || errors.duplicatedEmail" class="error">
                   <img class="align-self-center remove-plus-icon" src="assets/images/alert-triangle.svg" alt="icon-plus"/>
                </span>
            </div>
          </div>
        </div>
        <!-- Recent recipients -->
        <div>
          <tr *ngIf="recentRecipients.length && !inputText" class="contact-list" data-cy="share-panel-recent-contact-list">
            <td>
                <span class="contact-list-label">
                  {{ 'share.recent-recipients' | translate | uppercase}}
                </span>
            </td>
          </tr>
        </div>
        <div *ngIf="recentRecipients.length && !inputText">
          <tr class="user-table-row option-item w-100"
              *ngFor="let user of recentRecipients; let index = index"
              (click)="onSelectAddressee(user, $event)"
              [ngClass]="{
              'disabled' : checkIfUserSelected(user.email)||(isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1)
              }"
              id="{{user.email}}"
          >
            <div [ngClass]="{
                'user-main': true,
                'user-selected': checkIfUserSelected(user.email)
                }"
                 appShowIfTruncated
                 [ngbTooltip]="tooltipEmailCaller"
                 tooltipClass="share-tooltip-default tooltip-max-300"
                 [disableTooltip]="true"
                 placement="bottom"
                 container="body"
            >
              <ng-template #tooltipEmailCaller>
                <ng-container *ngTemplateOutlet="tooltipEmail; context: {$implicit: user.email}"></ng-container>
              </ng-template>
              <ng-container *ngIf="isSimpleUser(user) && isInSameCompany(user); else noNameInTable">
                <span class="user-main-infos" [innerHTML]="user.fullName && (user.fullName | titlecase | highlight:autoInput.value)+'&nbsp'"></span>
                <span class="user-details" [innerHTML]="'(' + (user.email| highlight:autoInput.value) + ')'"></span>
              </ng-container>
              <ng-template #noNameInTable>
                  <span  [innerHTML]="user.email| highlight:autoInput.value" [ngClass]="{
                    'user-main-infos': true,
                    'crossed': isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1
                  }">
                  </span>
              </ng-template>
            </div>
            <div *ngIf="!noGuestUser">
              <div class="width-fit-content"
                   [ngbTooltip]="getCreditTooltip(user)"
                   tooltipClass="share-tooltip-default tooltip-fixed-226"
                   placement="bottom"
                   container="body">
                  <span *ngIf="user.newDest; else existingAddressee" class="unlimited-credit">
                    {{'share.new-addressee-short' | translate}}
                  </span>
                <ng-template #existingAddressee>
                  <ng-container *ngIf="isInSameCompany(user); else differentCompany">
                      <span *ngIf="isSimpleUser(user)" class="unlimited-credit">
                        {{'share.unlimited-credits-subscriber' | translate}}
                      </span>
                    <span *ngIf="isGuestUser(user)"
                          [ngClass]="{
                            'credit-span': true,
                            'remaining-credit-color': user.userLevel.remaining_credits > 0,
                            'no-credit-color': user.userLevel.remaining_credits < 1
                          }"
                    >
                        {{user.userLevel.remaining_credits}}/{{user.userLevel.max_credit}}
                      </span>
                  </ng-container>
                  <ng-template #differentCompany>
                      <span class="unavailable-credit credit-span">
                        {{'share.unavailable' | translate}}
                      </span>
                  </ng-template>
                </ng-template>
              </div>
            </div>
            <div class="add-remove-column">
                <span *ngIf="!checkIfUserSelected(user.email)" class="add-user add-user-hover hidden" (click)="onSelectAddressee(user, $event)">
                  <img class="align-self-center remove-plus-icon filter-basic-600" src="assets/images/icon-plus-outline.svg" alt="icon-plus"/>
                </span>
              <span *ngIf="checkIfUserSelected(user.email)" class="remove-user" (click)="removeRecipient(user.email, $event)">
                  <img class="align-self-center remove-plus-icon" src="assets/images/icon-close.svg" alt="icon-close"/>
                </span>
            </div>
          </tr>
          <tr *ngIf="noGuestUser && !(filteredOptions$ | async)?.length" class="no-result">
            {{'share.no-recipient-found' | translate}}
          </tr>
        </div>
        <!-- Recipients list -->
        <div>
          <tr *ngIf="!autoInput.value" (click)="displayOrHideContactList($event)"  class="contact-list cursor-pointer" data-cy="share-panel-contact-list">
            <td>
                <span class="contact-list-label">
                  {{(isContactListSelected ? 'share.contact-list-hide' : 'share.contact-list-display')| translate | uppercase}}
                </span>
            </td>
            <td class="contact-list-chevron">
              <mat-icon *ngIf="!isContactListSelected"  svgIcon="chevron-down"></mat-icon>
              <mat-icon *ngIf="isContactListSelected" svgIcon="chevron-up"></mat-icon>
            </td>
          </tr>
        </div>
        <div *ngIf="isContactListSelected || inputText !==''">
          <tr class="user-table-row option-item w-100"
              *ngFor="let user of filteredOptions$ | async; let index = index"
              (click)="onSelectAddressee(user, $event)"
              [ngClass]="{
              'active': index === indexSelected,
              'disabled' : checkIfUserSelected(user.email)||(isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1)
              }"
              id="{{user.email}}"
          >
            <div [ngClass]="{
                'user-main': true,
                'user-selected': checkIfUserSelected(user.email)
                }"
                 appShowIfTruncated
                 [ngbTooltip]="tooltipEmailCaller"
                 tooltipClass="share-tooltip-default tooltip-max-300"
                 [disableTooltip]="true"
                 placement="bottom"
                 container="body"
            >
              <ng-template #tooltipEmailCaller>
                <ng-container *ngTemplateOutlet="tooltipEmail; context: {$implicit: user.email}"></ng-container>
              </ng-template>
              <ng-container *ngIf="isSimpleUser(user) && isInSameCompany(user); else noNameInTable">
                <span class="user-main-infos" [innerHTML]="user.fullName && (user.fullName | titlecase | highlight:autoInput.value)+'&nbsp'"></span>
                <span class="user-details" [innerHTML]="'(' + (user.email| highlight:autoInput.value) + ')'"></span>
              </ng-container>
              <ng-template #noNameInTable>
                  <span  [innerHTML]="user.email| highlight:autoInput.value" [ngClass]="{
                    'user-main-infos': true,
                    'crossed': isInSameCompany(user) && isGuestUser(user) && user.userLevel.remaining_credits < 1
                  }">
                  </span>
              </ng-template>
            </div>
            <div *ngIf="!noGuestUser">
              <div class="width-fit-content"
                   [ngbTooltip]="getCreditTooltip(user)"
                   tooltipClass="share-tooltip-default tooltip-fixed-226"
                   placement="bottom"
                   container="body">
                  <span *ngIf="user.newDest; else existingAddressee" class="unlimited-credit">
                    {{'share.new-addressee-short' | translate}}
                  </span>
                <ng-template #existingAddressee>
                  <ng-container *ngIf="isInSameCompany(user); else differentCompany">
                      <span *ngIf="isSimpleUser(user)" class="unlimited-credit">
                        {{'share.unlimited-credits-subscriber' | translate}}
                      </span>
                    <span *ngIf="isGuestUser(user)"
                          [ngClass]="{
                            'credit-span': true,
                            'remaining-credit-color': user.userLevel.remaining_credits > 0,
                            'no-credit-color': user.userLevel.remaining_credits < 1
                          }"
                    >
                        {{user.userLevel.remaining_credits}}/{{user.userLevel.max_credit}}
                      </span>
                  </ng-container>
                  <ng-template #differentCompany>
                      <span class="unavailable-credit credit-span">
                        {{'share.unavailable' | translate}}
                      </span>
                  </ng-template>
                </ng-template>
              </div>
            </div>
            <div class="add-remove-column">
                <span *ngIf="!checkIfUserSelected(user.email)" class="add-user add-user-hover hidden" (click)="onSelectAddressee(user, $event)">
                  <img class="align-self-center remove-plus-icon filter-basic-600" src="assets/images/icon-plus-outline.svg" alt="icon-plus"/>
                </span>
              <span *ngIf="checkIfUserSelected(user.email)" class="remove-user" (click)="removeRecipient(user.email, $event)">
                  <img class="align-self-center remove-plus-icon" src="assets/images/icon-close.svg" alt="icon-close"/>
                </span>
            </div>
          </tr>
          <tr *ngIf="noGuestUser && !(filteredOptions$ | async)?.length" class="no-result">
            {{'share.no-recipient-found' | translate}}
          </tr>
        </div>
        </tbody>
      </table>
    </div>
  </div>
  <div class="message-part w-100">
    <div class="sub-title">
      {{'share.message' | translate | uppercase}}
      <span class="fst-italic"> {{'share.optional' | translate | lowercase}}</span>
    </div>
    <div class="container-textarea">
      <textarea appOutlineNone
                data-cy="sharing-panel-sharing-message-input"
                class="input-share-message"
                maxlength="1000"
                [(ngModel)]="shareMessage"
      >
      </textarea>
      <span class="counter-textarea">{{shareMessage.length}}/1000</span>
    </div>
    <div class="send-copy" >
      <span>{{'share.send-mail-copy' | translate}}</span>
      <label class="switch checkbox ">
        <input type="checkbox" (change)="onSendCopyChange()">
        <span  class="slider round"></span>
      </label>
    </div>
  </div>
  <mat-divider class="ignore-parent-padding color-basic-500"></mat-divider>
  <div class="validation-part w-100 d-table">
    <div class="w-100 d-table-cell"> </div>
    <div class="action d-table-cell align-middle"
         (click)="reset();cancel.emit()">{{'share.close' | translate | titlecase }}
    </div>
    <div class=" d-table-cell align-middle">
      <button class="btn-send" data-cy="sharing-panel-send-button"
              type="submit" onclick="this.blur()"
              (click)="onSubmit()"
              [disabled]="!selectedUsers.length">
        <span>{{'share.send' | translate | titlecase}}</span>
      </button>
    </div>
  </div>
</div>

<app-toast-message [createMessage]="noGuestUser ? 'share.toast-market' : 'share.toast-content'"></app-toast-message>
<ng-template  #tooltipMoreEmails>
  <ul class="emails-list">
    <li *ngFor="let user of selectedUsers" class="email">{{user.email}}</li>
  </ul>
</ng-template>
<ng-template #tooltipCreditLeft>
  <span>{{'share.tooltipCreditLeft1' | translate}}</span>
  <br/>
  <br/>
  <span>{{'share.tooltipCreditLeft2' | translate}}</span>
</ng-template>
<ng-template #tooltipNoCreditLeft>
  <span>{{'share.tooltipNoCreditLeft' | translate}}</span>
</ng-template>
<ng-template #tooltipCreditUnavailable>
  <span>{{'share.tooltipCreditUnavailable' | translate}}</span>
</ng-template>
<ng-template #tooltipEmail let-email>
  <span>{{email}}</span>
</ng-template>

<ng-template #sharingToast let-toast="toast" let-toastService="toastService">
  <div class="position-relative d-flex w-100 h-100">
    <div class="position-absolute toast-icon-div icon--close align-self-center" (click)="toastService.remove(toast)">
    </div>
    <img src="assets/images/icon-checkmark-circle.svg" class="align-self-center toast-image" alt="icon-checkmark">
    <div class="fw-bold align-self-center toast-text">
      <ng-container>
        <p class="m-0">{{(noGuestUser ? 'share.toast-market' : 'share.toast-content') | translate}}</p>
      </ng-container>
    </div>
  </div>
</ng-template>
