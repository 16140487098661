import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-explanation',
  templateUrl: './modal-explanation.component.html',
  styleUrls: ['./modal-explanation.component.scss']
})
export class ModalExplanationComponent {

  @Input() title!: string;
  @Input() content!: string;
  @Input() content2!: string;
  @Input() mailto!: string;
  @Input() btnName!: string;

  constructor(private activeModal: NgbActiveModal) {
  }

  close() {
    this.activeModal.close();
  }

}
