import { booleanAttribute, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TenderTopic } from '../../models/tender-topic';
import { TreeviewConfig, TreeviewItem } from "../../../lib/ngx-treeview";
import { SearchHelper } from "../../../shared/helpers/search.helper";

@Component({
  selector: 'app-filter-topic',
  templateUrl: './filter-topic.component.html',
  styleUrls: ['./filter-topic.component.scss']
})
export class FilterTopicComponent implements OnChanges {
  NUMBER_TOPICS_MAX = 5;

  @Input({required: true}) items!: TenderTopic[];
  @Input() defaultTopicIds?: number[];
  @Input({transform: booleanAttribute}) multiSearchView?: boolean;
  @Input() forceToggle$?: EventEmitter<boolean>;
  @Output() itemsSelected = new EventEmitter<number[] | null>();

  treeviewItems: TreeviewItem[] = [];
  config = TreeviewConfig.create({
    hasFilter: true,
    maxHeight: 420
  });
  selectedIds: number[] = [];
  firstSelectedTopicName = '';

  filterText = '';

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] && this.items.length) {
      this.setUpTreeviewItems();
    }
    if (changes['defaultTopicIds'] && this.items.length) {
      if (this.selectedIds == this.defaultTopicIds) return; // prevent infinite loop
      this.setUpTreeviewItems();
      this.selectedIds = this.defaultTopicIds || [];



    }
  }

  setUpTreeviewItems() {
    this.treeviewItems = [];
    const limitReached = this.selectedIds.length >= this.NUMBER_TOPICS_MAX;
    this.treeviewItems = this.items.map((topic: TenderTopic) => {
      const itemChecked =  this.defaultTopicIds?.includes(topic.id) ?? false;
      return new TreeviewItem({
          checked: itemChecked,
          collapsed: false,
          value: topic.id,
          text: topic.name,
          children: [],
          isRoot: false,
          disabled: limitReached && !itemChecked
        }
      );
    });
  }

  onFunctionChange() {
    if (!this.items.length || !this.treeviewItems.length || !this.defaultTopicIds) return;
    this.selectedIds = this.treeviewItems.filter((item) => item.checked)
      .map((item) => item.value);
    this.firstSelectedTopicName = this.treeviewItems.find((item) => item.checked)?.text ?? '';
    // We use item.disabled to set info on treeview.
    const limitReached = this.selectedIds.length >= this.NUMBER_TOPICS_MAX;
    this.treeviewItems.map((item) => item.disabled = limitReached && !item.checked);
    this.itemsSelected.emit(this.selectedIds);
  }

  get maxTopicsReached(): boolean {
    return this.selectedIds?.length > (this.NUMBER_TOPICS_MAX - 1);
  }

  isNoneSelected() {
    return this.selectedIds?.length === 0;
  }

  itemInSearch(text: string): boolean {
    return !!SearchHelper.searchRegExp(text, this.filterText);
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children && item.children.length > 0) {
      if (item.isRoot) {
        return false;
      } else {
        const childInSearch = item.children.some(x => this.itemInSearch(x.text));
        return !childInSearch && !this.itemInSearch(item.text);
      }
    } else {
      return !this.itemInSearch(item.text);
    }
  }

  reset() {
    if (!this.selectedIds.length) return;
    this.treeviewItems.forEach(item => item.setCheckedRecursive(false));
    this.onFunctionChange();
    this.filterText = '';
  }

}
