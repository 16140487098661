import { TerritoryForAccountInfo } from './territory/territory-utils';

/**
 * Interface describing Account info.
 * 02/09/2022: only carrying department territories allowed for the current user
 */
export interface DbAccountInfo {
  territories: Array<TerritoryForAccountInfo>;
}

/**
 * Class describing Account info.
 * 02/09/2022: only carrying territories (departments accessible for the account)
 */
export class AccountInfo {
  private _territories: Array<TerritoryForAccountInfo>;

  constructor(dbAccountInfo: DbAccountInfo) {
    this._territories = dbAccountInfo.territories;
  }

  get territories(): Array<TerritoryForAccountInfo> {
    return this._territories;
  }

  set territories(value: Array<TerritoryForAccountInfo>) {
    this._territories = value;
  }
}
