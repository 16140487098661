<i class="ph ph-link copy-btn" [ngbTooltip]="copyTooltip" #ngbTooltip="ngbTooltip"
   (click)="copy(); ngbTooltip.close(false); ngbTooltip.ngbTooltip=copiedTooltip; ngbTooltip.open()"
   tooltipClass="simple-tooltip fit" container="body" placement="right auto"
   (mouseleave)="ngbTooltip.ngbTooltip=copyTooltip" [openDelay]="500">
</i>

<ng-template #copyTooltip>
  {{'tenders.link-copy' | translate}}
</ng-template>

<ng-template #copiedTooltip>
  {{'tenders.link-copied' | translate}}
</ng-template>
