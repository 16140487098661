<div class="modal-header">
  <h4 class="modal-title">
    {{'tenders.market-watches.modal-unsubscribe-watch.title' | translate}}
  </h4>
  <button type="button" class="btn-close" aria-label="close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="modal-message">
    {{ 'tenders.market-watches.modal-unsubscribe-watch.message' | translate }}
  </div>
  <div class="modal-info">
    {{ 'tenders.market-watches.modal-unsubscribe-watch.info' | translate: {name: ownerUser.name, email: ownerUser.email} }}
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="cancel fw-bold" (click)="cancel()">{{ 'tenders.market-watches.modal-unsubscribe-watch.cancel' |translate}}
  </button>
  <button type="button" class="btn confirm-danger d-flex" (click)="confirm()">
    <span class="text-uppercase fw-bold align-self-center">{{'tenders.market-watches.modal-unsubscribe-watch.confirm' | translate}}</span>
  </button>
</div>
