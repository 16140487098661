<div *ngIf="noResult" class="h-100 no-result">
  <div class="h-100 container d-flex flex-column align-items-center justify-content-center">
    <div class="icon-no-result d-flex align-items-center">
      <i class="ph-bold ph-magnifying-glass"></i>
      <i class="ph-bold ph-x"></i>
    </div>
    <div class="text d-flex align-items-center">Aucun résultat pour cette recherche</div>
  </div>
</div>

<div class="loader"
     [ngClass]="{'not-synchronized': !isSynchronizedWithLoaderService,
                'show': isShown}">

  <div class="loader-shape d-table align-middle">

    <div class="align-middle"
      [ngClass]="{'d-table-cell' : !vertical,
                  'd-table-row' : vertical }">
      <svg class="loader-icon" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff" [style.width]="size + 'px'">
        <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate attributeName="r"
                     begin="1.5s" dur="3s"
                     values="6;22"
                     calcMode="linear"
                     repeatCount="indefinite"/>
            <animate attributeName="stroke-opacity"
                     begin="1.5s" dur="3s"
                     values="1;0" calcMode="linear"
                     repeatCount="indefinite"/>
            <animate attributeName="stroke-width"
                     begin="1.5s" dur="3s"
                     values="2;0" calcMode="linear"
                     repeatCount="indefinite"/>
          </circle>
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate attributeName="r"
                     begin="3s" dur="3s"
                     values="6;22"
                     calcMode="linear"
                     repeatCount="indefinite"/>
            <animate attributeName="stroke-opacity"
                     begin="3s" dur="3s"
                     values="1;0" calcMode="linear"
                     repeatCount="indefinite"/>
            <animate attributeName="stroke-width"
                     begin="3s" dur="3s"
                     values="2;0" calcMode="linear"
                     repeatCount="indefinite"/>
          </circle>
          <circle cx="22" cy="22" r="8">
            <animate attributeName="r"
                     begin="0s" dur="1.5s"
                     values="6;1;2;3;4;5;6"
                     calcMode="linear"
                     repeatCount="indefinite"/>
          </circle>
        </g>
      </svg>
    </div>

    <div class="fw-bold align-middle"
         [ngClass]="{'d-table-cell' : !vertical,
                     'd-table-row' : vertical }">
      <span>{{ this.loaderText | translate}}</span>
    </div>

  </div>

</div>
