<div class="watch-user-selector-container">
  <span>{{ 'tenders.watch-users.selector-explanation' | translate }}</span>
  <div class="input-container">
    <input class="search-user-input w-100" type="text"
           [placeholder]="'tenders.watch-users.filter-placeholder' | translate"
           [(ngModel)]="filterText"/>
    <i *ngIf="filterText.length" (click)="filterText=''" (keydown)="filterText=''" class="ph ph-x-circle"></i>
  </div>
  <div class="d-flex watch-users-container no-select">
    <div class="eligible-users-container">
      <div class="d-flex flex-column eligible-user-container">
        <div class="sticky-header">{{ 'tenders.watch-users.eligible-users-header' | translate }}</div>
        <div class="scroll-box">
          <button *ngFor="let user of filteredEligibleUsers"
                  class="d-flex eligible-user user justify-content-between"
                  (click)="addWatchUser(user)"
                  [disabled]="selectedUsers.length >= MW_MAX_USERS">
            <span [innerHTML]="user.name | emphasizeFilter : filterText"></span>
            <mat-icon svgIcon="arrow-right"></mat-icon>
          </button>
          <div class="not-found" *ngIf="eligibleUsers.length && filteredEligibleUsers.length === 0">
            {{ 'tenders.watch-users.no-eligible-found' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="selected-users-container no-select">
      <div class="d-flex flex-column selected-user-container">
        <div class="sticky-header d-flex justify-content-between">
          <span>{{ 'tenders.watch-users.selected-users-header' | translate }}</span>
          <span class="count {{selectedUsers.length < MW_MAX_USERS ? 'info' : 'danger'}}">{{selectedUsers.length}}/{{MW_MAX_USERS}}</span>
        </div>
        <div class="scroll-box position-relative">
          <button *ngFor="let user of filteredSelectedUsers"
                  class="d-flex selected-user user"
                  (click)="removeWatchUser(user)">
            <mat-icon svgIcon="arrow-left"></mat-icon>
            <span [innerHTML]="user.name | emphasizeFilter : filterText"></span>
          </button>
          <!--custom empty state-->
          <div *ngIf="selectedUsers.length === 0" class="w-100 h-100 empty-state">
            <mat-icon svgIcon="user-plus-fill"/>
            <div class="titre">{{ 'tenders.error-display.add-users.title' | translate }}</div>
            <div class="description">{{ 'tenders.error-display.add-users.message' | translate }}</div>
          </div>
          <div class="not-found" *ngIf="selectedUsers.length && filteredSelectedUsers.length === 0">
            {{ 'tenders.watch-users.no-subscriber-found' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
