<div class="modal-header">
  <h4 class="modal-title">{{'tenders.market-watches.modal-delete-watch.title' | translate}}</h4>
  <button type="button" class="btn-close" aria-label="close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div *ngIf="watchUsersLength > 1">{{'tenders.market-watches.modal-delete-watch.info-x' | translate : {count: watchUsersLength} }}</div>
  <div *ngIf="watchUsersLength === 1">{{'tenders.market-watches.modal-delete-watch.info-1' | translate }}</div>
  <div *ngIf="watchUsersLength === 0">{{'tenders.market-watches.modal-delete-watch.info-0' | translate }}</div>
</div>
<div class="modal-footer">
  <button type="button" class="cancel fw-bold" (click)="cancel()">{{ 'tenders.market-watches.modal-delete-watch.cancel' |translate}}
  </button>
  <button type="button" class="btn confirm-danger d-flex" (click)="confirm()">
    <span class="text-uppercase fw-bold align-self-center">{{'tenders.market-watches.modal-delete-watch.confirm' | translate}}</span>
  </button>
</div>
