export function dateFormater(date: Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day}-${hour}-${minute}-${second}`;
}

export function hasNonZeroTime(date?: Date) {
  if (!date) {
    return false;
  }
  // Obtenez les heures et les minutes de la date
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Vérifiez si les heures OU les minutes sont différentes de zéro
  return hours !== 0 || minutes !== 0;
}
