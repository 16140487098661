import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { DbPagination } from '../../../../models/pagination';
import { Observable } from 'rxjs';
import { MatchStem } from '../../../../models/match-stem';

interface ContextDataItem {
  docs: Array<{
    excerpt: string;
    id: string;
  }>;
  id: number;
}

interface ResponseFromApi {
  data: Array<ContextDataItem>;
  pagination: DbPagination;
}

@Injectable({
  providedIn: 'root'
})
export class ApiTerritoryWatchSendingService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  retrieveContextFromCollectiveOrder(collectiveOrderId: number): Observable<ContextDataItem> {
    const body = {
      'fields': [
        'id',
        'docs'
      ],
      'filters': {
        'id': collectiveOrderId
      },
      'limit': 1,
      'offset': 0
    };
    return this.http.post<any>(`${environment.explainApiUrl}collective_order`, body, this.headersService.httpHeaders)
      .pipe(map((response: ResponseFromApi) => {
        return response.data[0];
      }));
  }

  /** Method getting digestResults file and extracting admin doc matchStems */
  retrieveDocumentMatchStemsFromS3(s3DeduplicatedDigestResultsFileUrl: string | null | undefined, documentId: string): Observable<Array<MatchStem>|null> {
    return this.http
      .get<{
        id: number,
        digests_by_content_type: Array<{
          content_type: string,
          search_results: {
            data: Array<{
              id: string,
              match_stems: Array<MatchStem>
            }>
          }
        }>
      }>(`${s3DeduplicatedDigestResultsFileUrl}`, {responseType: 'json'})
      .pipe(first())
      .pipe(map((response:
                   {
                     id: number,
                     digests_by_content_type: Array<{
                       content_type: string,
                       search_results: {
                         data: Array<{
                           id: string,
                           match_stems: Array<MatchStem>
                         }>
                       }
                     }>
                   }) => {
        const adminDocResults = response.digests_by_content_type.filter(x => x.content_type !== 'articles');
        const findings: Array<{
          id: string,
          match_stems: Array<MatchStem>
        }> = [];
        adminDocResults.forEach((element) => {
          const found = element.search_results?.data.find(elem => elem.id === documentId);
          if (found) {
            findings.push(found);
          }
        });
        if (findings[0]) {
          return findings[0].match_stems;
        } else {
          return null;
        }
      }));
  }

  /** Method to retrieve S3DeduplicatedDigestResults file url from a collectiveOrderId */
  retrieveS3DeduplicatedDigestResultsFileUrlFromCollectiveOrderId(collectiveOrderId: number): Observable<string> {
    const baseUrl = environment.s3DigestResultsBaseUrl;
    return this.http
      .get<{ id: number, digest_id: number, s3_file_url: string }>(`${environment.explainApiUrl}collective_order/${collectiveOrderId}`, this.headersService.httpHeaders)
      .pipe(first())
      .pipe(map((response: { id: number, digest_id: number, s3_file_url: string }) => {
        const fileName = response.s3_file_url.split('/').pop();
        return `${baseUrl}/${fileName}`;
      }));
  }
}
