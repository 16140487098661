<div class="h-100 pdf-menus-section d-table">

  <div class="h-100 d-table-cell align-middle align-items-center">

    <div class="h-100 d-flex">

      <div class="h-100 d-table">
        <div class="zoom d-table-cell align-middle">
          <pdf-zoom-dropdown class="zoom-part" [zoomLevels]="zoomLevels"></pdf-zoom-dropdown>
        </div>
      </div>

      <div class="page-number-input-container d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="d-table">
            <input class="page-number-input d-table-cell align-middle"
                   [(ngModel)]="page"
                   (keydown.enter)="onPageEntered(page)">
            <div class="page-total-number d-table-cell align-middle">/{{nbPages}}</div>
          </div>
        </div>
      </div>

      <div class="d-table">
        <div class="icon d-table-cell align-middle">
          <mat-icon svgIcon="refresh-basic-800"
                    class="refresh-icon strict"
                    (click)="onRotatePdf()"></mat-icon>
        </div>

      </div>

    </div>

  </div>

</div>
