<div class="template-container">
  <div class="card-block">
    <div class="block-body d-flex">
      <div class="block-container">
        <div class="fw-bold title">{{'template.configuration' | translate}}</div>
        <div class="d-flex justify-content-between">
          <div class="term-block">
            <div class="fw-600">{{'template.photovoltaic' | translate}}</div>
            <div class="fw-600">{{'template.solar' | translate}}</div>
          </div>
          <div class="term-block">
            <div class="fw-600">{{'template.petition' | translate}}</div>
            <div class="fw-600">{{'template.unfavorable' | translate}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-footer">
      <div class="fw-bold title">{{'template.results' | translate}}</div>
      <div class="request-chain">
        <div>
          <div class="d-inline-block color-basic-600 fw-600 ps-0">(</div>
          <div class="d-inline-block fw-bold">{{'template.photovoltaic' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block fw-bold">{{'template.petition' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">(</div>
          <div class="d-inline-block fw-bold">{{'template.photovoltaic' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block fw-bold">{{'template.unfavorable' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">(</div>
          <div class="d-inline-block fw-bold">{{'template.solar' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block fw-bold">{{'template.petition' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">)</div>
        </div>
        <div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.or' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">(</div>
          <div class="d-inline-block fw-bold">{{'template.solar' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600">{{'content.near-by' | translate}}</div>
          <div class="d-inline-block fw-bold">{{'template.unfavorable' | translate}}</div>
          <div class="d-inline-block color-basic-600 fw-600 pe-0">)</div>
        </div>
      </div>
    </div>
  </div>
  <app-example-guide-template [examples]="examples"></app-example-guide-template>
</div>
