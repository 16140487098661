import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { DbProject, Project } from '../../../../models/territory-watch/project';

@Injectable({
  providedIn: 'root'
})
/**
 * Service managing all api calls for Project related features
 */
export class ApiProjectService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) {
  }

  getProjectByUid(projectUid: string, params?: HttpParams): Observable<Project> {
    return this.http
      .get(
        `${environment.explainApiUrl}projects/${projectUid}`,
        this.headersService.getHttpOptionsWithParams(params)
      )
      .pipe(
        map((response) => {
          return new Project(response as DbProject);
        }));
  }

  /** method allowing us to know how many other documents are related to a project */
  findProjectDocumentsRelatedToPrefectoralDecree(projectUid: string):
      Observable<{ data: Array<{ id: string, publication_date: Date }>, total_count: number }> {
    const body = {
      filters: {
        project_uid: projectUid
      },
      limit: 10,
      offset: 0,
      service: 'documents',
      sort: {
        dir: 'desc',
        field: 'publication_date'
      }
    };

    return this.http
        .post(
            `${environment.explainApiUrl}projects/find_project_documents`,
            body,
            this.headersService.httpHeaders)
        .pipe(
            map((response: any) => {
              return response;
            })
        );
  }

}
