import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortDate'
})
export class SortDatePipe  implements PipeTransform {
  transform(array: any[], field: string, reverse: boolean = false, forceUpdate = 0): any[] {
    if (!array) {
      return [];
    }
    array.sort((a: any, b: any) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      if (reverse) {
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (dateA < dateB) {
          return -1;
        } else if (dateA > dateB) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }
}
