import { TerritoryForArticle } from './territory/territory-utils';
import { TerritoryWatchProjectPublicationTypeEnum } from './territory-watch-settings';

/**
 * Structure of an TerritoryWatch-Document Preview.
 * snake_case attributes.
 */
export interface DbTerritoryWatchDocumentPreview {
  id: string;
  title: string;
  publication_date: Date;
  excerpt: string;
  source?: string;
  territories?: Array<TerritoryForArticle>;
  html?: {
    html_key: string;
    nb_images: number;
  };
  website?: string;
  url?: string;
  displayed_publication_type?: string;
  publication_type?: TerritoryWatchProjectPublicationTypeEnum[];
  project_uid?: string;
  project_title?: string;
  inquirers?: Array<string>;
  nb_docs?: number;
  data_provider?: string;
}

/**
 * Class describing an TerritoryWatch-Document preview.
 * camelCase attributes. Constructor bases on interface.
 */
export class TerritoryWatchDocumentPreview {

  id: string;
  title: string;
  publicationDate: Date;
  excerpt: string;
  source?: string;
  territories?: Array<TerritoryForArticle>;
  html?: {
    htmlKey: string;
    nbImages: number;
  };
  website?: string;
  url?: string;
  displayedPublicationType?: string;
  publicationType?: TerritoryWatchProjectPublicationTypeEnum;
  projectUid?: string;
  projectTitle?: string;
  inquirers?: Array<string>;
  nbDocs?: number;
  cardType?: string;
  dataProvider?: string;
  extra_data?: { // useful object for displaying cards
    data_provider: string;
    project_uid: string;
    source: string;
    display_helper: {
      card_type: string;
      icon_name: string;
      text: string;
    }
  };

  constructor(document: DbTerritoryWatchDocumentPreview) {
    this.id = document.id;
    this.title = document.title;
    this.publicationDate = document.publication_date;
    this.excerpt = document.excerpt;
    this.source = document?.source;
    this.territories = document?.territories;
    this.html = document.html ? {
      htmlKey: document.html.html_key,
      nbImages: document.html.nb_images
    } : undefined;
    this.website = document?.website;
    this.url = document?.url;
    this.displayedPublicationType = document?.displayed_publication_type;
    this.publicationType = document?.publication_type ? document?.publication_type[0] : undefined;
    this.projectUid = document?.project_uid;
    this.projectTitle = document?.project_title;
    this.inquirers = document?.inquirers;
    this.nbDocs = document?.nb_docs;
    this.cardType = 'default';
    this.dataProvider = document?.data_provider;
    this.extra_data = {data_provider: '', project_uid: '', source: '', display_helper: {card_type: '', icon_name: '', text: ''}};
  }
}

/**
 * Class describing the response body return by the endpoint retrieving territory-watch-Documents previews.
 */
export class TerritoryWatchDocumentPreviewResponseBody {
  territoryWatchDocumentPreviews: TerritoryWatchDocumentPreview[];
  territoryWatchPreviewPeriod: TerritoryWatchDocumentPreviewPeriod;
  totalCount: number;

  constructor(territoryWatchDocumentPreviews: TerritoryWatchDocumentPreview[],
              territoryWatchPreviewPeriod: TerritoryWatchDocumentPreviewPeriod,
              totalCount: number) {
    this.territoryWatchDocumentPreviews = territoryWatchDocumentPreviews;
    this.territoryWatchPreviewPeriod = territoryWatchPreviewPeriod;
    this.totalCount = totalCount;
  }
}

/**
 * Structure of an TerritoryWatch-Document preview period.
 * snake_case attributes.
 */
export interface DbTerritoryWatchDocumentPreviewPeriod {
  territory_watch_date: Date;
  from: Date;
  to: Date;
  nb_admin_doc: number;
  nb_project: number;
  nb_article: number;
}

/**
 * Class describing TerritoryWatch-Document preview period.
 * camelCase attributes. Constructor based on the interface.
 */
export class TerritoryWatchDocumentPreviewPeriod {
  territoryWatchDate: Date;
  from: Date;
  to: Date;
  nbAdminDoc: number;
  nbProject: number;
  nbArticle: number;

  constructor(dbTerritoryWatchDocumentPreviewPeriod: DbTerritoryWatchDocumentPreviewPeriod) {
    this.territoryWatchDate = dbTerritoryWatchDocumentPreviewPeriod.territory_watch_date;
    this.from = dbTerritoryWatchDocumentPreviewPeriod.from;
    this.to = dbTerritoryWatchDocumentPreviewPeriod.to;
    this.nbAdminDoc = dbTerritoryWatchDocumentPreviewPeriod.nb_admin_doc;
    this.nbProject = dbTerritoryWatchDocumentPreviewPeriod.nb_project;
    this.nbArticle = dbTerritoryWatchDocumentPreviewPeriod.nb_article;
  }
}
