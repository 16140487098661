import {Injectable} from '@angular/core';
import {firstValueFrom} from 'rxjs';
import {DbTender, Tender} from '../../../models/tender';
import {EventTypeName, HttpHeadersService} from '../http-headers/http-headers.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {DbCountStatusItem} from '../../../models/tender-status';

@Injectable({
  providedIn: 'root'
})
export class ApiTenderSearchService {

  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) {
  }

  retrieveTenders(body: any): Promise<{ data: Tender[], total_count: number }> {
    return firstValueFrom(this.http
      .post<{ data: DbTender[], total_count: number }>(
        `${environment.explainApiGatewayUrl}tender-search`,
        body,
        this.httpHeadersService.getTrackedHttpHeaders(EventTypeName.TENDER_SEARCH))
      .pipe(map((res) => {
          const tenders = res.data.map((tender) => {
            return new Tender(tender);
          });
          return {data: tenders, total_count: res.total_count};
        }
      ))
    );
  }

  retrieveCountByStatus(body: any): Promise<DbCountStatusItem[]> {
    return firstValueFrom(this.http
      .post<{ status_counts: DbCountStatusItem[] }>(
        `${environment.explainApiGatewayUrl}tender-search`,
        body,
        this.httpHeadersService.httpHeaders)
      .pipe(map(res => {
        return res.status_counts as DbCountStatusItem[];
      })));
  }
}

