<div class="term-{{termType}} term-block" [attr.data-cy]="'term-block-' + termType">
  <div class="term-container position-relative">
    <div class="text-center label-section">
      <div class="text-uppercase fw-bold title">{{'wizard.term-name' | translate}}</div>
    </div>
    <button *ngIf="terms.length" class="btn-term btn-select-all"
            (click)="onLockAll()"
            [ngbTooltip]="tooltipContent"
      placement="top" tooltipClass="tooltip-term-lock">
      <div *ngIf="!isLockAll" class="icon-block icon-term--unlock"></div>
      <div *ngIf="isLockAll" class="icon-block icon-term--lock"></div>
      <div class="icon icon--chevron-down"></div>
    </button>
    <button *ngIf="terms.length" class="btn-term btn-delete" (click)="onRemoveAll()"
      ngbTooltip="{{'content.delete-all-term' | translate}}">
      <div class="icon-block icon-block--trash"></div>
      <div class="icon icon--chevron-down"></div>
    </button>
    <div [ngClass]="{'term-body': terms.length || blockActive, 'option-section': !terms.length && optional}">
      <ng-container *ngIf="blockActive && !terms.length">
        <div class="d-flex h-100">
          <div *ngIf="optional && blockActive"
            class="fw-bold fst-italic term-label align-self-center text-center w-100">({{'wizard.optional' |
            translate}})</div>
          <div *ngIf="!optional" class="text-center w-100 align-self-center">
            <div class="d-inline-flex">
              <div class="align-self-center icon icon--triangle"></div>
              <div class="fw-bold fst-italic term-label align-self-center">{{'content.term-required' |
                translate}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngFor="let term of terms; let i = index">
        <div class="mt-2 mb-2 w-100">
          <div *ngIf="i==0">
            <div class="mt-3"></div>
          </div>
          <div class="row row-flex nopadding w-100 d-flex align-items-start justify-content-between">
            <div class="col-1 nopadding d-flex align-items-start justify-content-center">
              <div *ngIf="!term.locked" class="icon-unlock-v2 ms-1" (click)="onLockChange(i, true)"
                style="height: 100%;min-width:20px;max-width:100%;"> &nbsp;
              </div>
              <div *ngIf="term.locked" class="icon-lock-v2 ms-1" (click)="onLockChange(i, false)"
                style="height: 100%;min-width:20px;max-width:100%;"> &nbsp; </div>
              <div>
                <div class="line"></div>
              </div>

            </div>
            <div class="col-9 h-100 nopadding" style=" word-wrap: break-word;">
              <div class="term-text align-self-center" style=" word-wrap: break-word;">{{term['term']}}</div>
            </div>
            <div class="col-1 h-100 nopadding d-flex align-items-start justify-content-center">
              <mat-icon svgIcon="x" class="icon-term--close" (click)="onRemove(i)"/>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="term-footer div-behind-input" [ngbTooltip]="complexityLimiterAchieved ? ('wizard.terms-limiter-achieved' | translate) : ''"
         placement="bottom" triggers="click hover focus">
      <div class="position-relative" *ngIf="inputText">
        <button class="d-flex position-absolute" (click)="onEnter()">
          <div class="align-self-center fw-bold">{{'button.enter' | translate}}</div>
          <div class="align-self-center icon-term icon-term--corner-down-left"></div>
        </button>
      </div>
      <input type="text" placeholder="{{'wizard.term-placeholder' | translate}}"
             [attr.data-cy]="'term-block-' + termType + '-input'"
             [ngClass]="{'not-allowed': complexityLimiterAchieved}"
             [disabled]="complexityLimiterAchieved"
             [(ngModel)]="inputText"
             (ngModelChange)="onEnterGenerated($event)"
             (keyup.enter)="onEnter()">
    </div>
  </div>
</div>
<ng-template #tooltipContent>
  <div class="tooltip-content">
    <div>{{'content.tooltip-term-all-lock-title' | translate}}</div>
    <div class="d-flex">
      <div class="icon-term icon-term--unlock align-self-center"></div>
      <div class="trait align-self-center"></div>
      <div class="align-self-center word word--example">{{'content.close' | translate}}</div>
      <div class="icon-term icon-term--icon-arrow-right align-self-center"></div>
      <div class="align-self-center word" *ngFor="let example of listExample; let i = index">
        <span *ngIf="i > 0" class="pe-1">,</span>
        <span class="correct-word">{{example['word']}}</span>
      </div>
      <div class="word word--example">…</div>
    </div>
    <div class="d-flex">
      <div class="icon-term icon-term--lock align-self-center"></div>
      <div class="trait align-self-center"></div>
      <div class="align-self-center word word--example">{{'content.close' | translate}}</div>
      <div class="icon-term icon-term--icon-arrow-right align-self-center"></div>
      <div class="align-self-center word" *ngFor="let example of listExample; let i = index">
        <span *ngIf="i > 0" class="pe-1">,</span>
        <span [ngClass]="{'correct-word': example['correct'], 'incorrect-word': !example['correct']}">{{example['word']}}</span>
      </div>
      <div class="word word--example">…</div>
    </div>
  </div>
</ng-template>
