import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { DbTenderTerritory } from '../../../models/tender-territory';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiTenderTerritoryService {

  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) {
  }

  getRegions(): Observable<DbTenderTerritory[]> {
    return this.http.get<DbTenderTerritory[]>(
      `${environment.explainApiUrl}territory/region_tree/`,
      this.httpHeadersService.httpHeaders
    );
  }

  getDepartmentsByRegion(regionCode: string): Observable<DbTenderTerritory> {
    return this.http.get<DbTenderTerritory>(
      `${environment.explainApiUrl}territory/region_tree/${regionCode}/`,
      this.httpHeadersService.httpHeaders
    );
  }
}
