import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { UserSessionService } from "./shared/services/user-session.service";


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userSessionService: UserSessionService,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const userSession = this.userSessionService.userSession;
    const explainToken = this.userSessionService.explainToken;
    if (userSession || explainToken) { // version sans module
      // authorized so return true
      return true;
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], {queryParams: {fromUrl: this.location.path()}}).then();
    return false;
  }
}
