import { Injectable } from '@angular/core';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

export interface DbSearchingPeriod {
  from: string;
  to: string;
}

export enum TenderPublicationTypeEnum {
  APPEL_OFFRE = 'ao_avis_source',
}

export enum TenderServiceEnum {
  COUNT = 'count',
  SEARCH = 'search',
}

interface DbTenderCountBody {
  filters: {
    indexation_period: DbSearchingPeriod,
    publication_period: DbSearchingPeriod,
    publication_type: TenderPublicationTypeEnum[]
  },
  service: TenderServiceEnum
}

@Injectable({
  providedIn: 'root'
})
export class ApiTenderCountService {

  constructor(private httpHeadersService: HttpHeadersService,
              private http: HttpClient) { }

  /** Nombre de documents récupérées de type "Appel d'offre" au cours des N dernières heures  */
  retrieveTendersCount(numberOfHours = 6, date = new Date()) {
    const currentHour = new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours());
    const nHoursAgo = new Date(currentHour.getTime() - numberOfHours * 60 * 60 * 1000);
    const threeDaysAgo = new Date(currentHour.getTime() - 3 * 24 * 60 * 60 * 1000);

    const body: DbTenderCountBody = {
      filters: {
        indexation_period: {from: this.toApiString(nHoursAgo), to: this.toApiString(currentHour)},
        publication_period: {from: this.toApiString(threeDaysAgo), to: this.toApiString(currentHour)},
        publication_type: [TenderPublicationTypeEnum.APPEL_OFFRE]
      },
      service: TenderServiceEnum.COUNT
    };

    return this.http.post<{ count: number }>(
      `${environment.explainApiUrl}tenders/count`,
      body,
      this.httpHeadersService.httpHeaders
    ).pipe(map((res) => res.count)
    );
  }

  toApiString(date: Date): string {
    const iso = date.toISOString();
    return iso.slice(0, iso.length - 5);
  }

}
