import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'insertText'
})
export class InsertTextPipe implements PipeTransform {

  transform(value: any, element: string, index: number): string {
    if (!value && value !== 0) {
      return'';
    }

    value = value.toString();
    if (value.length <= index) {
      return value;
    }
    value = value.split('').reverse().join('');
    const rep = new RegExp('.{1,' + index + '}', 'g');
    return value.match(rep).join(element).split('').reverse().join('');
  }

}
