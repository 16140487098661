import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { LoaderService } from '../../../../common-explain/services/loader.service';
import { map } from 'rxjs/operators';
import { EventTypeName } from '../../../../models/user-tracker';
import {
  DbImpacter,
  DbImpacterWithByFilter,
  Impacter,
  RetrieveImpactersTypedResponseBody
} from '../../../../models/impacter/impacter';

@Injectable({
  providedIn: 'root'
})
export class ApiImpacterService {

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private loaderService: LoaderService,
  ) {
  }

  /**
   * Method to retrieve impacter by its ID
   * This call to the api is tracked as it is, with a event name called 'impacter-opening'
   * @param impacterId - impacter's ID as a string
   */
  getImpacter(impacterId: string): Observable<Impacter> {
    return this.http
      .get<DbImpacter>(
        `${environment.explainApiUrl}impacters/${impacterId}`,
        this.headersService.getTrackedHttpHeaders(EventTypeName.IMPACTER_OPENING)
      ).pipe(
        map((res: DbImpacter) => {
          return new Impacter(res);
        })
      );
  }

  /**
   * Method to retrieve a load of impacters to display as cards on impacters-page component
   * @param body - body de type searchBody as used in all POST methods for searching entities
   */
  retrieveImpacters(body: object): Observable<RetrieveImpactersTypedResponseBody> {
    this.loaderService.displayLoader(true);

    return this.http.post<{
      data: DbImpacterWithByFilter[],
      filtered_count: number,
      total_count: number
    }>(
      `${environment.explainApiUrl}impacters/search`,
      body,
      this.headersService.getTrackedHttpHeaders(EventTypeName.IMPACTER_SEARCH)
    ).pipe(
      map((res: {
        data: DbImpacterWithByFilter[],
        filtered_count: number,
        total_count: number
      }) => {
        this.loaderService.hideLoader(true);
        return new RetrieveImpactersTypedResponseBody(res);
      }));
  }

  /**
   * Method to retrieve count of impacters
   */
  getImpacterCount(body: object): Observable<number> {
    return this.http.post<{count: number}>(`${environment.explainApiUrl}impacters/count`, body, this.headersService.httpHeaders)
      .pipe(map(((res: {count: number}) => res['count'])));
  }
}

