import { booleanAttribute, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-loader',
  templateUrl: './simple-loader.component.html',
  styleUrls: ['./simple-loader.component.scss']
})
export class SimpleLoaderComponent implements OnInit {

  @Input() isSynchronizedWithLoaderService = true;
  @Input() loaderText = 'loader';
  @Input({transform: booleanAttribute}) vertical = false;
  @Input() isShown = true;
  @Input() size: number = 72;
  @Input() noResult = false;

  constructor() { }

  ngOnInit(): void {
  }

}
