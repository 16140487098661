import { Injectable, TemplateRef } from '@angular/core';
import { saveAs } from 'file-saver';
import { ApiExportService } from './api/export-download/api-export.service';
import { ToastMessageStackService } from './toast-message-stack.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/**
 * Class taking care of exporting files
 * Uses npm library FILE-SAVER, to save document onto user's computer.
 */
export class ExportService {

  constructor(private apiExportService: ApiExportService, private toastMessageStackService: ToastMessageStackService) { }

  export(
    requestBody: any,
    toast?: {template: TemplateRef<any>, options?: object},
    options?: {timeout?: number, generationHeaders?: HttpHeaders, downloadHeaders?: HttpHeaders}
  ) {
    if (toast) this.toastMessageStackService.show(toast.template, toast.options);
    let allow = true;
    return {
      promise: new Promise<void>(async (resolve, reject) => {
        let start = Date.now();
        const url = await this.apiExportService.generateUrl(requestBody, {timeout: options?.timeout, headers: options?.generationHeaders});
        let blob;
        while(start + (options?.timeout ?? 60000) - Date.now() > 0 && !blob && allow) {
          blob = await this.apiExportService.downloadBlob(url, {headers: options?.downloadHeaders}).catch(() => undefined);
          await new Promise(r => setTimeout(r, 1000));
        }
        if (blob) {
          saveAs(blob, this.getFileNameFromURL(url) ?? undefined);
          resolve();
        }
        reject();
      }).finally(() => {
        if (toast) {
          this.toastMessageStackService.removeByClassName((toast.options as any)?.classname);
          this.toastMessageStackService.removeByClassName(`l-t-${(toast.options as any)?.id}`)
        }
      }),
      cancel: () => {allow = false}
    };
  }

  /**
   * Method to save Blob as a PDF to the user's computer.
   */
  savePdfToComputer(adminDocPdfBlob: Blob, documentName: string): void {
    saveAs(adminDocPdfBlob, documentName);
  }

  saveZipToComputer(zipBlob: Blob | null, documentName: string): void {
    if (!zipBlob) return;
    saveAs(zipBlob, documentName);
  }

  getFileNameFromURL(url: string): string | null {
    const urlObject = new URL(url);
    const pathParts = urlObject.pathname.split('/');
    const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
    return fileName;
  }
}
