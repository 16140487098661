import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tender-type-badge',
  templateUrl: './tender-type-badge.component.html',
  styleUrls: ['./tender-type-badge.component.scss']
})
export class TenderTypeBadgeComponent {
  @Input({required: true}) tenderType!: string;

}
