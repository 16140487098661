import { booleanAttribute, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DbTendersUser } from '../../../models/user';
import { TendersModuleService } from '../../services/tenders-module.service';

@Component({
  selector: 'app-assignment-list',
  templateUrl: './assignment-list.component.html',
  styleUrls: ['./assignment-list.component.scss']
})
export class AssignmentListComponent implements OnInit {
  @Input({transform: booleanAttribute}) multiple: boolean = false;

  @Input() selection: DbTendersUser[] = [];
  @Output() selectionChange: EventEmitter<DbTendersUser[]> = new EventEmitter();

  user!: DbTendersUser;
  users!: DbTendersUser[];

  constructor(protected tendersModuleService: TendersModuleService) { }

  async ngOnInit() {
    this.user = await this.tendersModuleService.user;
    this.users = await this.tendersModuleService.groupAccountUsers;
  }

  select(user: DbTendersUser | null) {
    if (user) {
      if (this.multiple) {
        if (this.selection.includes(user)) this.selection.splice(this.selection.indexOf(user), 1);
        else this.selection.push(user);
      }
      else this.selection = [user];
    }
    else this.selection = [];
    this.selectionChange.emit(this.selection);
  }
}
