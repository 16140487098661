import { Component, OnInit } from '@angular/core';
export interface IexampleItem {
  text: string;
  correct: boolean;
}
@Component({
  selector: 'app-explicative-block-two',
  templateUrl: './explicative-block-two.component.html',
  styleUrls: ['./explicative-block-two.component.scss']
})
export class ExplicativeBlockTwoComponent implements OnInit {
  public list1: Array<IexampleItem> = new Array<IexampleItem>();
  public list2: Array<IexampleItem> = new Array<IexampleItem>();
  public list3: Array<IexampleItem> = new Array<IexampleItem>();
  public list4: Array<IexampleItem> = new Array<IexampleItem>();

  constructor() { }

  ngOnInit(): void {
    this.list1 = [
      {
        text: 'explicative.block-3.exemple-1.word-2',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-1.word-3',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-1.word-4',
        correct: false
      },
      {
        text: 'explicative.block-3.exemple-1.word-5',
        correct: false
      }
    ];
    this.list2 = [
      {
        text: 'explicative.block-3.exemple-2.word-2',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-2.word-3',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-2.word-4',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-2.word-5',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-2.word-6',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-2.word-7',
        correct: true
      }
    ];
    this.list3 = [
      {
        text: 'explicative.block-3.exemple-3.word-2',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-3.word-3',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-3.word-4',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-3.word-5',
        correct: true
      }
    ];
    this.list4 = [
      {
        text: 'explicative.block-3.exemple-3.word-2',
        correct: true
      },
      {
        text: 'explicative.block-3.exemple-3.word-3',
        correct: false
      },
      {
        text: 'explicative.block-3.exemple-3.word-4',
        correct: false
      },
      {
        text: 'explicative.block-3.exemple-3.word-5',
        correct: false
      }
    ];
  }

}
