
export interface DbStatusItem {
  id: number | null;
  status_name: string;
  displayed_status_name: string;
  color?: string;
  count?: number;
}

export class StatusItem {
  id: number | null;
  name?: string;
  displayName?: string;
  backgroundColor?: string;
  color?: string;
  count?: number;

  constructor(dbStatus: DbStatusItem & { backgroundColor?: string }) {
    this.id = dbStatus.id;
    this.name = dbStatus.status_name;
    this.displayName = dbStatus.displayed_status_name;
    this.backgroundColor = dbStatus.backgroundColor;
    this.color = dbStatus.color;
    this.count = dbStatus.count;
  }
}

export interface DbCountStatusItem {
  status_id: number | null;
  count: number;
}
export class StatusCountItem {
  statusId: number | null;
  count: number;

  constructor(dbCountStatus: DbCountStatusItem) {
    this.statusId = dbCountStatus.status_id;
    this.count = dbCountStatus.count;
  }
}
