<div *ngIf="user && users" class="users-list">
  <div class="user-row" [ngClass]="{active: selection.includes(user)}"
       (click)="select(user)">
    <div *ngIf="multiple" class="ex-checkbox">
      <input type="checkbox" [checked]="selection.includes(user)"/>
      <span class="checkmark"></span>
    </div>
    <ex-user-badge [user]="user" size="small" />
    <div class="text-truncate">{{user.name}}</div>
    <div>({{'tenders.assignment.me' | translate}})</div>
    <i *ngIf="!multiple && selection.includes(user)" class="ph ph-x ms-auto"
       (click)="$event.stopPropagation(); select(null)"></i>
  </div>
  <div *ngFor="let colleague of users" class="user-row"
       [ngClass]="{active: selection.includes(colleague)}" (click)="select(colleague)" >
    <div *ngIf="multiple" class="ex-checkbox">
      <input type="checkbox" [checked]="selection.includes(colleague)"/>
      <span class="checkmark"></span>
    </div>
    <ex-user-badge [user]="colleague" size="small" />
    <div class="text-truncate">{{colleague.name}}</div>
    <i *ngIf="!multiple && selection.includes(colleague)" class="ph ph-x ms-auto"
       (click)="$event.stopPropagation(); select(null)"></i>
  </div>
</div>
