import { Component, Input } from '@angular/core';
import { TenderDetail } from '../../models/tender-detail';
import { TenderTerritoriesTextService } from '../../services/helpers/tender-territories-text.service';
import { hasNonZeroTime } from '../../services/helpers/date-formater';
import { TenderTypesEnum } from '../../models/tender-search-body';

const MAX_SHOWN_TERRITORIES = 4;

@Component({
  selector: 'app-tender-metadata',
  templateUrl: './tender-metadata.component.html',
  styleUrls: ['./tender-metadata.component.scss']
})
export class TenderMetadataComponent {
  protected readonly hasNonZeroTime = hasNonZeroTime;
  protected readonly MAX_SHOWN_TERRITORIES = MAX_SHOWN_TERRITORIES;
  protected readonly TenderTypesEnum = TenderTypesEnum;

  @Input() tenderDetail!: TenderDetail;

  constructor(
    public tenderTerritoriesTextService: TenderTerritoriesTextService,
  ) { }


}

