import { Injectable } from '@angular/core';
import { BlockModel, BlockType } from '../../core/block/block.model';

@Injectable({
  providedIn: 'root'
})
/** Service allowing data manipulation regarding Topics */
export class TopicService {

  public topicIncludeBlocks: Array<BlockModel> = [];
  public topicComplexityMaxValue = 0;

  /**
   * Method to evaluate a topic complexity based on its blocks.
   */
  evaluateTopicComplexity(blocks: BlockModel[]): number {
    let topicComplexity = 0;
    blocks.forEach(block => {
      if (block.type === BlockType.SIMPLE_LIST) {
        if (block.columns?.[0]) {
          topicComplexity += block.columns[0].length;
        } else {
          topicComplexity += 0;
        }
      }
      if (block.type === BlockType.TERMS_TO_EXCLUDE) {
        // Do nothing
      }
      if (block.type === BlockType.NEARBY_TERMS || block.type === BlockType.EXPRESSIONS_COMBINATIONS) {
        let complexityToAdd = 1;
        let testEmptyList = 0;
        for (const column of block.columns ?? []) {
          if (column != null) {
            complexityToAdd = complexityToAdd * column.length;
            testEmptyList += column.length;
          }
        }
        if (testEmptyList === 0) {
          complexityToAdd = 0;
        }
        topicComplexity += complexityToAdd;
      }
    });
    return topicComplexity;
  }

}
