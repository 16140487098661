import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ex-filter-text',
  templateUrl: './ex-filter-text.component.html',
  styleUrls: ['./ex-filter-text.component.scss']
})
export class ExFilterTextComponent {
  @Input() placeholder?: string;
  @Input() text?: string;
  @Output() textChange = new EventEmitter<string>();
  isFocused = false;

  onInputFocus() {
    this.isFocused = true;
  }

  onInputBlur() {
    this.isFocused = false;
  }

  clearInput() {
    this.text = '';
    this.emitText();
  }

  emitText() {
    this.text = this.text?.trim();
    this.textChange.emit(this.text);
  }
}
