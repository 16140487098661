import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { AutoUnsubscribe } from "../../common-explain/decorators/auto-unsubscribe";
import { UserSessionService } from "./user-session.service";

@AutoUnsubscribe
@Injectable({
  providedIn: 'root'
})
export class ModuleManagerService {

  private _currentModule?: ExplainModuleEnum = ExplainModuleEnum.TERRITORY_INTEL;
  private _alternativeSuggestedModule!: ExplainModuleEnum | null;
  moduleChanged = new ReplaySubject<ExplainModuleEnum>();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private userSessionService: UserSessionService) {
    this.updateCurrentModule();
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateCurrentModule();
      })
  }

  updateCurrentModule() {
    const module = (this.route.firstChild?.snapshot.data['module']) || this.userSessionService.userSession?.modules[0];
    if (module === 'settings') {this._currentModule = undefined; return;}
    if (module === 'admin-interface') {this._currentModule = undefined; return;}
    if (module === 'guest-interface') {this._currentModule = undefined; return;}
    if (module) {
      if (!this.userSessionService.userSession?.modules.includes(module)) { // redirect
        const url = (module === ExplainModuleEnum.TENDERS) ? '' : '/' + ExplainModuleEnum.TENDERS;
        this.router.navigate([url]).then();
      } else {
        this.currentModule = module;
      }
    }
  }

  get currentModule(): ExplainModuleEnum | undefined {
    return this._currentModule;
  }

  set currentModule(value: ExplainModuleEnum) {
    this._currentModule = value;
    this.updateAlternativeSuggestedModule(value);
    this.moduleChanged.next(value);
  }

  get alternativeSuggestedModule(): ExplainModuleEnum | null {
    return this._alternativeSuggestedModule;
  }

  set alternativeSuggestedModule(value: ExplainModuleEnum | null) {
    this._alternativeSuggestedModule = value;
  }

  updateAlternativeSuggestedModule(currentModule: ExplainModuleEnum) {
    if (this.userSessionService.userSession?.modules.length === 1) {
      this.alternativeSuggestedModule = null;
    } else {
      switch (currentModule) {
        case ExplainModuleEnum.TERRITORY_INTEL:
          this.alternativeSuggestedModule = ExplainModuleEnum.TENDERS;
          break;
        case ExplainModuleEnum.TENDERS:
          this.alternativeSuggestedModule = ExplainModuleEnum.TERRITORY_INTEL;
          break;
        default:
          this.alternativeSuggestedModule = null;
      }
    }
  }
}

export enum ExplainModuleEnum {
  TERRITORY_INTEL = 'territory_intel',
  TENDERS = 'tenders'
}

export enum ExplainModuleEnumExtended {
  TERRITORY_INTEL = ExplainModuleEnum.TERRITORY_INTEL,
  TENDERS = ExplainModuleEnum.TENDERS,
  ALL = 'all'
}
