import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-type-guide-template',
  templateUrl: './simple-type-guide-template.component.html',
  styleUrls: ['./simple-type-guide-template.component.scss']
})
export class SimpleTypeGuideTemplateComponent implements OnInit {
  examples: Array<{text: string, correct: boolean}>;
  constructor() {
    this.examples = [
      {
        text: 'template.simple-example-1',
        correct: true
      },
      {
        text: 'template.simple-example-2',
        correct: true
      },
      {
        text: 'template.simple-example-3',
        correct: true
      },
      {
        text: 'template.simple-example-4',
        correct: true
      },
      {
        text: 'template.simple-example-5',
        correct: false
      },
      {
        text: 'template.simple-example-6',
        correct: false
      },
    ];
  }

  ngOnInit(): void {
  }

}
