import { ChunkPageBbox, DbChunkPageBbox } from './chunk-page-bbox';


export interface DbChunkDetail {
  chunk_id: string;
  chunk_pages_bboxes: DbChunkPageBbox[];
  doc_uid: string;
}

export class ChunkDetail {
  chunkId: string;
  chunkPagesBboxes: ChunkPageBbox[];
  docUid: string;

  constructor(dbChunkDetail: DbChunkDetail) {
    this.chunkId = dbChunkDetail.chunk_id;
    this.chunkPagesBboxes = dbChunkDetail.chunk_pages_bboxes.map((pageBbox) => new ChunkPageBbox(pageBbox));
    this.docUid = dbChunkDetail.doc_uid;
  }
}
