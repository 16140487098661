import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastMessageStackService {

  toasts: any[] = [];
  // hiddenToasts: any[] = [];
  stockedHiddenToasts: Map<string, any> = new Map<string, any>();

  // Push new Toasts to array with content and options
  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({textOrTpl, ...options});
  }

  // Callback method to remove Toast DOM element from view
  remove(toast: string | TemplateRef<any> | any) {
    this.toasts = this.toasts.filter(t => t !== toast);
    console.log(this.toasts);
  }

  removeByClassName(className: string) {
    this.toasts = this.toasts.filter(t => !t['classname'].includes(className));
  }

  /** Method to hide a big toast, keep it in memory, and to display a light version */
  hide(toastToHide: any, toastToStock: TemplateRef<any>, classnameToStock: string, id: string, toastToShow: any) {
    if (this.stockedHiddenToasts.has(id)) {
      return; // case of multi simultaneous exports on the same document
    }
    this.stockedHiddenToasts.set(id, {ref: toastToStock, classname: classnameToStock});
    this.remove(toastToHide);
    this.show(toastToShow, {classname: `light-toast l-t-${id}`}); // l-t-${id} stands for light-toast-id
  }

  /** Method to display a hidden toast kept in memory */
  unhide(lightToast: any, id: string) {
    this.remove(lightToast);
    this.show(this.stockedHiddenToasts.get(id).ref, {classname: this.stockedHiddenToasts.get(id).classname});
    this.stockedHiddenToasts.delete(id);
  }

  constructor() {
  }
}
