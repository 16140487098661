import { Component, Input } from '@angular/core';

@Component({
  selector: 'ex-menu-item',
  templateUrl: './ex-menu-item.component.html',
  styleUrls: ['./ex-menu-item.component.scss']
})
export class ExMenuItemComponent {

  @Input({required: true}) iconName!: string;
  @Input({required: true}) menuItemRouterLink!: string | undefined;
  @Input({required: true}) label!: string;
  @Input({required: true}) module!: string;
  phStyle = 'ph';
  isActiveLink = false;

  onRouterLinkActive($event: boolean) {
    this.phStyle = $event ? 'ph-duotone' : 'ph';
    this.isActiveLink = $event;
  }

  setPhStyle(hover: boolean) {
    if (!this.isActiveLink) {
      this.phStyle = hover ? 'ph-duotone' : 'ph';
    }
  }
}
