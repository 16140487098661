<div class="example-section">
  <div class="fw-bold title">{{'template.example' | translate}}</div>
  <div class="list">
    <div class="d-flex" *ngFor="let example of examples">
      <i *ngIf="example['correct']" class="ph-bold ph-check-circle d-flex align-items-center"></i>
      <i *ngIf="!example['correct']" class="ph-bold ph-x-circle d-flex align-items-center"></i>
      <div class="align-self-center" [innerHTML]="example.text | translate"></div>
    </div>
  </div>
</div>
