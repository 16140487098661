import { Injectable } from '@angular/core';
import { UserSession } from "../../core/user/user.model";
import MEPConfiguration from "../../../assets/mep-configuration/version.json";

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private _userSession: UserSession | null = null;

  constructor() {}

  get userSession(): UserSession | null {
    return this._userSession;
  }

  set userSession(value: UserSession | null) {
    this._userSession = value;
    // everything is removed from localstorage
    localStorage.clear();
    if (!this._userSession) return ; // stop if !value
    else {
      // setup new values
      localStorage.setItem('email', this._userSession.email);
      localStorage.setItem('user_id', String(this._userSession.id));
      localStorage.setItem('group_account_id', String(this._userSession.groupAccountId));
      localStorage.setItem('modules', JSON.stringify(this._userSession.modules));
      localStorage.setItem('api_token', this._userSession.apiToken);
    }
  }

  get explainToken(): string {
    return localStorage.getItem('api_token') ?? '';
  }

  setUserSessionFromLocalStorage() {
    if (this._userSession !== null) return;
    const email = localStorage.getItem('email') ?? 'unknown-email';
    const userId = localStorage.getItem('user_id') ?? '-1';
    const groupAccountId = localStorage.getItem('group_account_id') ?? '-1';
    const modules = localStorage.getItem('modules');
    if (!modules) return this.logout();
    const apiToken = localStorage.getItem('api_token') ?? '';
    if (apiToken) {
      this._userSession = new UserSession({
        id: +userId,
        group_account_id: +groupAccountId,
        first_name: 'first_name',
        last_name: 'last_name',
        email: email,
        api_token: apiToken,
        modules: JSON.parse(modules)
      });
    } else {
      this._userSession = null;
    }
  }

  logout() {
    // Storing new-feature-seen status - part 1/2
    // Only the new feature item will be stored. Old ones are cleared.
    const newFeatureAdminDocExportSeen = localStorage.getItem(MEPConfiguration.new_feature_localstorage_item_name);
    // Clearing all items on localStorage
    localStorage.clear();
    // Setting back new-feature-seen status - part 2/2
    if (newFeatureAdminDocExportSeen) {
      localStorage.setItem(MEPConfiguration.new_feature_localstorage_item_name, newFeatureAdminDocExportSeen);
    }
    this.userSession = null;
  }
}
