export interface DbTenderTerritoryLight {
    uid: string;
    name: string;
    code: string;
}

export class TenderTerritoryLight {
    uid: string;
    name: string;
    code: string;

    constructor(dbTenderTerritory: DbTenderTerritoryLight) {
        this.uid = dbTenderTerritory.uid;
        this.name = dbTenderTerritory.name;
        this.code = dbTenderTerritory.code;
    }
}

export interface DbTenderTerritory {
    code: string;
    id: number;
    kind: string;
    name: string;
    search_key: string;
    subkind: string;
    text?: string;
    value?: string;
    territories?: DbTenderTerritory[];
}


