/**
 * Very generic class describing an Item or entity that has an id and a name.
 * Every object from the model should extends Item.
 */
export interface DbItem {
  id: any;
  name: string;
}

export class Item {
  id: any ;
  name: string;

  constructor(id: any, name: string) {
    this.id = id;
    this.name = name;
  }
}

export interface TreeItem {
  name: string;
}
