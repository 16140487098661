<div class="progress-bar-container" [style.width]="progressBarWidth ? progressBarWidth + 'px' : ''"
     [ngClass]="{'unload' : currentValue == minValue,
                  'loading': (currentValue > minValue) && (currentValue <= (maxValue - 1)),
                  'fully-loaded': (currentValue >= maxValue),
                  'credit-span-container': progressBarClass === 'credit-span-container'}">
  <div [ngClass]="{'loaded-background': (currentValue >= maxValue),
                   'progression-almost-full': (currentValue > minValue) && (currentValue <= (maxValue - 1)) && (currentValue >= (0.95 * maxValue)),
                   'progression-element': true }"
       [style.width]="ProgressionPartWidth"></div>
  <div [ngClass]="{'loaded-text-area': (currentValue >= maxValue), 'text-area': true}">
    <span data-cy="progress-bar-current-value">{{currentValue}}</span> / {{maxValue}} {{nameValue | translate | lowercase}}
  </div>
</div>
