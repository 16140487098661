<app-header [showContent]="false"></app-header>
<div class="container-fluid topic-container pt-0 ">
  <div class="row w-100 filtered-ar">
    <div class="col-10 text-uppercase d-flex align-items-center">
      <div class="label-filtered me-3">{{'topic.search' | translate}}</div>
      <div>
        <span class="filtered-input-container">
          <input [formControl]="topicFilteredName" type="text" placeholder="{{'topic.topic-name' | translate}}" class="filtered-input">
            <img (click)="clearText()" *ngIf="showClearIcon" src="assets/images/close-circle.svg" alt="close" class="cursor-pointer">
        </span>
      </div>
    </div>
  </div>
  <div class="row topics-container">
    <div class="col-12">
      <app-topic-list-template
        [topics]="filteredTopics ? (filteredTopics | filter: 'type': 'custom_topic') : []"
        [defaultTopics]="topics ? (topics | filter: 'type': 'default_topic') : []"
        [type]="TopicType.CUSTOM"
        [topicListLength]="(topics | filter: 'type': 'custom_topic').length"
        [hasFilter]="hasFilter"
        (renameTopic)="renameTopic($event)"
        (deleteTopic)="deleteTopic($event)">
      </app-topic-list-template>
      <app-topic-list-template
        [topics]="filteredTopics ? (filteredTopics | filter: 'type': 'default_topic') : []"
        [type]="TopicType.DEFAULT"
        [topicListLength]="(topics | filter: 'type': 'default_topic').length"
        [hasFilter]="hasFilter"
        (deleteTopic)="deleteTopic($event)">
      </app-topic-list-template>
    </div>
  </div>
  <app-toast-message createMessage="content.topic-toast-content" editMessage="content.topic-toast-edit-content">
  </app-toast-message>
</div>
