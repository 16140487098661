import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(array: Array<object>, attribute: string, value: any): Array<any> {
    return array.filter((i: any) => i[attribute] === value);
  }
}
