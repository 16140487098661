export interface DbProject {
    title: string;
    id: string;
    inquirers: string[];
    url: string;
}

export class Project {
    title: string;
    id: string;
    inquirers: string[];
    url: string;
    updatedAt?: Date;
    totalDocumentsCount?: number;

    constructor(dbProject?: DbProject) {
        this.title = dbProject?.title ?? '';
        this.id = dbProject?.id ?? '';
        this.inquirers = dbProject?.inquirers ?? [];
        this.url = dbProject?.url ?? '';
        this.updatedAt = undefined;
        this.totalDocumentsCount = undefined;
    }
}
