<div class="d-inline-block page-tab">
  <button class="left text-uppercase d-inline-flex"
          [ngClass]="{'active': currentUrl.includes(articleUrl)}"
          [routerLink]="['./'+articleUrl]">
    <div class="icon-page-tab icon-page-tab--article align-self-center"></div>
    <div class="button-label">{{'header.article-tab' | translate}} ({{nbArticle | insertText: ' ': 3}})</div>
  </button>
  <button class="right text-uppercase d-inline-flex"
          [ngClass]="{'active': currentUrl.includes(adminDocUrl)}"
          [routerLink]="['./'+adminDocUrl]">
    <div class="icon-page-tab icon-page-tab--admin-doc align-self-center"></div>
    <div class="button-label">{{'header.admin-doc-tab' | translate}} ({{nbAdminDoc | insertText: ' ': 3}})</div>
  </button>
  <button class="impacter-button text-uppercase d-inline-flex left right"
          [ngClass]="{'active': currentUrl.includes(impacterUrl)}"
          [routerLink]="['./'+impacterUrl]">
    <div class="icon-page-tab icon-page-tab--impacter align-self-center"></div>
    <div class="button-label">{{'header.impacters' | translate}} ({{nbImpacter | insertText: ' ': 3}})</div>
  </button>
</div>
