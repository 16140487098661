<div class="no-select home-container d-flex flex-column align-items-center justify-content-between"
     [ngClass]="{'home': !linkToOtherModule,
                 'other-module': linkToOtherModule}"
      (click)="linkToOtherModule ? navigateToOtherModule() : navigateToHome()"
     (mouseenter)="phStyle = 'ph-duotone'"
     (mouseleave)="phStyle = 'ph'">
  <!--case module home-->
  <mat-icon *ngIf="!linkToOtherModule" svgIcon="explain-icon"></mat-icon>
  <!--case link to other module-->
  <div class="d-flex app-name">
    {{ 'common.app-name' | translate }}
  </div>
  <div class="module-name d-flex">
    {{ 'explain-module-name.' + (linkToOtherModule ? ''+alternativeSuggestedModule : ''+currentModule) | translate }}
    <i *ngIf="linkToOtherModule"
       class="{{phStyle}} ph-arrow-square-out d-flex align-items-center"></i>
  </div>
</div>
