import { TermModel } from '../term/term.model';

export interface BlockModel {
  type: BlockType;
  columns?: Array<Array<TermModel>>;
}

export enum BlockType {
  SIMPLE_LIST = 'simple_list',
  TERMS_TO_EXCLUDE = 'terms_to_exclude',
  NEARBY_TERMS = 'nearby_terms',
  EXPRESSIONS_COMBINATIONS = 'expressions_combinations'
}
