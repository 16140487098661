import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { Observable } from 'rxjs';
import { DbFolder, Folder } from '../../../../models/folder';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Article, DbArticle } from '../../../../models/article';
import { AdminDocument, DbAdminDocument } from '../../../../models/admin-document';
import { BookmarkEntityType } from '../../../../models/bookmark-folder';
import { ApiPutResponse } from '../../../../models/api-put-response';
import { SavingPanelDocumentInput } from '../../../../models/saving-panel-document';

@Injectable({
  providedIn: 'root'
})
export class ApiBookmarkService {

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
  ) {
  }

  /** FOLDERS PART */
  retrieveBookmarkFolders(body: object): Observable<Array<Folder>> {
    return this.http.post<{ data: Array<DbFolder> }>(
      `${environment.explainApiUrl}bookmarks`,
      {'container_id': null, ...body},
      this.headersService.httpHeaders
    ).pipe(map((response: { data: Array<DbFolder> }) => {
      return response.data.map((folder: DbFolder) => new Folder(folder));
    }));
  }

  /**
   * Méthode retournant la liste des documents contenus dans un dossier.
   * Renvoie également le nombre total de documents
   */
  retrieveBookmarkDocuments(body: any): Observable<{ documents: Array<Article | AdminDocument>, total_count: number }> {
    return this.http.post(
      `${environment.explainApiUrl}bookmarks`,
      body,
      this.headersService.httpHeaders
    ).pipe(map((data:  any) => {
      if (body['entity_type'] === BookmarkEntityType.ARTICLE) {
        return {
          documents: data['data'].map((article: DbArticle) => new Article(article)),
          total_count: Number(data['total_count'])
        };
      } else if (body['entity_type'] === BookmarkEntityType.ADMIN_DOC) {
        return {
          documents: data['data'].map((adminDocument: DbAdminDocument) => new AdminDocument(adminDocument)),
          total_count: Number(data['total_count'])
        };
      }
      return {
        documents: [],
        total_count: 0
      };
    }));
  }

  /** PUT to insert a new BookmarkFolder. */
  createBookmarkFolder(bookmarkFolderName: string): Observable<ApiPutResponse> {
    const body = {
      'container_id': null,
      'context': {},
      'entity_label': bookmarkFolderName,
      'entity_type': BookmarkEntityType.FOLDER
    };
    return this.http.put<ApiPutResponse>(
      `${environment.explainApiUrl}bookmarks`,
      body,
      this.headersService.httpHeaders
    ).pipe(map((response: ApiPutResponse) => response));
  }

  /** PUT to insert a document inside a BookmarkFolder. */
  createBookmarkDocument(document: SavingPanelDocumentInput, containerId: number): Observable<ApiPutResponse> {
    const body = {
      'container_id': containerId,
      'context': {
        'title': document.title,
        'excerpt': document.snippet,
        'match_stems': document?.matchStems,
        'project_uid': document?.projectUid
      },
      'entity_label': document.id,
      'entity_type': document.type
    };
    return this.http.put<ApiPutResponse>(
      `${environment.explainApiUrl}bookmarks`,
      body,
      this.headersService.httpHeaders
    ).pipe(map((response: ApiPutResponse) => response));
  }

  /* DELETE to delete a BookmarkFolder.
   * Returns 204 - NO CONTENT */
  deleteBookmarkEntity(entityId: number, entityType: BookmarkEntityType): Observable<null> {
    return this.http.delete<null>(
      `${environment.explainApiUrl}bookmarks?entity_id=${entityId}&entity_type=${entityType}`,
      this.headersService.httpHeaders
    ).pipe(map((response: null) => response));
  }

  /* PATCH to rename a BookMarkFolder.
  * Returns 204 - NO CONTENT */
  renameBookmarkFolder(folderId: number, newFolderName: string): Observable<null> {
    const body = {
      'entity_id': folderId,
      'entity_label': newFolderName
    };
    return this.http.patch<null>(
      `${environment.explainApiUrl}bookmarks`,
      body,
      this.headersService.httpHeaders
    ).pipe(map((response: null) => response));
  }

  /* GET to retrieve information on a bookmark. **/
  getBookmarkEntityByTypeAndId(entityType: BookmarkEntityType, entityId: number): Observable<Folder> {
    return this.http.get<DbFolder>(
      `${environment.explainApiUrl}bookmarks/${entityType}/${entityId}`,
      this.headersService.httpHeaders
    ).pipe(map((response: DbFolder) => {
      return new Folder(response);
    }));
  }
}
