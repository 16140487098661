export interface DbChunkPageBbox {
  page_num: number;
  bbox: number[];
}

export class ChunkPageBbox {
  pageNum: number;
  bbox: number[];

  constructor(dbChunkPageBbox: DbChunkPageBbox) {
    this.pageNum = dbChunkPageBbox.page_num;
    this.bbox = dbChunkPageBbox.bbox;
  }
}
