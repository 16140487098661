<div class="modal-container">
  <div class="title justify-content-between d-flex"> {{'tenders.watch-users.manage-users-title' | translate }}
    <i class="ph ph-x-circle" (click)="cancel()" (keydown)="cancel()"></i>
  </div>
  <ex-watch-users-selector
    class="watch-users-selector"
    [marketWatchUsers]="marketWatchUsers"
    (selectedUsersUpdate)="selectedUsersUpdate($event)"
  >
  </ex-watch-users-selector>
  <div class="buttons d-flex">
    <button class="ex-btn-optional-transparent ex-btn" (click)="cancel()">{{ 'tenders.watch-users.cancel' | translate }}</button>
    <button class="ex-btn-strong ex-btn" (click)="confirm()">{{ 'tenders.watch-users.confirm' | translate }}</button>
  </div>
</div>
