import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ex-chip',
  templateUrl: './ex-chip.component.html',
  styleUrls: ['./ex-chip.component.scss']
})
export class ExChipComponent {
  @Input() backgroundColor = 'white';
  @Input() color = 'gray';

  @Output() bodyClick = new EventEmitter<void>();
  @Output() closeClick = new EventEmitter<void>();
}
