<div class="container-fluid nopadding">
    <div class="row nopadding">
        <div class="col-5 nopadding">
          <div class="row nopadding">
            <div class="col-10 nopadding">
              <div class="w-100 m-3">
                <div class="titleblock1" style="margin-bottom: 15px;">
                  {{"explicative.block-1.title"| translate}}
                </div>
                <div class="descriptionblock1">
                  <div class="br">{{"explicative.block-1.description-1"| translate}}</div>
                  <div class="br">{{"explicative.block-1.description-2"| translate}}</div>
                  <div class="br">{{"explicative.block-1.description-3"| translate}}</div>
                  <div class="br">{{"explicative.block-1.description-4"| translate}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 backgroundblock1 nopadding">
          <div 
          class="h-100 w-100" 
          style="background-image: url('assets/images/block1.png');
          background-size: contain;
          min-height:500px;
          background-repeat: no-repeat;">
          </div>
        </div>
      </div>
</div>