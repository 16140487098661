import { Injectable } from '@angular/core';
import { environment } from "../../../../../environments/environment";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { DtoUserSession, UserSession } from "../../../../core/user/user.model";


@Injectable({
  providedIn: 'root'
})
export class ApiAccountService {

  constructor(private http: HttpClient) { }

  /**
   * Log the user in, and sets its session values inside the local storage.
   */
  getUserSession(logs: { email: string | null, password: string }) {
    return this.http.post<DtoUserSession>(`${environment.explainApiUrl}account/session`, logs)
      .pipe(
        map((user) => {
          return new UserSession(user)
        }));
  }


}
