import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbModel } from '../../../core/breadcrumb/breadcrumb.model';
import { filter } from 'rxjs/operators';
import { BookmarkFolderService } from '../../../shared/services/bookmark-folder.service';
import { Subscription } from 'rxjs';
import { Folder } from '../../../models/folder';
const FAKE_TERRITORY = 'FRDEPA00';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {
  public breadcrumbs!: Array<any>;
  @Input() label!: string | null;
  @Input() preLabel!: string | null;
  @Input() guestDisplay = false;
  private subscription!: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bookmarkFolderService: BookmarkFolderService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        const route = this.route.root.firstChild;
        if (route) {
          this.breadcrumbs = this.buildBreadCrumb(route);
        }
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<BreadcrumbModel> = []): Array<BreadcrumbModel> {
     let path = route.routeConfig?.path;
     for (const key of Object.keys(route.snapshot.params)) {
       if (path?.includes(`:${key}`)) {
         path = path.replace(`:${key}`, route.snapshot.params[key]);
       }
     }
     const nextUrl = `${url}${path}/`;
     let newBreadcrumbs = [ ...breadcrumbs];
     if (route.routeConfig?.data && route.routeConfig.data['breadcrumb']) {
       const label = route.routeConfig.data['breadcrumb'];
       const breadcrumb = {
         label: label,
         url: nextUrl
       };
       if (label === 'breadcrumb.folder-name') {
         if (this.subscription) {
           this.subscription.unsubscribe();
         }
         this.subscription = this.bookmarkFolderService.getFolderSubject().subscribe((folder: Folder) => {
           breadcrumb.label = folder?.name;
         });
       }
       newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];
     }
     if (route.firstChild) {
       return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
     }

     return newBreadcrumbs;
  }

  getUrl(url: string): string {
    return url.includes(FAKE_TERRITORY) ? '/search' : url;
  }
}
