import { Component, Input } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'ex-overlay-loader',
  templateUrl: './ex-overlay-loader.component.html',
  styleUrls: ['./ex-overlay-loader.component.scss']
})
export class ExOverlayLoaderComponent {

  constructor(private translate: TranslateService) {
  }

  @Input() direction: 'row' | 'column' = 'column';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() background: 'default' | 'strong' = 'default';
  @Input() color : 'primary' | 'secondary' = 'primary'
  @Input() text = this.translate.instant('tenders.loader.default');

}
