import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { TerritoryCountersService } from '../../shared/services/territory-counters.service';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryHelper } from '../../shared/helpers/territory.helper';
import { TerritoryManager } from '../../models/territory/territory-manager';
@Component({
  selector: 'app-page-tab',
  templateUrl: './page-tab.component.html',
  styleUrls: ['./page-tab.component.scss']
})

export class PageTabComponent implements OnInit {
  @Input() inFolderView = false;
  @Input() territoryUid!: string;
  articleUrl = 'article';
  adminDocUrl = 'admin-doc';
  impacterUrl = 'impacter';
  public currentUrl = 'article';
  public nbAdminDoc!: number;
  public nbImpacter!: number;
  public nbArticle!: number;

  constructor(
    private router: Router,
    private territoryCountersService: TerritoryCountersService,
    private apiService: ApiService,
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event['urlAfterRedirects'];
      });
  }

  ngOnInit() {
    if ( this.territoryUid ) {
      this.apiService.territory.retrieveTerritories(this.territoryUid).subscribe((territoryManager: TerritoryManager) => {
        const territoriesList = [...TerritoryHelper.transferToList([territoryManager.territory])];
        this.territoryCountersService.getTerritoryCounters().subscribe((territoryCounters) => {
          this.nbArticle = territoryCounters.articlesCount;
          const adminDocCountBody = {
            filters: {
              territories: territoriesList,
            },
            service: 'count'
          };
          const impacterCountBody = {
            filters: {
              territories: territoriesList,
            },
          };
          this.apiService.adminDoc.getAdminDocCount(adminDocCountBody)
            .pipe(first()).toPromise().then(((res: number | undefined) => this.nbAdminDoc = res || 0));
          this.apiService.impacter.getImpacterCount(impacterCountBody)
            .pipe(first()).toPromise().then(((res: number | undefined) => this.nbImpacter = res || 0));
        });
      });
    }
  }
}
