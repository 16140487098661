<div class="{{type}} {{variant}} {{size}}">
  <div #dropdownHeader class=""
       cdkOverlayOrigin #trigger="cdkOverlayOrigin"
  >
    <ng-container
      *ngIf="headerTemplate"
      [ngTemplateOutlet]="headerTemplate"
      [ngTemplateOutletContext]="headerContext"
    />
  </div>
  <ng-template
    #dropdownMenu
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="open"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (overlayOutsideClick)="clickOutside($event)"
    (attach)="disableScroll()"
    (detach)="enableScroll()"
  >
    <ng-container
      *ngIf="contentTemplate"
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="headerContext"
    />
  </ng-template>

</div>
