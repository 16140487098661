[
  {
    "value": "mon",
    "selected": true,
    "index": 1
  },
  {
    "value": "tue",
    "selected": true,
    "index": 2
  },
  {
    "value": "wed",
    "selected": true,
    "index": 3
  },
  {
    "value": "thu",
    "selected": true,
    "index": 4
  },
  {
    "value": "fri",
    "selected": true,
    "index": 5
  },
  {
    "value": "sat",
    "selected": false,
    "index": 6
  },
  {
    "value": "sun",
    "selected": false,
    "index": 0
  }
]
