import { Component, Input } from '@angular/core';

@Component({
  selector: 'ex-badge',
  templateUrl: './ex-badge.component.html',
  styleUrls: ['./ex-badge.component.scss']
})
export class ExBadgeComponent {
  @Input() icon?: string;
  @Input({required: true}) text!: string;
  @Input() tooltip?: string;
  @Input() height: number = 20;
  @Input() width: string = "fit-content";
  @Input() padding: number = 4;
  @Input() color?: string;
  /* Can be a percentage of the color input, e.g. "50%" */
  @Input() borderColor?: string;
  @Input() backgroundColor?: string;

  isPercentage(value: string) {
    return /^(\d+|(\.\d+))(\.\d+)?%$/.test(value);
  }
}
