import { TenderTypesEnum } from './tender-search-body';
import { WatchUser } from "../../common-explain/components/ex-watch-users-selector/ex-watch-users-selector.component";

export const MARKET_WATCHES_LIMIT = 10;
export const MW_NAME_MIN_LENGTH = 2;
export const MW_NAME_MAX_LENGTH = 50;
export const MW_MAX_USERS = 50;
export const MW_GENERATION_TIME_IN_HOURS = 7; // pour tester
export const MW_NEW_NAME_DEFAULT_VALUE = 'Nouvelle veille';
export const MW_DEFAULT_FREQUENCY = 'mon,tue,wed,thu,fri';

export function isGenerationHourBygone() {
  const now = new Date();
  return now.getHours() >= MW_GENERATION_TIME_IN_HOURS;
}

export interface DbMarketWatchSettings {
  market_types: string[];
  territories: string[];
  tender_types: TenderTypesEnum[];
}

export interface DbMarketWatchFilters {
  settings_json: DbMarketWatchSettings;
  topics_ids: number[];
}

export interface DbMarketWatch extends DbMarketWatchFilters {
  id: number;
  name: string;
  users?: WatchUser[];
  owner_user_id: number;
  day_of_week?: string;
  market_watch_dates?: any;
  created_at?: string;
  updated_at?: string;
}

export interface DtoMarketWatchUpdate {
  name?: string;
  settings_json?: DbMarketWatchSettings;
  topics_ids?: number[];
  users?: number[];
  day_of_week?: string;
}

export interface DtoMarketWatchCreate extends DbMarketWatchFilters{
  name: string;
  owner_user_id: number;
  day_of_week: string;
}

export interface MarketWatchDayOfWeek {
  value: string;
  selected: boolean;
  index: number
}

export class MarketWatch {
  id: number;
  name: string;
  settingsJson: DbMarketWatchSettings;
  topicsIds: number[];
  ownerUserId: number;
  dayOfWeek?: string[];
  createdAt?: string;
  marketWatchDates?: string[];
  private _updatedAt?: string;
  private _watchUsers?: WatchUser[];

  constructor(dbMarketWatch: DbMarketWatch) {
    this.id = dbMarketWatch.id;
    this.name = dbMarketWatch.name;
    this.settingsJson = dbMarketWatch.settings_json;
    this.topicsIds = dbMarketWatch.topics_ids;
    this.ownerUserId = dbMarketWatch.owner_user_id;
    this.dayOfWeek = dbMarketWatch.day_of_week?.split(',')
    this.marketWatchDates = dbMarketWatch.market_watch_dates?.sort();
    this.createdAt = dbMarketWatch.created_at;
    this._updatedAt = dbMarketWatch.updated_at;
    this._watchUsers = dbMarketWatch.users;
  }

  get watchFilters(): DbMarketWatchFilters {
    return {
      settings_json: this.settingsJson,
      topics_ids: this.topicsIds
    };
  }

  set watchFilters(value: DbMarketWatchFilters) {
    this.settingsJson = value.settings_json;
    this.topicsIds = value.topics_ids;
  }

  get updatedAt(): string | undefined{
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get watchUsers(): WatchUser[] | undefined {
    return this._watchUsers?.filter(user => user.id !== +(localStorage.getItem('user_id')||0)) ?? [];
  }

  set watchUsers(value: WatchUser[]) {
    this._watchUsers = value;
  }

  get isOwnedByCurrentUser(): boolean {
    return this.ownerUserId === +(localStorage.getItem('user_id') ?? 0);
  }
}
