const DIACRITICS_MAP= {
    'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
    'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
    'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
    'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
    'ß': 'ss', 'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
    'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
    'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
    'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
    'ÿ': 'y'
};

export class RegexpSearch {
    public static normalizedSearch (str1: string, str2: string): boolean {
        str1 = str1.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() ;
        str2 = str2.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLocaleLowerCase() ;
        return str1.includes(str2) ;
    }

    public static normalizedSearchWithAccents(str1: string, str2: string): boolean {
        return this.normalizeString(str1).includes(this.normalizeString(str2));
    }

    public static normalizeString(str: string): string {
        return str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .replace(/[àáâãäåæçèéêëìíîïðñòóôõöøùúûüűýþÿ]/g,
            // @ts-ignore
            (match: string | number) => DIACRITICS_MAP[match]);
    }
}


