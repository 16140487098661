<div class="input-container" [class.focused]="isFocused">
  <i class="ph ph-magnifying-glass"></i>
  <input
    #inputFilterText
    type="text"
    placeholder="{{placeholder}}"
    [(ngModel)]="text"
    (focus)="onInputFocus()"
    (blur)="onInputBlur()"
    (keydown.enter)="emitText(); inputFilterText.blur()"
    (keydown.tab)="emitText(); inputFilterText.blur()"
  >
  <i class="ph ph-x" *ngIf="text" (click)="clearInput()"></i>
</div>
