<div class="d-flex justify-content-between align-items-center item-list-header">
  <div class="d-flex align-items-center">
    <h3 class="table-title" id="table-title">
      {{(type === TopicType.CUSTOM ? 'topic.custom-topic' : 'topic.default-topic') | translate}}
    </h3>
    <div class="count-rectangle" *ngIf="!hasFilter">
      <p class="color">
        {{topics.length}}
      </p>
    </div>
    <div class="count-rectangle hasFilter ps-2 pe-2" *ngIf="hasFilter">
      <p class="color ps-2 pe-2">
        {{topics.length}}/{{topicListLength}}
      </p>
    </div>
  </div>
  <button *ngIf="type === TopicType.CUSTOM" class="btn-create d-flex" (click)="openCreationModal(choiceNewTopic)"
          data-cy="item-list-create-button">
    <div class="align-self-center icon icon--plus-circle"></div>
    <div class="text-uppercase fw-bold align-self-center">{{'alert.create-topic' | translate}}</div>
  </button>
</div>
<ng-container *ngIf="topics && topics.length > 0">
  <div class="row header-table">
    <div class="col-xl-7 col-lg-6 col-md-5 col-sm-4 d-flex align-items-center" (click)="changeOrder('name')">
      <p>
        {{'topic.name-and-description' | translate | uppercase}}
      </p>
      <ng-container *ngIf="currentOrder.orderBy === 'name'">
        <ng-container *ngIf="currentOrder.reverse">
          <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
        </ng-container>
        <ng-container *ngIf="!currentOrder.reverse">
          <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentOrder.orderBy !== 'name'">
        <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
      </ng-container>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-items-center " (click)="changeOrder('updatedAt')">
      <p>
        {{(type === TopicType.CUSTOM ? 'topic.latest-modification' : 'topic.latest-update') | translate | uppercase}}
      </p>
      <ng-container *ngIf="currentOrder.orderBy === 'updatedAt'">
        <ng-container *ngIf="currentOrder.reverse">
          <img src="assets/images/icon-arrow-up-fill.svg" alt="arrow-up">
        </ng-container>
        <ng-container *ngIf="!currentOrder.reverse">
          <img src="assets/images/icon-arrow-down-fill.svg" alt="arrow-down">
        </ng-container>
      </ng-container>
      <ng-container *ngIf="currentOrder.orderBy !== 'updatedAt'">
        <img src="assets/images/icon-arrow-both-fill.svg" alt="arrow-both-up-and-down">
      </ng-container>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4">
    </div>
  </div>
  <div>
    <div class="card-board" *ngFor="let topic of (currentOrder.orderBy === 'name' ? (topics | sortText: currentOrder.orderBy : currentOrder.reverse : forceUpdate) :
                                                                                   (topics | sortDate: currentOrder.orderBy : currentOrder.reverse : forceUpdate)) ;
                                                                                   let i = index">
      <div class="item">
        <div class="row entity-item" data-cy="topic-row">
          <div class="col-xl-7 col-lg-6 col-md-5 col-sm-4">
              <div class="d-flex">
                <div class="d-table-cell fw-bold name">
              <span data-cy="cliquable-topic-name-from-topic-list"
                    [routerLink]="buildRouterLink(topic.id)">{{topic.name}}</span>
            </div>
                <div class="rename d-table-cell align-items-start">
                  <ng-container *ngIf="topic.type===TopicType.CUSTOM">
                    <button appOpenRenameModal class="d-flex align-self-center"
                            [item]="topic"
                            [itemIndex]="i"
                            [itemsList]="topics"
                            [itemType]="'topic'"
                            (itemRenamed)="onRename($event)"
                    >
                      <img src="assets/images/pen-icon.svg" class="align-self-center rename-icon" alt="icon-duplicate">
                      <div class="align-self-center fw-bold">{{'topic.rename' | translate}}</div>
                    </button>
                  </ng-container>
                </div>
              </div>
            <div *ngIf="topic.description" class="fw-600 description">{{topic.description}}</div>
            <div *ngIf="!topic.description" class="fw-600 description color-basic-600 fst-italic">
              {{'wizard.no-description' | translate}}</div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 d-flex align-self-center">
            <p class="update-date" *ngIf="topic.updatedAt">
              {{topic.updatedAt | date:'dd MMMM yyyy':'':'fr' }}
            </p>
          </div>
          <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex justify-content-end">
            <ng-container *ngIf="topic.type===TopicType.CUSTOM">
              <div class="setting d-flex">
                <button class="d-flex align-self-center" [routerLink]="buildRouterLink(topic.id)">
                  <img src="assets/images/pen-icon.svg" class="align-self-center margin-image" alt="icon-settings">
                  <div class="align-self-center fw-bold text-uppercase">{{'alert.update' | translate}}</div>
                </button>
              </div>
              <div class="delete d-flex">
                <button class="d-flex align-self-center" (click)="openDeleteModal(content, topic.id, i)" placement="bottom"
                        ngbTooltip="{{'topic.delete' | translate}}" tooltipClass="custom-tooltip-trash" >
                  <img src="assets/images/icon-trash.svg" class="align-self-center icon-block--trash" alt="icon-delete">
                </button>
              </div>
            </ng-container>
            <ng-container *ngIf="topic.type===TopicType.DEFAULT">
              <div class="setting d-flex">
                <button class="d-flex align-self-center" [routerLink]="buildRouterLink(topic.id)">
                  <img src="assets/images/icon-view-edit.svg" class="align-self-center margin-image" alt="icon-view-edit">
                  <div class="align-self-center fw-bold text-uppercase">{{'topic.view-copy' | translate |
                    uppercase}}
                  </div>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="topicListLength === 0">
  <app-placeholder-no-topic [type]="type"></app-placeholder-no-topic>
</ng-container>
<ng-container *ngIf="topics?.length === 0 && topicListLength > 0">
  <div class="topics-placeholder">
    {{(type === TopicType.CUSTOM ? 'topic.notfound-custom' : 'topic.notfound-default') | translate}}
  </div>
</ng-container>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'alert.modal-delete-title-topic' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">

    </button>
  </div>
  <div class="modal-body">
    <div class="fw-bold message pe-3">
      <div>
        {{'alert.modal-delete-body-title-topic' | translate}}
      </div>
      <div *ngIf="TerritoryWatchRelatedToTopic.length > 0">
        {{'alert.modal-delete-body-description-topic' | translate: ({TerritoryWatchRelatedToTopicCount: TerritoryWatchRelatedToTopic.length}) }}
      </div>
    </div>
    <div>
      <ul style="padding:0 0 0 18px;">
        <li *ngFor="let item of TerritoryWatchRelatedToTopic">
          {{item.name}}
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="cancel fw-bold" (click)="modal.close()">{{'alert.cancel' |
      translate}}</button>
    <button type="button" class="btn confirm-delete d-flex" (click)="onDelete()">
      <div class="icon icon--trash align-self-center"></div>
      <div class="text-uppercase fw-bold align-self-center">{{'alert.delete-topic' | translate}}</div>
    </button>
  </div>
</ng-template>
<ng-template #choiceNewTopic let-modal>
  <div class="update-modal">
    <div class="modal-header">
      <p class="modal-title update-modal">{{'content.modal-choice-creation.title' | translate}}</p>
    </div>
    <div class="modal-body pt-0">
      <div class="row-radio">
        <div class="form-check radio-item mt-0">
          <input
            type="radio"
            class="form-check-input"
            id="checkFromScratch"
            [value]="true"
            [(ngModel)]="formFromScratch">
          <label class="form-check-label color-basic-800 fw-600 radio cursor-pointer" for="checkFromScratch">
            {{'content.modal-choice-creation.create-topic-from-scratch' | translate}}
          </label>
        </div>
      </div>
      <div class="row-radio">
        <div class="form-check radio-item mt-0">
          <input
            type="radio"
            class="form-check-input"
            id="checkFromExistingDefault"
            [value]="false"
            [(ngModel)]="formFromScratch">
          <label class="form-check-label color-basic-800 fw-600 radio cursor-pointer" for="checkFromExistingDefault">
            {{'content.modal-choice-creation.create-topic-from-default' | translate}}
          </label>
        </div>
      </div>
      <div appClickOutside (clickOutside)="hideIfOpen()" class="container-autocomplete" (click)="hideOrOpen($event)">
        <input appOutlineNone type="text"
               class="input-search search-color-size cursor-pointer"
               [(ngModel)]="defaultTopicName" (ngModelChange)="search($event)"
               [disabled]="formFromScratch"
               placeholder="{{'content.modal-choice-creation.choice-from-which-topic' | translate}}"
               id="dropdownDefaultTopicList"
               autocomplete="off"/>
        <div *ngIf="!show" class="icon icon--chevron-down position-absolute" aria-haspopup="true"
             aria-expanded="false"></div>
        <div *ngIf="show" class="icon icon--chevron-up position-absolute" aria-haspopup="true"
             aria-expanded="true"></div>
      </div>
      <div [hidden]="!defaultTopicsFiltered.length"
           class="dropdown-menu"
           [ngClass]="{'show': show}"
           aria-labelledby="dropdownDefaultTopicList">
        <div (click)="onItemClicked(topic)"
             class="dropdown-item search-color-size"
             [ngClass]="{'selected-topic': selectedTopic === topic.id}"
             *ngFor="let topic of defaultTopicsFiltered | sort: 'name'">
          <span class="dropdown-item-text w-100 cursor-pointer">{{topic.name}}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="cancel fw-bold" (click)="modal.close()">
        {{'content.modal-choice-creation.cancel' | translate}}
      </button>
      <button [disabled]="!formFromScratch && !selectedTopic" type="button" class="btn confirm-delete d-flex"
              data-cy="topic-configure-button"
              (click)="modal.close()"
              [routerLink]="formFromScratch ? [buildRouterLink('')] : [buildRouterLink(''+selectedTopic)]">
        <span
          class="text-uppercase fw-bold align-self-center cursor-pointer">{{'content.modal-choice-creation.configure' | translate}}</span>
        <em class="icon icon--edit align-self-center"></em>
      </button>
    </div>
  </div>
</ng-template>
