import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalRenameItemComponent } from '../../../components/modal-rename-item/modal-rename-item.component';
import { DbListItem } from '../../../models/generic/list-item';
import { DbTopic } from '../../../models/topic';
import { DisplayedTerritoryWatch } from '../../../models/territory-watch';
import { Folder } from '../../../models/folder';
@Directive({
  selector: '[appOpenRenameModal]'
})
export class OpenRenameModalDirective {

  @Input() item!: DbTopic | DisplayedTerritoryWatch | Folder;
  @Input() itemIndex!: number;
  @Input() itemsList!: Array<DbTopic | DisplayedTerritoryWatch | Folder>;
  @Input() itemType!: string;
  @Input() maxLength: number | null = null;
  @Output() itemRenamed = new EventEmitter<DbListItem>();


  constructor(private modalService: NgbModal) {
  }

  @HostListener('click', ['$event.target'])
  public clickRename() {
    const modal = this.modalService.open(ModalRenameItemComponent, { ariaLabelledBy: 'modal-basic-title', centered: true });
    modal.componentInstance.item = {
        id: this.item.id,
        name: this.item.name,
        item_type: this.itemType,
        index: this.itemIndex
      };
    modal.componentInstance.maxLength = this.maxLength;
    modal.componentInstance.itemNamesList = this.itemsList.filter(item => item !== this.item)
                                                          .map(item => item.name.trim().toLowerCase());
    modal.componentInstance.itemRenamed
      .pipe(first())
      .subscribe((itemRenamed: any) => {
        this.itemRenamed.emit(itemRenamed);
      });
  }
}
