import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pdf-reader-menus',
  templateUrl: './pdf-reader-menus.component.html',
  styleUrls: ['./pdf-reader-menus.component.scss']
})
export class PdfReaderMenusComponent {
  @Input() page!: number;
  @Input() nbPages!: number;
  @Output() pageEntered = new EventEmitter<number>();
  @Output() rotatePdf = new EventEmitter<string>();

  public zoomLevels = [
    'auto',
    'page-actual',
    'page-fit',
    'page-width',
    '25%',
    '50%',
    '75%',
    '100%',
    '150%',
    '200%',
    '250%',
    '400%',
  ];

  onPageEntered(event: number) {
    this.pageEntered.emit(event);
  }

  onRotatePdf() {
    this.rotatePdf.emit('rotate-clockwise');
  }

}
