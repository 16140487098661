import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TermModel } from '../../core/term/term.model';
import { TopicService } from '../topic-form/topic.service';

const tooltipExample = [
  {
    'word': 'ferme',
    'correct': true
  },
  {
    'word': 'fermes',
    'correct': false
  },
  {
    'word': 'fermée',
    'correct': false
  },
  {
    'word': 'fermer',
    'correct': false
  }
];

@Component({
  selector: 'app-topic-term',
  templateUrl: './topic-term.component.html',
  styleUrls: ['./topic-term.component.scss']
})
export class TopicTermComponent implements OnInit {
  @Input() termType!: string;
  @Input() terms: Array<TermModel> = [];
  @Input() blockIndex!: number;
  @Input() columnIndex!: number;
  @Input() optional!: boolean;
  @Input() blockActive!: boolean;
  @Input() complexityLimiterAchieved!: boolean;
  @Output() onTermsChange = new EventEmitter<{ columnIndex: number, terms: Array<TermModel> }>();
  @Output() onTermsChangeGenerated = new EventEmitter<{ value: string, index: number }>();

  public inputText: string = "";
  public isLockAll = false;
  public listExample = tooltipExample;

  constructor(private topicService: TopicService) {
  }

  ngOnInit(): void {
  }

  onEnter() {
    if (!this.inputText) {
      return;
    }
    const term = <TermModel> {
      term: this.inputText,
      locked: false
    };
    this.terms.push(term);
    this.updateValue();

    // Boolean to determine if the term was really added or blocked by the complexity limit
    let termWasAdded = true;
    if (this.topicService.evaluateTopicComplexity(this.topicService.topicIncludeBlocks) > this.topicService.topicComplexityMaxValue) {
      // In that case, we emit term list minus the last element to ensure it's not added
      this.onTermsChange.emit({
        columnIndex: this.columnIndex,
        terms: this.terms.slice(0, -1)
      });
      termWasAdded = false;
    }
    // In case term was not added, inputText stays the same.
    this.inputText = termWasAdded ? '' : this.inputText;
    this.updateValueGenerated(this.inputText);
  }

  onEnterGenerated(event: any) {
    this.updateValueGenerated(this.inputText);
  }

  onRemove(termIndex: number) {
    this.terms.splice(termIndex, 1);
    this.updateValue();
  }

  onLockChange(termIndex: number, value: boolean) {
    this.terms[termIndex].locked = value;
    this.isLockAll = !this.terms.some(term => !term.locked);
    this.updateValue();
  }

  onRemoveAll(): void {
    this.terms = [];
    this.updateValue();
  }

  onLockAll(): void {
    this.isLockAll = !this.isLockAll;
    this.terms.forEach(term => {
      term.locked = this.isLockAll;
    });
    this.updateValue();
  }


  updateValue() {
    this.onTermsChange.emit({
      columnIndex: this.columnIndex,
      terms: this.terms
    });
  }

  updateValueGenerated(value: string): void {
    this.onTermsChangeGenerated.emit({
      value: value,
      index: this.columnIndex
    });
  }
}
