<div class="error-toast-container align-middle no-select">
  <img src="assets/images/icon-alert-triangle-white.svg" class="align-self-center toast-image"
       alt="icon-alert">
  <div class="align-self-center toast-text">
    <p class="title fw-bold m-0">{{title | translate | uppercase}}</p>
    <p class="fst-italic m-0">
      {{text | translate}}
    </p>
  </div>
</div>
