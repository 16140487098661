import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Impacter } from '../../models/impacter/impacter';
import { SourceTerritories } from '../../models/territory/territory-counters';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  private entityId = new Subject<string | null>();
  private totalCount = new Subject<number | null>();
  private allCount = new Subject<number>();
  private nbResource = new Subject<number>();
  private sourcesList = new Subject<SourceTerritories[]>();
  private articleIMTotalCount = new Subject<number | null>();
  private articleIMCurrentCount = new Subject<number | null>();
  private document = new Subject<object>();
  private impacter = new Subject<Impacter | null>();
  private bookMarkPathsSubject = new Subject<{id: string, bookmarkPaths: Array<any>}>();
  contentRef: Element | null = document.querySelector('.root-content');

  sendEntityId(entityId: string | null): void {
    this.entityId.next(entityId);
  }

  getEntityId(): Observable<string | null> {
    return this.entityId.asObservable();
  }

  sendTotalCount(totalCount: number | null): void {
    this.totalCount.next(totalCount);
  }

  sendAllCount(allCount: number): void {
    this.allCount.next(allCount);
  }
  sendNbResource(nbResource: number): void {
    this.nbResource.next(nbResource);
  }
  sendSourcesList(sourcesList: SourceTerritories[]): void {
    this.sourcesList.next(sourcesList);
  }
  sendArticleIMTotalCount(totalCount: number | null): void {
    this.articleIMTotalCount.next(totalCount);
  }

  sendArticleIMCurrentCount(totalCount: number | null): void {
    this.articleIMCurrentCount.next(totalCount);
  }

  getTotalCount(): Observable<number | null> {
    return this.totalCount.asObservable();
  }

  getAllCount(): Observable<number> {
    return this.allCount.asObservable();
  }

  getNbResource(): Observable<number> {
    return this.nbResource.asObservable();
  }
  getSourcesList(): Observable<SourceTerritories[]> {
    return this.sourcesList.asObservable();
  }
  getArticleIMTotalCount(): Observable<number | null> {
    return this.articleIMTotalCount.asObservable();
  }

  getArticleIMCurrentCount(): Observable<number | null> {
    return this.articleIMCurrentCount.asObservable();
  }

  sendDocument(doc: object | null | undefined): void {
    if (this.contentRef) {
      if (doc) {
        this.contentRef.classList.add('full-height');
      } else {
        this.contentRef.classList.remove('full-height');
      }
    }
    if(doc){
      this.document.next(doc);
    }
  }

  sendImpacter(impacter: Impacter | null): void {
    this.impacter.next(impacter);
  }

  sendBookmarkPaths(id: string, bookmarkPaths: Array<any>) {
    this.bookMarkPathsSubject.next({id: id, bookmarkPaths: bookmarkPaths});
  }

  getDocument(): Observable<object> {
    return this.document.asObservable();
  }

  getImpacter(): Observable<Impacter | null> {
    return this.impacter.asObservable();
  }

  get BookMarkPathsSubject(): Observable<{id: string, bookmarkPaths: Array<any>}> {
    return this.bookMarkPathsSubject;
  }
}
