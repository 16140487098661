import { Injectable, TemplateRef } from '@angular/core';
import { Tender } from '../models/tender';
import { ExportService } from '../../shared/services/export.service';
import { TendersSearchService } from './tenders-search.service';
import { ApiSearchServiceEnum, PostTenderSearchBody, PostTenderSearchWatchBody, SortFieldEnum } from '../models/tender-search-body';
import { formatDateAsYYYYMMDD } from '../../shared/helpers/date-helper';
import { SortDirEnum } from '../../common-explain/models/sort';

@Injectable()
export class TendersExportService {
  selection?: Array<Tender>
  export?: { promise: Promise<void>, cancel: () => void }

  constructor(private exportService: ExportService) { }

  select(tender: Tender) {
    if (!this.selection) this.selection = [];
    if (this.selection.includes(tender)) this.selection.splice(this.selection.indexOf(tender), 1);
    else this.selection.push(tender);
  }

  toggle(open?: boolean) {
    if (open === undefined) open = !this.selection;
    if (open) this.selection = [];
    else this.selection = undefined;
  }

  exportSelection(tendersSearchService: TendersSearchService, toast: TemplateRef<any>) {
    if (!this.selection?.length) return;
    const filters = tendersSearchService.filters!;
    this.export = this.exportService.export(
      {
        user_token: localStorage.getItem('api_token'),
        user_id: localStorage.getItem('user_id'),
        service: filters.market_watch_id ? ApiSearchServiceEnum.SEARCH_MARKET_WATCH_TENDERS :
          (filters.status_ids ? ApiSearchServiceEnum.SEARCH_QUALIFIED_TENDERS :
            (filters.text ? ApiSearchServiceEnum.SEARCH_TEXT : ApiSearchServiceEnum.SEARCH_TOPICS)),
        sort: {
          field: "closing_date",
          dir: "asc"
        },
        filters: {
          uids: this.selection.map((tender) => tender.id),
        }
      },
      {template: toast, options: {classname: 'toast-export-in-progress toast-shape', id: 'list-export'}}
    )
    this.export.promise.finally(() => this.export = undefined);
  }

  exportAll(tendersSearchService: TendersSearchService, toast: TemplateRef<any>) {
    if (!tendersSearchService.tenders?.length) return;
    let searchBody;
    const filters = tendersSearchService.filters;
    if (!filters || !filters.indexation_period || !filters.territories) return;
    if (filters.market_watch_id) {
      searchBody = new PostTenderSearchWatchBody(
        filters.topics,
        filters.market_watch_id,
        formatDateAsYYYYMMDD(filters.indexation_period.to),
        filters.offset ?? 0,
        filters.limit ?? 25,
        filters.sort ?? {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE},
      )
    } else {
      searchBody = new PostTenderSearchBody(
        filters.topics,
        filters.text,
        filters.refined_search_text,
        filters.territories,
        filters.indexation_period,
        filters.tender_types,
        filters.status_ids,
        filters.assigned_user_ids,
        filters.offset ?? 0,
        filters.limit ?? 25,
        filters.sort ?? {dir: SortDirEnum.DESC, field: SortFieldEnum.INDEXATION_DATE}
      )
    }
    this.export = this.exportService.export(
      searchBody,
      {template: toast, options: {classname: 'toast-export-in-progress toast-shape', id: 'list-export'}}
    )
    this.export.promise.finally(() => this.export = undefined);
  }
}
