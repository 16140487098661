import { Component, Input, TemplateRef } from '@angular/core';
import { TreeNode } from '../../../tenders/models/tender-detail';

@Component({
  selector: 'ex-tree-node',
  templateUrl: './ex-tree-node.component.html',
  styleUrls: ['./ex-tree-node.component.scss']
})
export class ExTreeNodeComponent {
  @Input({required: true}) template!: TemplateRef<{ $implicit: TreeNode }>;
  @Input({required: true}) node!: TreeNode;
}
