<div class="overlay-loader-main-container"
     [ngClass]="{'overlay-loader-strong' : background === 'strong',
                 'overlay-loader-default' : background === 'default' }">

  <div class="overlay-loader-content-container"
       [ngClass]="{'flex-row' : direction === 'row',
                   'flex-column' : direction === 'column' }">
    <svg class="loader-icon {{size}} {{color}}"
         viewBox="0 0 45 45">
      <g fill="none" fill-rule="nonzero" stroke-width="2">
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate attributeName="r"
                   begin="1.5s" dur="3s"
                   values="6;22"
                   calcMode="linear"
                   repeatCount="indefinite"/>
          <animate attributeName="stroke-opacity"
                   begin="1.5s" dur="3s"
                   values="1;0" calcMode="linear"
                   repeatCount="indefinite"/>
          <animate attributeName="stroke-width"
                   begin="1.5s" dur="3s"
                   values="2;0" calcMode="linear"
                   repeatCount="indefinite"/>
        </circle>
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate attributeName="r"
                   begin="3s" dur="3s"
                   values="6;22"
                   calcMode="linear"
                   repeatCount="indefinite"/>
          <animate attributeName="stroke-opacity"
                   begin="3s" dur="3s"
                   values="1;0" calcMode="linear"
                   repeatCount="indefinite"/>
          <animate attributeName="stroke-width"
                   begin="3s" dur="3s"
                   values="2;0" calcMode="linear"
                   repeatCount="indefinite"/>
        </circle>
        <circle cx="22" cy="22" r="8">
          <animate attributeName="r"
                   begin="0s" dur="1.5s"
                   values="6;1;2;3;4;5;6"
                   calcMode="linear"
                   repeatCount="indefinite"/>
        </circle>
      </g>
    </svg>
    <div class="overlay-loader-text-container">
      <span class="overlay-loader-text {{color}}">{{ text }}</span>
    </div>
  </div>

</div>
