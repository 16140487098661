import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Article, DbArticle } from '../../../../models/article';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { SearchMode } from '../../../../components/search/search-mode';
import { LoaderService } from '../../../../common-explain/services/loader.service';
import { TerritoryCountService } from '../../territory-count.service';
import { EventTypeName } from '../../../../models/user-tracker';

interface ApiResponseSearchArticles {
  data: Array<DbArticle>;
  territory_count: object;
  total_count: number;
}

interface ResponseSearchArticles {
  data: Array<Article>;
  territory_count: object;
  total_count: number;
}

@Injectable({
  providedIn: 'root'
})
export class ApiArticleService {
  articleExcerptSize = 300;

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private loaderService: LoaderService,
    private territoryCountService: TerritoryCountService
  ) { }

  callGetArticleEndpoint(query: string, options: {headers: HttpHeaders, params?: HttpParams}): Observable<Article> {
    return this.http.get<DbArticle>(`${environment.explainApiUrl}articles/${query}`, options)
    .pipe(map((dbArticle: DbArticle) => new Article(dbArticle)));
  }

  getArticle(query: string, params: HttpParams, trackingEventName: EventTypeName | ''): Observable<Article> {
    return this.callGetArticleEndpoint(query, this.headersService.getHttpOptionsWithParams(params, trackingEventName));
  }

  /** Version of getArticle for the guest part of the application */
  getSharedArticle(query: string, params: HttpParams, shareToken: string): Observable<Article> {
    return this.callGetArticleEndpoint(query, this.headersService.getSharedHeadersWithParams(params, shareToken));
  }

  getArticleHtml(htmlKey: string): Observable<string> {
    return this.http.get(htmlKey, {responseType: 'text' as const});
  }

  getArticleCount(body?: object, params?: HttpParams): Observable<number> {
    return this.http.post<{ count: number }>(`${environment.explainApiUrl}articles/count`, body, this.headersService.getHttpOptionsWithParams(params))
      .pipe(map(((res: { count: number }) => res['count'])));
  }

  retrieveArticles(body: any, searchMode: SearchMode): Observable<ResponseSearchArticles> {
    this.loaderService.displayLoader(true);
    body['excerpt_size'] = this.articleExcerptSize;

    // If the topics mode is engaged -> adapt the body before request.
    if (searchMode === SearchMode.TOPICS) {
      body['filters'] = {...body['filters'], 'topics': [Number(body['filters']['text'])]};
      body = {...body, 'service': 'search-topics'};
    }

    const headers = body['impacter_id'] ?
      this.headersService.getTrackedHttpHeaders(EventTypeName.IMPACTER_ARTICLE_SEARCH) :
      this.headersService.httpHeaders;

    return this.http.post<ApiResponseSearchArticles>(`${environment.explainApiUrl}articles/search`, body, headers)
      .pipe(map((response: ApiResponseSearchArticles) => {
        if (response.territory_count) {
          this.territoryCountService.territoriesCount.next(response.territory_count);
        }
        this.loaderService.hideLoader(true);
        return {
          data: response.data.map((article) => new Article(article)),
          territory_count: response.territory_count,
          total_count: response.total_count
        };
      }));
  }

}
