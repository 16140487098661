import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor() { }

  get(key: string): any {
    return JSON.parse(localStorage.getItem('settings') ?? '{}')[key]
  }

  set(key: string, value: any) {
    const currentSettings = JSON.parse(localStorage.getItem('settings') ?? '{}');
    currentSettings[key] = value;
    localStorage.setItem('settings', JSON.stringify(currentSettings));
  }
}
