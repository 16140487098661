import { Pipe, PipeTransform } from '@angular/core';
import { SearchHelper } from '../../shared/helpers/search.helper';

@Pipe({
  name: 'emphasizeFilter'
})
export class EmphasizeFilterPipe implements PipeTransform {
  transform(text: string, searchText: string): any {
    if (!searchText) {
      return text;
    }
    return text.replace(
      SearchHelper.regexpPattern(searchText),
      (str) => `<b>${str}</b>`
    );
  }
}
