import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  @Input() show = false;
  @Input() center = false;
  @Input() topChoice = '';
  @Input() inView = false;
  @Input() inSearch = false;
  @Input() noResult = false;
  @Input() inImpacterView = false;

}
