import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { DbTendersUser, User } from '../../../../models/user';
import { HttpHeadersService } from '../../../../tenders/services/api/http-headers/http-headers.service';


@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  constructor(
    private http: HttpClient,
    private headersService: HeadersService,
    private httpHeadersService: HttpHeadersService
  ) { }

  searchUsers(filters: any): Observable<Array<User>> {
    const body = {
      'fields': [
        'id',
        'first_name',
        'last_name',
        'email',
        'user_level',
        'group_account_id',
        'is_active',
        'created_at',
        'updated_at',
        'modules'
      ],
      'filters': filters,
      'limit': 1000
    };
    return this.http.post(`${environment.explainApiUrl}user`, body, this.headersService.httpHeaders)
      .pipe(map((response: any) => {
        return response['data'].map((item: any) => {
          item.kind = 'DbUserPost';
          return new User(item);
        });
      }));
  }

  getOwnUser(): Observable<User> {
    const userId = localStorage.getItem('user_id');
    return this.http.get(`${environment.explainApiUrl}user/${userId}`, this.headersService.httpHeaders)
      .pipe(map((dbUserGet: any) => {
        dbUserGet.kind = 'DbUserGet';
        return new User(dbUserGet);
      }));
  }

  getGroupAccountTendersUsers(): Observable<DbTendersUser[]> {
    const fields = ['id', 'is_market_watch_user', 'name', 'email', 'color', 'initials'];
    const filters = {
      group_account_id: +(localStorage.getItem('group_account_id') ?? 0),
      level_id: 1,
      modules: ['tenders'],
      is_active: true
    };
    return this.http
      .post<{data: DbTendersUser[]}>(
        `${environment.explainApiUrl}user`,
        { fields, filters, limit: 100 },
        this.httpHeadersService.httpHeaders
      ).pipe(map(response =>
        response.data
          .sort((a, b) => a.name.localeCompare(b.name))
      ));
  }
}
