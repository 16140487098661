import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenderTerritoryLight } from '../../models/tender-territory';
import { frenchDepartment } from '../api/territory/french-departments';

const DEFAULT_MAX_SHOWN_TERRITORIES = 13;

@Injectable({
  providedIn: 'root'
})
export class TenderTerritoriesTextService {

  constructor(private translateService: TranslateService) { }

  getCompactString(territories: TenderTerritoryLight[], limit = DEFAULT_MAX_SHOWN_TERRITORIES) {
    if (territories.length >= frenchDepartment.length) return this.translateService.instant('tenders.all-territories');
    let content = territories.slice(0, limit).map((t) => `${t.name} (${t.code})`).join(', ');
    if (territories.length > limit) {
      const overflow = territories.length - limit;
      content += ' ' + this.translateService.instant(
        'tenders.and-x-territor' + (overflow > 1 ? 'ies' : 'y'),
        {count: overflow}
      );
    }
    return content;
  }

  getString(territories: TenderTerritoryLight[]) {
    return territories.map((t) => `${t.name} (${t.code})`).join(', ');
  }
}
