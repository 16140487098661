import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MarketWatchDayOfWeek } from '../../models/market-watch';
import { isEqual } from 'lodash';

@Component({
  selector: 'app-modal-manage-watch-frequency',
  templateUrl: './modal-manage-watch-frequency.component.html',
  styleUrls: ['./modal-manage-watch-frequency.component.scss']
})
export class ModalManageWatchFrequencyComponent implements OnInit {
  @Input() daysOfWeek: string[] = [];

  days = require('../../../shared/helpers/days.json') as MarketWatchDayOfWeek[];

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit() {
    this.days.forEach((day) => {
      day.selected = this.daysOfWeek.includes(day.value);
    })
  }

  cancel() {
    this.activeModal.dismiss();
  }

  confirm() {
    if (!isEqual(this.getSelectedDays(),this.daysOfWeek)) {
      this.activeModal.close(this.getSelectedDays());
    } else this.cancel()
  }

  getSelectedDays() {
    return this.days.filter((day) => day.selected).map((day) => day.value);
  }

  onSelectDay(dayIndex: number) {
    this.days[dayIndex].selected = !this.days[dayIndex].selected;
  }
}
