<svg *ngIf="tender.watchAppearance" width="20" height="20" viewBox="0 0 20 20"
     class="occurrence-success"
     [ngbTooltip]="link"
     placement="bottom auto"
     tooltipClass="tooltip-watch-appearance"
     [closeDelay]="1000"
>
  <path fill-rule="evenodd" clip-rule="evenodd"
        [style.color]="'var(--basic-600)'"
        class="glass"
        d="M8.7496 6.25006H10.4361C10.6166 6.69586 10.8491 7.11504 11.126 7.50006H8.75272V13.111C8.75502 13.5531 8.6783 13.992 8.52616 14.4071C8.18063 15.3312 7.48487 16.0819 6.58966 16.4965C5.69446 16.9111 4.67192 16.9562 3.74366 16.6221C2.81539 16.288 2.05619 15.6015 1.63058 14.7115C1.20497 13.8215 1.14724 12.7996 1.46991 11.8673V11.8587C1.47138 11.8531 1.47321 11.8476 1.47538 11.8423C1.52724 11.7008 1.5875 11.5625 1.65585 11.4282L4.90038 4.04537C4.93165 3.97428 4.97589 3.90963 5.03085 3.85475C5.263 3.62254 5.53862 3.43834 5.84198 3.31267C6.14533 3.187 6.47046 3.12231 6.79882 3.12231C7.12717 3.12231 7.45231 3.187 7.75566 3.31267C8.05901 3.43834 8.33463 3.62254 8.56679 3.85475C8.62481 3.91284 8.67081 3.98178 8.70218 4.05766C8.73355 4.13353 8.74966 4.21484 8.7496 4.29694V6.25006ZM7.34882 13.9766C7.11802 14.5924 6.65387 15.0925 6.05693 15.3685C5.46 15.6445 4.77835 15.6741 4.15971 15.451C3.54106 15.2279 3.03524 14.77 2.75185 14.1766C2.46845 13.5831 2.43031 12.9019 2.64569 12.2805L2.7871 11.9579C3.047 11.4638 3.46477 11.071 3.97384 10.842C4.48291 10.6129 5.05399 10.5609 5.59609 10.694C6.13819 10.8272 6.62012 11.138 6.96509 11.5769C7.31006 12.0158 7.49822 12.5575 7.4996 13.1157V13.1251C7.49955 13.4166 7.44852 13.7058 7.34882 13.9798V13.9766Z"
        fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd"
        [style.color]="'var(--basic-600)'"
        class="glass"
        d="M16 10.0001C16.5616 10.0001 17.1052 9.92291 17.6207 9.77862L18.3465 11.4282C18.4148 11.5625 18.4751 11.7008 18.5269 11.8423C18.5292 11.8478 18.5311 11.8536 18.5324 11.8594V11.8673L18.5355 11.868C18.8582 12.8003 18.8005 13.8222 18.3749 14.7123C17.9493 15.6023 17.1901 16.2888 16.2618 16.6229C15.3335 16.957 14.311 16.9119 13.4158 16.4973C12.5206 16.0826 11.8248 15.332 11.4793 14.4079C11.3271 13.9928 11.2504 13.5539 11.2527 13.1118V7.66977C12.3502 9.08739 14.0685 10.0001 16 10.0001ZM15.859 15.4743C15.4811 15.6126 15.0754 15.6577 14.6763 15.6056C14.2773 15.5536 13.8967 15.406 13.5669 15.1754C13.2371 14.9448 12.9679 14.6379 12.7821 14.281C12.5962 13.924 12.4993 13.5275 12.4996 13.1251V13.1165C12.5017 12.5585 12.6903 12.0173 13.0355 11.5789C13.3808 11.1406 13.8627 10.8303 14.4046 10.6976C14.9465 10.5648 15.5173 10.6171 16.0261 10.8462C16.5348 11.0753 16.9523 11.468 17.2121 11.9618L17.3535 12.2844C17.5758 12.9057 17.5434 13.5897 17.2635 14.1873C16.9835 14.7848 16.4786 15.2475 15.859 15.4743Z"
        fill="currentColor"/>
  <path
    [style.color]="'var(--solid-success-600)'"
    class="dot"
    d="M16.8027 7.91944C18.6271 7.54778 20 5.93422 20 4C20 1.79086 18.2091 0 16 0C13.9922 0 12.3298 1.47936 12.0436 3.40756C12.0419 3.41856 12.0403 3.42957 12.0388 3.44059C12.0132 3.62339 12 3.81015 12 4C12 6.20914 13.7909 8 16 8C16.2749 8 16.5434 7.97226 16.8027 7.91944Z"
    fill="currentColor"/>
</svg>


<svg *ngIf="tender.watchAppearance === null"
     class="occurrence-no-success"
     width="20" height="20" viewBox="0 0 20 20"
     [ngbTooltip]="'tenders.tooltip-watch-appearance.not-found' | translate"
     placement="bottom auto"
     tooltipClass="tooltip-watch-appearance">
  <path fill-rule="evenodd" clip-rule="evenodd"
        [style.color]="'var(--basic-300)'"
        d="M8.7496 6.25006H10.4361C10.6166 6.69586 10.8491 7.11504 11.126 7.50006H8.75272V13.111C8.75502 13.5531 8.6783 13.992 8.52616 14.4071C8.18063 15.3312 7.48487 16.0819 6.58966 16.4965C5.69446 16.9111 4.67192 16.9562 3.74366 16.6221C2.81539 16.288 2.05619 15.6015 1.63058 14.7115C1.20497 13.8215 1.14724 12.7996 1.46991 11.8673V11.8587C1.47138 11.8531 1.47321 11.8476 1.47538 11.8423C1.52724 11.7008 1.5875 11.5625 1.65585 11.4282L4.90038 4.04537C4.93165 3.97428 4.97589 3.90963 5.03085 3.85475C5.263 3.62254 5.53862 3.43834 5.84198 3.31267C6.14533 3.187 6.47046 3.12231 6.79882 3.12231C7.12717 3.12231 7.45231 3.187 7.75566 3.31267C8.05901 3.43834 8.33463 3.62254 8.56679 3.85475C8.62481 3.91284 8.67081 3.98178 8.70218 4.05766C8.73355 4.13353 8.74966 4.21484 8.7496 4.29694V6.25006ZM7.34882 13.9766C7.11802 14.5924 6.65387 15.0925 6.05693 15.3685C5.46 15.6445 4.77835 15.6741 4.15971 15.451C3.54106 15.2279 3.03524 14.77 2.75185 14.1766C2.46845 13.5831 2.43031 12.9019 2.64569 12.2805L2.7871 11.9579C3.047 11.4638 3.46477 11.071 3.97384 10.842C4.48291 10.6129 5.05399 10.5609 5.59609 10.694C6.13819 10.8272 6.62012 11.138 6.96509 11.5769C7.31006 12.0158 7.49822 12.5575 7.4996 13.1157V13.1251C7.49955 13.4166 7.44852 13.7058 7.34882 13.9798V13.9766Z"
        fill="currentColor"/>
  <path fill-rule="evenodd" clip-rule="evenodd"
        [style.color]="'var(--basic-300)'"
        d="M16 10.0001C16.5616 10.0001 17.1052 9.92291 17.6207 9.77862L18.3465 11.4282C18.4148 11.5625 18.4751 11.7008 18.5269 11.8423C18.5292 11.8478 18.5311 11.8536 18.5324 11.8594V11.8673L18.5355 11.868C18.8582 12.8003 18.8005 13.8222 18.3749 14.7123C17.9493 15.6023 17.1901 16.2888 16.2618 16.6229C15.3335 16.957 14.311 16.9119 13.4158 16.4973C12.5206 16.0826 11.8248 15.332 11.4793 14.4079C11.3271 13.9928 11.2504 13.5539 11.2527 13.1118V7.66977C12.3502 9.08739 14.0685 10.0001 16 10.0001ZM15.859 15.4743C15.4811 15.6126 15.0754 15.6577 14.6763 15.6056C14.2773 15.5536 13.8967 15.406 13.5669 15.1754C13.2371 14.9448 12.9679 14.6379 12.7821 14.281C12.5962 13.924 12.4993 13.5275 12.4996 13.1251V13.1165C12.5017 12.5585 12.6903 12.0173 13.0355 11.5789C13.3808 11.1406 13.8627 10.8303 14.4046 10.6976C14.9465 10.5648 15.5173 10.6171 16.0261 10.8462C16.5348 11.0753 16.9523 11.468 17.2121 11.9618L17.3535 12.2844C17.5758 12.9057 17.5434 13.5897 17.2635 14.1873C16.9835 14.7848 16.4786 15.2475 15.859 15.4743Z"
        fill="currentColor"/>
  <path
    [style.color]="'var(--basic-500)'"
    d="M16.8027 7.91944C18.6271 7.54778 20 5.93422 20 4C20 1.79086 18.2091 0 16 0C13.9922 0 12.3298 1.47936 12.0436 3.40756C12.0419 3.41856 12.0403 3.42957 12.0388 3.44059C12.0132 3.62339 12 3.81015 12 4C12 6.20914 13.7909 8 16 8C16.2749 8 16.5434 7.97226 16.8027 7.91944Z"
    fill="currentColor"/>
</svg>

<ng-template #link>
<span>{{'tenders.tooltip-watch-appearance.watch' | translate}}</span>
  "<a [href]="tender.watchAppearance?.market_watch_url"
     target="_blank" rel="noopener noreferrer">{{tender.watchAppearance?.market_watch_name}}</a>"
  <span>{{'tenders.tooltip-watch-appearance.from' | translate}}{{tender.watchAppearance?.market_watch_date | localDate}}.</span>
</ng-template>
