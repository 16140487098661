import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';

export enum EventTypeName {
    TENDER_SEARCH = 'tender-search',
    OTHER = 'other'
}

@Injectable({
    providedIn: 'root'
})
export class HttpHeadersService {

    constructor() {
    }

    /**
     * Method to get httpHeaders containing the user's goodwill Token.
     * Usefull to call the goodwill api.
     */
    get httpHeaders(): { headers: HttpHeaders } {
        return {
            headers:
                new HttpHeaders({
                    'accept': 'text/plain',
                    'X-API-KEY': localStorage.getItem('api_token') || '',
                    'Content-Type': 'application/json'
                })
        };
    }

    /** Méthode pour obtenir des httpHeaders permettant à l'appel d'être tracké. */
    public getTrackedHttpHeaders(trackedEventType: EventTypeName): { headers: HttpHeaders } {
        return {
            headers:
                new HttpHeaders({
                    'accept': 'text/plain',
                    'X-API-KEY': localStorage.getItem('api_token') || '',
                    'Content-Type': 'application/json',
                    'is-tracked': 'true',
                    'tracked-method-name': trackedEventType
                })
        };
    }

    /** Method to obtain options objects with headers and given parameters. */
    public getHttpOptionsWithParams(
        params?: HttpParams,
        trackingEventName?: EventTypeName | ''
    ): { headers: HttpHeaders, params?: HttpParams } {
        let headers: { headers: HttpHeaders };
        if (trackingEventName) {
            headers = this.getTrackedHttpHeaders(trackingEventName);
        } else {
            headers = this.httpHeaders;
        }
        return {...headers, params: params};
    }

}
