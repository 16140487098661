<div *ngIf="!guestDisplay" data-cy="custom-breadcrumb" class="d-flex breadcrumb-custom no-select">
  <div *ngFor="let breadcrumb of breadcrumbs, let i = index" class="item"
  [ngClass]="{'last-div-item': (i === breadcrumbs.length - 1)}">
    <span *ngIf="i === breadcrumbs.length - 1" class="last-item">
      <span>{{breadcrumb.label | translate}}</span>
      <span class="last-label" *ngIf="label"> | {{label | translate}}</span>
    </span>
    <span *ngIf="i < breadcrumbs.length - 1">
      <a [routerLink]="['/' + getUrl(breadcrumb.url)]">
        <span>{{ breadcrumb.label | translate}}</span>
        <span *ngIf="i === breadcrumbs.length - 2 && preLabel"> | {{preLabel | truncate: 50:'...'}}</span>
      </a>
      <img src="/assets/images/arrow-ios-right.svg" alt="arrow-ios-right">
    </span>
  </div>
</div>
