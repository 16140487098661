/**
 * Interface and Class describing data structure of response received from entity update.
 */
export interface DbEntityUpdatedPatchResponseBody {
  id: number;
  status: string;
}

export class EntityUpdatedPatchResponseBody {
  private _id: number;
  private _status: string;

  constructor(dbEntityUpdatedPatchResponseBody: DbEntityUpdatedPatchResponseBody) {
    this._id = dbEntityUpdatedPatchResponseBody.id;
    this._status = dbEntityUpdatedPatchResponseBody.status;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }
}
