<div class="new-item-modal">
  <div class="modal-header">
    <h4 class="modal-title">{{translationFileSection + '.modal-edit-item-title' | translate}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()">

    </button>
  </div>
  <div class="modal-body">
    <div class="fw-bold message pe-3">
      <div>
        {{translationFileSection + '.modal-edit-item-sub-title' | translate}}
      </div>
    </div>
  </div>
  <div class="input-name">
    <div class="input-container">
      <input
        autocomplete="off"
        type="text"
        data-cy="modal-name-input"
        maxlength="40"
        [formControl]="editItemNameForm"
        [value]="editItemNameForm.value"
        ngbAutofocus
        (keyup.enter)="modifyItem()"
      >
    </div>
    <div *ngIf="editItemNameForm.dirty && (f?.errors || displayAlreadyExists  || displayNameNotAllowed)"
         class="error-container d-flex align-items-baseline">
      <div *ngIf="f?.errors || displayAlreadyExists  || displayNameNotAllowed" class="error-icon">
        <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
      </div>
      <div *ngIf="f?.errors?.['required']" class="error-text">
        {{translationFileSection + '.modal-new-item-error-required' | translate}}
      </div>
      <div *ngIf="f?.errors?.['maxlength']" class="error-text">
        {{translationFileSection + '.modal-new-item-error-maxlength' | translate}}
      </div>
      <div *ngIf="displayAlreadyExists" class="error-text">
        {{translationFileSection + '.modal-new-item-error-existing-name' | translate}}
      </div>
      <div *ngIf="displayNameNotAllowed" class="error-text"
           [innerHTML]="translationFileSection+'.modal-new-item-error-not-allowed-name' | translate"
      >
      </div>
    </div>

  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="delete fw-bold"
            (click)="openDeleteModal()">
      <mat-icon class="trash-icon" svgIcon="trash-icon"></mat-icon>{{translationFileSection + '.modal-edit-delete' | translate}}</button>
    <button [disabled]="f?.errors" type="button" class="btn confirm-delete d-flex" (click)="modifyItem()">
      <span class="text-uppercase fw-bold align-self-center">{{translationFileSection + '.modal-edit-save' | translate}}</span>
    </button>
  </div>
</div>
