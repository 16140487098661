export interface DbFolder {
  bookmark_entity_id: number;
  entity_label: string;
  documents_count: number;
  admin_docs_count: number;
  articles_count: number;
  last_modified: Date;
}

export class Folder {
  private _id: number;
  private _name: string;
  private _documentsCount: number;
  private _adminDocsCount: number;
  private _articlesCount: number;
  private _lastModified: Date;
  private _containsDocument?: boolean;

  constructor(dbFolder: DbFolder, containsDocument = false) {
    this._id = dbFolder.bookmark_entity_id;
    this._name = dbFolder.entity_label;
    this._documentsCount = dbFolder.documents_count;
    this._adminDocsCount = dbFolder.admin_docs_count;
    this._articlesCount = dbFolder.articles_count;
    this._lastModified = dbFolder.last_modified;
    this._containsDocument = containsDocument;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get documentsCount(): number {
    return this._documentsCount;
  }

  set documentsCount(value: number) {
    this._documentsCount = value;
  }

  get admin_docsCount(): number {
    return this._adminDocsCount;
  }

  set admin_docsCount(value: number) {
    this._adminDocsCount = value;
  }

  get articlesCount(): number {
    return this._articlesCount;
  }

  set articlesCount(value: number) {
    this._articlesCount = value;
  }

  get lastModified(): Date {
    return this._lastModified;
  }

  set lastModified(value: Date) {
    this._lastModified = value;
  }

  get containsDocument(): boolean | undefined {
    return this._containsDocument;
  }

  set containsDocument(value: boolean) {
    this._containsDocument = value;
  }

}
