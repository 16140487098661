<div class="template-container">
  <div class="card-block">
    <div class="block-body">
      <div class="block-container">
        <div class="fw-bold title">{{'template.configuration' | translate}}</div>
        <div class="term-block">
          <div class="fw-600">{{'template.sanitation' | translate}}</div>
          <div class="fw-600">{{'template.potable-water' | translate}}</div>
        </div>
      </div>
    </div>
    <div class="block-footer">
      <div class="fw-bold title">{{'template.results' | translate}}</div>
      <div class="d-flex request-chain">
        <div class="fw-bold">{{'template.sanitation' | translate}}</div>
        <div class="color-basic-600 fw-600">{{'content.or' | translate}}</div>
        <div class="fw-bold">{{'template.potable-water' | translate}}</div>
      </div>
    </div>
  </div>
  <app-example-guide-template [examples]="examples"></app-example-guide-template>
</div>
