import { ExplainModuleEnum } from "../../../../shared/services/module-manager.service";

export class TenderTopicBuilder {

    public getUserTopics_buildBody(module?: ExplainModuleEnum | undefined) {
        const userId = localStorage.getItem('user_id');
        return {
            'fields': [
                'id',
                'name',
                'type',
                'description',
                'updated_at',
                'created_at'
            ],
            'filters': {
                'owner_user_id': userId,
                'modules': module && [module]
            },
            'limit': 1000,
            'offset': 0,
            'order_by': [
              {
                'direction': 'asc',
                'field': 'name'
              }
            ]
        };
    }
}
