import { Component, EventEmitter, Output } from '@angular/core';
import { UserSessionService } from "../../../shared/services/user-session.service";
import {
  ExplainModuleEnum,
  ExplainModuleEnumExtended,
  ModuleManagerService
} from "../../../shared/services/module-manager.service";
import { AutoUnsubscribe } from "../../decorators/auto-unsubscribe";

interface MenuItem {
  label: string,
  phIconName: string,
  routerLink?: string,
  module: ExplainModuleEnumExtended
}

@AutoUnsubscribe
@Component({
  selector: 'ex-menu',
  templateUrl: './ex-menu.component.html',
  styleUrls: ['./ex-menu.component.scss']
})
export class ExMenuComponent {
  @Output() logout = new EventEmitter<any>();
  currentModule: ExplainModuleEnum = ExplainModuleEnum.TERRITORY_INTEL;
  alternativeSuggestedModule: ExplainModuleEnum | null = null;
  displayedMenuItems: MenuItem[] = [];
  menuItems: MenuItem[] = [
    //region *** Territory Intel ***
    {
      label: 'search',
      phIconName: 'magnifying-glass',
      routerLink: '/search',
      module: ExplainModuleEnumExtended.TERRITORY_INTEL
    },
    {
      label: 'folders',
      phIconName: 'folder-simple',
      routerLink: '/folder',
      module: ExplainModuleEnumExtended.TERRITORY_INTEL
    },
    {
      label: 'alerts',
      phIconName: 'bell',
      routerLink: '/alert',
      module: ExplainModuleEnumExtended.TERRITORY_INTEL
    },
    {
      label: 'topics',
      phIconName: 'chat-circle-text',
      routerLink: '/topics',
      module: ExplainModuleEnumExtended.TERRITORY_INTEL
    },
    //endregion

    //region *** Tenders ***
    {
      label: 'market-watch',
      phIconName: 'binoculars',
      routerLink: '/tenders/watch',
      module: ExplainModuleEnumExtended.TENDERS
    },
    {
      label: 'board',
      phIconName: 'target',
      routerLink: '/tenders/board',
      module: ExplainModuleEnumExtended.TENDERS
    },
    {
      label: 'search',
      phIconName: 'magnifying-glass',
      routerLink: '/tenders/search',
      module: ExplainModuleEnumExtended.TENDERS
    },
    {
      label: 'topics',
      phIconName: 'chat-circle-text',
      routerLink: '/tenders/topics',
      module: ExplainModuleEnumExtended.TENDERS
    }
    //endregion
  ]
  accountMenuItems: MenuItem[] = [
    {
      label: '',
      phIconName: 'user-circle-gear',
      routerLink: '/settings',
      module: ExplainModuleEnumExtended.ALL
    },
    {
      label: '',
      phIconName: 'sign-out',
      routerLink: undefined,
      module: ExplainModuleEnumExtended.ALL
    }
  ];

  constructor(private moduleManagerService: ModuleManagerService,
              private userSessionService: UserSessionService) {
    this.moduleManagerService.moduleChanged.subscribe(module => {
      this.currentModule = module;
      this.alternativeSuggestedModule = this.moduleManagerService.alternativeSuggestedModule;
      this.displayedMenuItems = this.menuItems.filter(item => {
        return (item.module as unknown as ExplainModuleEnum) === this.currentModule &&
          this.userSessionService.userSession?.modules.includes(this.currentModule)
      });
    });
  }

  signOut() {
    this.logout.emit();
  }

  onClickAccountMenuItem(item: MenuItem) {
    if (item.phIconName === 'sign-out') {
      this.signOut();
    }
  }
}
