import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { TerritoryCounters } from '../../models/territory/territory-counters';

@Injectable({
  providedIn: 'root'
})
export class TerritoryCountersService {
  countersSubject: ReplaySubject<TerritoryCounters>;

  constructor() {
    this.countersSubject = new ReplaySubject<TerritoryCounters>(0);
  }

  getTerritoryCounters(): Observable<TerritoryCounters> {
    return this.countersSubject;
  }
}
