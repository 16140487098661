export interface DbDceDocumentUid {
  uid: string;
}

export class DceDocumentUid {
  uid: string;

  constructor(dbDceDocumentUid: DbDceDocumentUid) {
    this.uid = dbDceDocumentUid.uid;
  }
}
