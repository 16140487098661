'use strict';

const AssertError = require('./error');
const internals = {};
module.exports = function (condition, ...args) {
  if (condition) {
    return;
  }
  if (args.length === 1 && args[0] instanceof Error) {
    throw args[0];
  }
  throw new AssertError(args);
};