import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUserTerritoryWatchService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /**
   * PUT method in charge of switching the active state of a territory watch.
   * Param isActive correspond to the new, wanted state after calling the Endpoint.
   */
  switchTerritoryWatchActiveState(territoryWatchId: string, isActive: boolean): Observable<any> {
    const body = {
      territory_watch_id: territoryWatchId,
      is_active: isActive
    };
    return this.http
      .put(
        `${environment.explainApiUrl}user_territory_watches/is_active`,
        body,
        this.headersService.httpHeaders
      );
  }
}
