import { booleanAttribute, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DropdownItem } from '../ex-dropdown/ex-dropdown.component';
import { SortDirEnum } from '../../models/sort';
import { DbPostSearchSort } from '../../../tenders/models/tender-search-body';

export interface SortDropdownItem extends DropdownItem {
  direction: SortDirEnum;
  unidirectional?: boolean;
  onSort?: (item: SortDropdownItem) => void;
}

@Component({
  selector: 'ex-sort-dropdown',
  templateUrl: './ex-sort-dropdown.component.html',
  styleUrls: ['./ex-sort-dropdown.component.scss']
})
export class ExSortDropdownComponent implements OnChanges{
  @Input({required: true}) items!: SortDropdownItem[];
  @Input() defaultValue?: DbPostSearchSort;
  @Input() headerHeight?: number;
  @Input({transform: booleanAttribute}) useShortLabel = false
  activeItem?: SortDropdownItem;

  @Output() sort = new EventEmitter<DbPostSearchSort>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultValue'] && this.defaultValue) {
      this.activeItem = this.items.find(item => item.value === this.defaultValue?.field);
      if (this.activeItem && this.activeItem.direction !== this.defaultValue.dir) {
        this.changeState(this.activeItem)
      }
    }
  }

  changeState(item: SortDropdownItem) {
    if (item === this.activeItem && !item.unidirectional) {
      item.direction = item.direction === SortDirEnum.DESC ? SortDirEnum.ASC : SortDirEnum.DESC;
    }
    if (item.onSort) item.onSort(item);
  }

  onSort(item: SortDropdownItem) {
    this.changeState(item)
    this.activeItem = item;
    this.sort.emit({field: item.value, dir: item.direction});
  }
}
