import { Injectable } from '@angular/core';
import { HttpHeadersService } from '../http-headers/http-headers.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DbTenderTopic, TenderTopic } from '../../../models/tender-topic';
import { TenderTopicBuilder } from './tender-topic.builder';
import { ExplainModuleEnum } from "../../../../shared/services/module-manager.service";

@Injectable({
    providedIn: 'root'
})
export class ApiTenderTopicService {

    // helper containing all the methods to build bodies for the requests
    builder = new TenderTopicBuilder();

    constructor(private httpHeadersService: HttpHeadersService,
                private http: HttpClient) {
    }

    /** POST method retrieving all user's topics */
    getUserTopics(module?: ExplainModuleEnum | undefined): Observable<TenderTopic[]> {
        return this.http
            .post<DbTenderTopic>(
                `${environment.explainApiUrl}topics`,
                this.builder.getUserTopics_buildBody(module),
                this.httpHeadersService.httpHeaders)
            .pipe(
                map((data: any) => {
                    return data['data'].map((dbTenderTopic: DbTenderTopic) => {
                        return new TenderTopic(dbTenderTopic);
                    });
                }));

    }
}

