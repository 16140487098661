import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe  implements PipeTransform {
  transform(array: any[], field: string, reverse: boolean = false): any[] {
    array.sort((a: any, b: any) => {

      if (reverse) {
        if (a[field] > b[field]) {
          return -1;
        } else if (a[field] < b[field]) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }
}
