import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  WatchUser
} from "../../../common-explain/components/ex-watch-users-selector/ex-watch-users-selector.component";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-manage-watch-users',
  templateUrl: './modal-manage-watch-users.component.html',
  styleUrls: ['./modal-manage-watch-users.component.scss']
})
export class ModalManageWatchUsersComponent implements OnChanges {
  @Input() marketWatchUsers: WatchUser[] = [];
  selectedUsers: WatchUser[] = [];

  constructor(private activeModal: NgbActiveModal) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['marketWatchUsers']) {
      this.selectedUsers = this.marketWatchUsers;
    }
  }

  selectedUsersUpdate(event: WatchUser[]) {
    this.selectedUsers = event;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  confirm() {
    this.activeModal.close(this.selectedUsers);
  }
}
