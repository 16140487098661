import {
  booleanAttribute,
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { RegexpSearch } from "../../../shared/helpers/regex";
import { BehaviorSubject } from "rxjs";
import { AutoUnsubscribe } from "../../decorators/auto-unsubscribe";
import { mod } from "../../../shared/helpers/math";

export interface FilterItem {
  id: number;
  name: string;
  description?: string;
}
@AutoUnsubscribe
@Component({
  selector: 'app-filter-text-and-items',
  templateUrl: './filter-text-and-items.component.html',
  styleUrls: ['./filter-text-and-items.component.scss']
})
export class FilterTextAndItemsComponent implements OnChanges {

  @ViewChild('itemsContainer') itemsContainer?: ElementRef;
  @Input() searchText = '';
  @Input() items?: FilterItem[];
  @Input() filteredItems?: FilterItem[];
  @Input({required: true}) itemsSectionName!: string;
  @Input({required: true}) placeholder!: string;
  @Input({transform: booleanAttribute}) openAfterInit = false;
  @Output() itemSelected = new EventEmitter<FilterItem | null>();
  @Output() textSelected = new EventEmitter<string | null>();
  selectedItem = new BehaviorSubject<FilterItem | null>(null);
  selectedText = new BehaviorSubject<string | null>(null);
  show = false;
  focusIndex = -1;
  displayText = '';
  forceToggle$ = new EventEmitter<boolean>();

  constructor() {
    this.selectedItem.subscribe((res) => {
      this.itemSelected.emit(res);
    });
    this.selectedText.subscribe((res) => {
      this.textSelected.emit(res);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchText']) {
      this.searchText = this.searchText?.trim();
    }
    if (changes['items']) {
      // tri par ordre alphabétique des noms des items
      this.filteredItems = this.items?.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  textChangeFromDropdown(event: string | undefined) {
    this.searchText = event?.trim() ?? '';
    this.focusIndex = -1;
    if (this.searchText) {
      this.filteredItems = this.items?.filter(item => {
        return RegexpSearch.normalizedSearch(item.name, this.searchText) ||
          RegexpSearch.normalizedSearch(item.description ?? '', this.searchText);
      });
    } else {
      this.filteredItems = this.items;
      this.selectText(null);
    }
  }

  selectItem(item: FilterItem | null) {
    if (item) this.show = false;
    this.selectedItem.next(item);
    this.selectedText.next(null);
    this.displayText = item?.name ?? '';
  }

  selectText(text: string | null) {
    if (text) this.show = false;
    this.selectedText.next(text);
    this.selectedItem.next(null);
    this.displayText = text ?? '';
  }

  onToggleChange(event: boolean) {
    this.show = event;
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrow(event: KeyboardEvent) {
    // STOP if closed panel.
    if (!this.show) return;
    event.preventDefault();
    if (this.focusIndex !== undefined) {
      const goUpFromFirstItem = this.focusIndex === 0 && event.key === 'ArrowUp';
      const goDownFromLastItem = this.focusIndex === (this.filteredItems?.length ?? 0) - 1 && event.key === 'ArrowDown';

      if (goUpFromFirstItem || goDownFromLastItem) {
        // focus text section
        this.focusIndex = -1;
      } else if (this.focusIndex === -1 && event.key === 'ArrowUp') { // going up from text section
        // focus last item
        this.focusIndex = (this.filteredItems?.length ?? 0) - 1;
      } else {
        // going up or down
        const direction = event.key === 'ArrowUp' ? -1 : 1;
        // focus an item in the filtered list
        this.focusIndex = mod(this.focusIndex + direction, (this.filteredItems?.length ?? 0));
      }
      // manage the scroll from item to item in the filtered list
      const scrollIndex = this.focusIndex !== 0 ? this.focusIndex + 1 : 0;
      this.itemsContainer?.nativeElement.children[scrollIndex]?.scrollIntoView({block: 'nearest'});
    } else {
      // focus index is not defined -> focus text section, or first item.
      this.focusIndex = event.key === 'ArrowUp' ? (this.filteredItems?.length ?? 0) - 1 : 0;
    }
  }

  @HostListener('document:keydown.Enter', ['$event'])
  @HostListener('document:keydown.Tab', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.show) {
      event.preventDefault();
      if (this.focusIndex === -1) {
        this.selectText(this.searchText);
      } else {
        this.selectItem(this.filteredItems?.at(this.focusIndex) ?? null);
      }
      this.forceToggle$.emit(false);
    }
  }
}
