import { Injectable } from '@angular/core';
import { ApiTenderSearchService } from './search/api-tender-search.service';
import { ApiTenderTerritoryService } from './territory/api-tender-territory.service';
import { ApiTenderTopicService } from './topic/api-tender-topic.service';
import { ApiTenderCountService } from './tender-count/api-tender-count.service';
import { ApiTenderDetailService } from "./tender-detail/api-tender-detail.service";
import { ApiSmartGridService } from "./smart-grid/api-smart-grid.service";
import { ApiMarketWatchService } from './watch/api-market-watch.service';
import { ApiAnnotationService } from "./status/api-annotation.service";

@Injectable({
  providedIn: 'root'
})
export class ApiTenderService {

  constructor(
    private _searchService: ApiTenderSearchService,
    private _topicService: ApiTenderTopicService,
    private _territoryService: ApiTenderTerritoryService,
    private _tenderCountService: ApiTenderCountService,
    private _tenderDetailService: ApiTenderDetailService,
    private _gridQuestionService: ApiSmartGridService,
    private _marketWatchService: ApiMarketWatchService,
    private _annotationService: ApiAnnotationService
  ) {
  }

  get search(): ApiTenderSearchService {
    return this._searchService;
  }

  get topic(): ApiTenderTopicService {
    return this._topicService;
  }

  get territory(): ApiTenderTerritoryService {
    return this._territoryService;
  }

  get tenderCount(): ApiTenderCountService {
    return this._tenderCountService;
  }

  get tenderDetail(): ApiTenderDetailService {
    return this._tenderDetailService;
  }

  get gridQuestion(): ApiSmartGridService {
    return this._gridQuestionService;
  }

  get marketWatch(): ApiMarketWatchService {
    return this._marketWatchService;
  }

  get annotation(): ApiAnnotationService {
    return this._annotationService;
  }
}
