export const frenchDepartment =
    [
        {
            'code': '2B',
            'search_key': 'Haute-Corse',
            'kind': 'FRDEPA',
            'region_name': 'Corse',
            'region_id': '05',
            'id': 1,
            'uid': 'FRDEPA2B'
        },
        {
            'code': '2A',
            'search_key': 'Corse-du-Sud',
            'kind': 'FRDEPA',
            'region_name': 'Corse',
            'region_id': '05',
            'id': 2,
            'uid': 'FRDEPA2A'
        },
        {
            'code': '01',
            'search_key': 'Ain',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 3,
            'uid': 'FRDEPA01'
        },
        {
            'code': '02',
            'search_key': 'Aisne',
            'kind': 'FRDEPA',
            'region_name': 'Hauts-de-France',
            'region_id': '09',
            'id': 4,
            'uid': 'FRDEPA02'
        },
        {
            'code': '03',
            'search_key': 'Allier',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 5,
            'uid': 'FRDEPA03'
        },
        {
            'code': '04',
            'search_key': 'Alpes-de-Haute-Provence',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 6,
            'uid': 'FRDEPA04'
        },
        {
            'code': '05',
            'search_key': 'Hautes-Alpes',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 7,
            'uid': 'FRDEPA05'
        },
        {
            'code': '06',
            'search_key': 'Alpes-Maritimes',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 8,
            'uid': 'FRDEPA06'
        },
        {
            'code': '07',
            'search_key': 'Ardèche',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 9,
            'uid': 'FRDEPA07'
        },
        {
            'code': '08',
            'search_key': 'Ardennes',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 10,
            'uid': 'FRDEPA08'
        },
        {
            'code': '09',
            'search_key': 'Ariège',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 11,
            'uid': 'FRDEPA09'
        },
        {
            'code': 10,
            'search_key': 'Aube',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 12,
            'uid': 'FRDEPA10'
        },
        {
            'code': 11,
            'search_key': 'Aude',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 13,
            'uid': 'FRDEPA11'
        },
        {
            'code': 12,
            'search_key': 'Aveyron',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 14,
            'uid': 'FRDEPA12'
        },
        {
            'code': 13,
            'search_key': 'Bouches-du-Rhône',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 15,
            'uid': 'FRDEPA13'
        },
        {
            'code': 14,
            'search_key': 'Calvados',
            'kind': 'FRDEPA',
            'region_name': 'Normandie',
            'region_id': '12',
            'id': 16,
            'uid': 'FRDEPA14'
        },
        {
            'code': 15,
            'search_key': 'Cantal',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 17,
            'uid': 'FRDEPA15'
        },
        {
            'code': 16,
            'search_key': 'Charente',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 18,
            'uid': 'FRDEPA16'
        },
        {
            'code': 17,
            'search_key': 'Charente-Maritime',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 19,
            'uid': 'FRDEPA17'
        },
        {
            'code': 18,
            'search_key': 'Cher',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 20,
            'uid': 'FRDEPA18'
        },
        {
            'code': 19,
            'search_key': 'Corrèze',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 21,
            'uid': 'FRDEPA19'
        },
        {
            'code': 21,
            'search_key': 'Côte-d\'Or',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 22,
            'uid': 'FRDEPA21'
        },
        {
            'code': 22,
            'search_key': 'Côtes-d\'Armor',
            'kind': 'FRDEPA',
            'region_name': 'Bretagne',
            'region_id': '03',
            'id': 23,
            'uid': 'FRDEPA22'
        },
        {
            'code': 23,
            'search_key': 'Creuse',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 24,
            'uid': 'FRDEPA23'
        },
        {
            'code': 24,
            'search_key': 'Dordogne',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 25,
            'uid': 'FRDEPA24'
        },
        {
            'code': 25,
            'search_key': 'Doubs',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 26,
            'uid': 'FRDEPA25'
        },
        {
            'code': 26,
            'search_key': 'Drôme',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 27,
            'uid': 'FRDEPA26'
        },
        {
            'code': 27,
            'search_key': 'Eure',
            'kind': 'FRDEPA',
            'region_name': 'Normandie',
            'region_id': '12',
            'id': 28,
            'uid': 'FRDEPA27'
        },
        {
            'code': 28,
            'search_key': 'Eure-et-Loir',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 29,
            'uid': 'FRDEPA28'
        },
        {
            'code': 29,
            'search_key': 'Finistère',
            'kind': 'FRDEPA',
            'region_name': 'Bretagne',
            'region_id': '03',
            'id': 30,
            'uid': 'FRDEPA29'
        },
        {
            'code': 30,
            'search_key': 'Gard',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 31,
            'uid': 'FRDEPA30'
        },
        {
            'code': 31,
            'search_key': 'Haute-Garonne',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 32,
            'uid': 'FRDEPA31'
        },
        {
            'code': 32,
            'search_key': 'Gers',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 33,
            'uid': 'FRDEPA32'
        },
        {
            'code': 33,
            'search_key': 'Gironde',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 34,
            'uid': 'FRDEPA33'
        },
        {
            'code': 34,
            'search_key': 'Hérault',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 35,
            'uid': 'FRDEPA34'
        },
        {
            'code': 35,
            'search_key': 'Ille-et-Vilaine',
            'kind': 'FRDEPA',
            'region_name': 'Bretagne',
            'region_id': '03',
            'id': 36,
            'uid': 'FRDEPA35'
        },
        {
            'code': 36,
            'search_key': 'Indre',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 37,
            'uid': 'FRDEPA36'
        },
        {
            'code': 37,
            'search_key': 'Indre-et-Loire',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 38,
            'uid': 'FRDEPA37'
        },
        {
            'code': 38,
            'search_key': 'Isère',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 39,
            'uid': 'FRDEPA38'
        },
        {
            'code': 39,
            'search_key': 'Jura',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 40,
            'uid': 'FRDEPA39'
        },
        {
            'code': 40,
            'search_key': 'Landes',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 41,
            'uid': 'FRDEPA40'
        },
        {
            'code': 41,
            'search_key': 'Loir-et-Cher',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 42,
            'uid': 'FRDEPA41'
        },
        {
            'code': 42,
            'search_key': 'Loire',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 43,
            'uid': 'FRDEPA42'
        },
        {
            'code': 43,
            'search_key': 'Haute-Loire',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 44,
            'uid': 'FRDEPA43'
        },
        {
            'code': 44,
            'search_key': 'Loire-Atlantique',
            'kind': 'FRDEPA',
            'region_name': 'Pays de la Loire',
            'region_id': '15',
            'id': 45,
            'uid': 'FRDEPA44'
        },
        {
            'code': 45,
            'search_key': 'Loiret',
            'kind': 'FRDEPA',
            'region_name': 'Centre-Val de Loire',
            'region_id': '04',
            'id': 46,
            'uid': 'FRDEPA45'
        },
        {
            'code': 46,
            'search_key': 'Lot',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 47,
            'uid': 'FRDEPA46'
        },
        {
            'code': 47,
            'search_key': 'Lot-et-Garonne',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 48,
            'uid': 'FRDEPA47'
        },
        {
            'code': 48,
            'search_key': 'Lozère',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 49,
            'uid': 'FRDEPA48'
        },
        {
            'code': 49,
            'search_key': 'Maine-et-Loire',
            'kind': 'FRDEPA',
            'region_name': 'Pays de la Loire',
            'region_id': '15',
            'id': 50,
            'uid': 'FRDEPA49'
        },
        {
            'code': 50,
            'search_key': 'Manche',
            'kind': 'FRDEPA',
            'region_name': 'Normandie',
            'region_id': '12',
            'id': 51,
            'uid': 'FRDEPA50'
        },
        {
            'code': 51,
            'search_key': 'Marne',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 52,
            'uid': 'FRDEPA51'
        },
        {
            'code': 52,
            'search_key': 'Haute-Marne',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 53,
            'uid': 'FRDEPA52'
        },
        {
            'code': 53,
            'search_key': 'Mayenne',
            'kind': 'FRDEPA',
            'region_name': 'Pays de la Loire',
            'region_id': '15',
            'id': 54,
            'uid': 'FRDEPA53'
        },
        {
            'code': 54,
            'search_key': 'Meurthe-et-Moselle',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 55,
            'uid': 'FRDEPA54'
        },
        {
            'code': 55,
            'search_key': 'Meuse',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 56,
            'uid': 'FRDEPA55'
        },
        {
            'code': 56,
            'search_key': 'Morbihan',
            'kind': 'FRDEPA',
            'region_name': 'Bretagne',
            'region_id': '03',
            'id': 57,
            'uid': 'FRDEPA56'
        },
        {
            'code': 57,
            'search_key': 'Moselle',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 58,
            'uid': 'FRDEPA57'
        },
        {
            'code': 58,
            'search_key': 'Nièvre',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 59,
            'uid': 'FRDEPA58'
        },
        {
            'code': 59,
            'search_key': 'Nord',
            'kind': 'FRDEPA',
            'region_name': 'Hauts-de-France',
            'region_id': '09',
            'id': 60,
            'uid': 'FRDEPA59'
        },
        {
            'code': 60,
            'search_key': 'Oise',
            'kind': 'FRDEPA',
            'region_name': 'Hauts-de-France',
            'region_id': '09',
            'id': 61,
            'uid': 'FRDEPA60'
        },
        {
            'code': 61,
            'search_key': 'Orne',
            'kind': 'FRDEPA',
            'region_name': 'Normandie',
            'region_id': '12',
            'id': 62,
            'uid': 'FRDEPA61'
        },
        {
            'code': 62,
            'search_key': 'Pas-de-Calais',
            'kind': 'FRDEPA',
            'region_name': 'Hauts-de-France',
            'region_id': '09',
            'id': 63,
            'uid': 'FRDEPA62'
        },
        {
            'code': 63,
            'search_key': 'Puy-de-Dôme',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 64,
            'uid': 'FRDEPA63'
        },
        {
            'code': 64,
            'search_key': 'Pyrénées-Atlantiques',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 65,
            'uid': 'FRDEPA64'
        },
        {
            'code': 65,
            'search_key': 'Hautes-Pyrénées',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 66,
            'uid': 'FRDEPA65'
        },
        {
            'code': 66,
            'search_key': 'Pyrénées-Orientales',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 67,
            'uid': 'FRDEPA66'
        },
        {
            'code': 67,
            'search_key': 'Bas-Rhin',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 68,
            'uid': 'FRDEPA67'
        },
        {
            'code': 68,
            'search_key': 'Haut-Rhin',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 69,
            'uid': 'FRDEPA68'
        },
        {
            'code': 69,
            'search_key': 'Rhône',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 70,
            'uid': 'FRDEPA69'
        },
        {
            'code': 70,
            'search_key': 'Haute-Saône',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 71,
            'uid': 'FRDEPA70'
        },
        {
            'code': 71,
            'search_key': 'Saône-et-Loire',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 72,
            'uid': 'FRDEPA71'
        },
        {
            'code': 72,
            'search_key': 'Sarthe',
            'kind': 'FRDEPA',
            'region_name': 'Pays de la Loire',
            'region_id': '15',
            'id': 73,
            'uid': 'FRDEPA72'
        },
        {
            'code': 73,
            'search_key': 'Savoie',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 74,
            'uid': 'FRDEPA73'
        },
        {
            'code': 74,
            'search_key': 'Haute-Savoie',
            'kind': 'FRDEPA',
            'region_name': 'Auvergne-Rhône-Alpes',
            'region_id': '01',
            'id': 75,
            'uid': 'FRDEPA74'
        },
        {
            'code': 75,
            'search_key': 'Paris',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 76,
            'uid': 'FRDEPA75'
        },
        {
            'code': 76,
            'search_key': 'Seine-Maritime',
            'kind': 'FRDEPA',
            'region_name': 'Normandie',
            'region_id': '12',
            'id': 77,
            'uid': 'FRDEPA76'
        },
        {
            'code': 77,
            'search_key': 'Seine-et-Marne',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 78,
            'uid': 'FRDEPA77'
        },
        {
            'code': 78,
            'search_key': 'Yvelines',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 79,
            'uid': 'FRDEPA78'
        },
        {
            'code': 79,
            'search_key': 'Deux-Sèvres',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 80,
            'uid': 'FRDEPA79'
        },
        {
            'code': 80,
            'search_key': 'Somme',
            'kind': 'FRDEPA',
            'region_name': 'Hauts-de-France',
            'region_id': '09',
            'id': 81,
            'uid': 'FRDEPA80'
        },
        {
            'code': 81,
            'search_key': 'Tarn',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 82,
            'uid': 'FRDEPA81'
        },
        {
            'code': 82,
            'search_key': 'Tarn-et-Garonne',
            'kind': 'FRDEPA',
            'region_name': 'Occitanie',
            'region_id': '14',
            'id': 83,
            'uid': 'FRDEPA82'
        },
        {
            'code': 83,
            'search_key': 'Var',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 84,
            'uid': 'FRDEPA83'
        },
        {
            'code': 84,
            'search_key': 'Vaucluse',
            'kind': 'FRDEPA',
            'region_name': 'Provence-Alpes-Côte d\'Azur',
            'region_id': '16',
            'id': 85,
            'uid': 'FRDEPA84'
        },
        {
            'code': 85,
            'search_key': 'Vendée',
            'kind': 'FRDEPA',
            'region_name': 'Pays de la Loire',
            'region_id': '15',
            'id': 86,
            'uid': 'FRDEPA85'
        },
        {
            'code': 86,
            'search_key': 'Vienne',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 87,
            'uid': 'FRDEPA86'
        },
        {
            'code': 87,
            'search_key': 'Haute-Vienne',
            'kind': 'FRDEPA',
            'region_name': 'Nouvelle-Aquitaine',
            'region_id': '13',
            'id': 88,
            'uid': 'FRDEPA87'
        },
        {
            'code': 88,
            'search_key': 'Vosges',
            'kind': 'FRDEPA',
            'region_name': 'Grand Est',
            'region_id': '06',
            'id': 89,
            'uid': 'FRDEPA88'
        },
        {
            'code': 89,
            'search_key': 'Yonne',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 90,
            'uid': 'FRDEPA89'
        },
        {
            'code': 90,
            'search_key': 'Territoire de Belfort',
            'kind': 'FRDEPA',
            'region_name': 'Bourgogne-Franche-Comté',
            'region_id': '02',
            'id': 91,
            'uid': 'FRDEPA90'
        },
        {
            'code': 91,
            'search_key': 'Essonne',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 92,
            'uid': 'FRDEPA91'
        },
        {
            'code': 92,
            'search_key': 'Hauts-de-Seine',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 93,
            'uid': 'FRDEPA92'
        },
        {
            'code': 93,
            'search_key': 'Seine-Saint-Denis',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 94,
            'uid': 'FRDEPA93'
        },
        {
            'code': 94,
            'search_key': 'Val-de-Marne',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 95,
            'uid': 'FRDEPA94'
        },
        {
            'code': 95,
            'search_key': 'Val-d\'Oise',
            'kind': 'FRDEPA',
            'region_name': 'Île-de-france',
            'region_id': '10',
            'id': 96,
            'uid': 'FRDEPA95'
        },
        {
            'code': 971,
            'search_key': 'Guadeloupe',
            'kind': 'FRDEPA',
            'region_name': 'Guadeloupe',
            'region_id': '07',
            'id': 97,
            'uid': 'FRDEPA971'
        },
        {
            'code': 972,
            'search_key': 'Martinique',
            'kind': 'FRDEPA',
            'region_name': 'Martinique',
            'region_id': '11',
            'id': 98,
            'uid': 'FRDEPA972'
        },
        {
            'code': 973,
            'search_key': 'Guyane',
            'kind': 'FRDEPA',
            'region_name': 'Guyane',
            'region_id': '08',
            'id': 99,
            'uid': 'FRDEPA973'
        },
        {
            'code': 974,
            'search_key': 'La Réunion',
            'kind': 'FRDEPA',
            'region_name': 'La Réunion',
            'region_id': '17',
            'id': 100,
            'uid': 'FRDEPA974'
        },
        {
            'code': 976,
            'search_key': 'Mayotte',
            'kind': 'FRDEPA',
            'region_name': 'Mayotte',
            'region_id': '18',
            'id': 101,
            'uid': 'FRDEPA976'
        }
    ];
