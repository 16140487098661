import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {HeadersService} from '../headers/headers.service';
import { lastValueFrom, Observable, timeout } from 'rxjs';
import {EventTypeName} from "../../../../models/user-tracker";
import {environment} from "../../../../../environments/environment";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ApiExportService {

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  generateUrl(body: object, options?: {timeout?: number, headers?: HttpHeaders}): Promise<string> {
    return lastValueFrom(this.http.post<{ s3_download_doc_url: string, errorMessage?: string }>(
      `${environment.explainApiGatewayUrl}export-document`,
      body,
      { headers: options?.headers }
    ).pipe(
      timeout(options?.timeout ?? 60000),
      map(res => {
        if (res.errorMessage) throw new Error(res.errorMessage);
        return res.s3_download_doc_url;
      })
    ));
  }

  downloadBlob(url: string, options?: {headers?: HttpHeaders}): Promise<Blob> {
    return lastValueFrom(this.http.get(url, { headers: options?.headers, responseType: 'blob' }))
  }

  /**
   * @deprecated The method should not be used
   */
  getDceDownloadUrl(projectId: string, dceProjectUid: string, inquirers: string[], givenTimeout: number): Observable<string> {
    return this.http.post<{ s3_download_doc_url: string, errorMessage?: string }>(
      `${environment.explainApiGatewayUrl}export-document`,
      {
        project_uid: projectId,
        dce_project_uid: dceProjectUid,
        inquirers: inquirers,
        entity_id: 'tender',
      },
      this.headerService.getTrackedHttpHeaders(EventTypeName.DCE_DOWNLOAD_TRIGGERED)
    )
      .pipe(
        timeout(givenTimeout),
        map(res => {
          if (res.errorMessage) { // j'ai eu un cas 200 avec un errorMessage dans la réponse...
            throw new Error(res.errorMessage);
          }
          return res.s3_download_doc_url
        })
      );
  }

  /**
   * @deprecated The method should not be used
   */
  getBlobFromUrl(url: string | undefined): Observable<Blob> {
    if (url === undefined) throw new Error();
    return this.http.get(
      url ?? '',
      {
        headers: this.headerService.getTrackedHttpHeaders(EventTypeName.DCE_DOWNLOADED).headers,
        responseType: 'blob'
      }
    )
  }
}
