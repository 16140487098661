/**
 * DbExplainStatistics: interface carrying counters of explain entities (admin_docs, articles, impacters...)
 */
export interface DbExplainStatistics {
  all_admin_docs_count: number;
  all_articles_count: number;
  all_impacters_count: number;
  all_sources_count: number;
  all_websites_count: number;
  impacters_count: number;
}

/**
 * ExplainStatistics: class carrying counters of explain entities (admin_docs, articles, impacters...)
 */
export class ExplainStatistics {
  private _allAdminDocsCount: number;
  private _allArticlesCount: number;
  private _allImpactersCount: number;
  private _allSourcesCount: number;
  private _allWebsitesCount: number;
  private _impactersCount: number;

  constructor(dbExplainStatistics: DbExplainStatistics) {
    this._allAdminDocsCount = dbExplainStatistics.all_admin_docs_count;
    this._allArticlesCount = dbExplainStatistics.all_articles_count;
    this._allImpactersCount = dbExplainStatistics.all_impacters_count;
    this._allSourcesCount = dbExplainStatistics.all_sources_count;
    this._allWebsitesCount = dbExplainStatistics.all_websites_count;
    this._impactersCount = dbExplainStatistics.impacters_count;
  }

  get allAdminDocsCount(): number {
    return this._allAdminDocsCount;
  }

  set allAdminDocsCount(value: number) {
    this._allAdminDocsCount = value;
  }

  get allArticlesCount(): number {
    return this._allArticlesCount;
  }

  set allArticlesCount(value: number) {
    this._allArticlesCount = value;
  }

  get allImpactersCount(): number {
    return this._allImpactersCount;
  }

  set allImpactersCount(value: number) {
    this._allImpactersCount = value;
  }

  get allSourcesCount(): number {
    return this._allSourcesCount;
  }

  set allSourcesCount(value: number) {
    this._allSourcesCount = value;
  }

  get allWebsitesCount(): number {
    return this._allWebsitesCount;
  }

  set allWebsitesCount(value: number) {
    this._allWebsitesCount = value;
  }

  get impactersCount(): number {
    return this._impactersCount;
  }

  set impactersCount(value: number) {
    this._impactersCount = value;
  }
}
