import { Component, Input } from '@angular/core';
import { Tender } from '../../models/tender';

@Component({
  selector: 'app-tender-link-copy-button',
  templateUrl: './tender-link-copy-button.component.html',
  styleUrls: ['./tender-link-copy-button.component.scss']
})
export class TenderLinkCopyButtonComponent {
  @Input({required: true}) tender!: Tender;

  tooltip: string = 'tenders.link-copy';

  async copy() {
    const url = `${window.location.origin}/tenders/search/${this.tender.id}`;
    const htmlBlob = new Blob([`<a href="${url}">${this.getSanitizedTitle()}</a>`], { type: "text/html" });
    const textBlob = new Blob([url], { type: 'text/plain' });
    const data = [new ClipboardItem({
      [htmlBlob.type]: htmlBlob,
      [textBlob.type]: textBlob,
    })];
    await navigator.clipboard.write(data);
    this.tooltip = 'tender.link-copied';
  }

  getSanitizedTitle() {
    return new DOMParser().parseFromString(this.tender.title, 'text/html').body.textContent
  }
}
