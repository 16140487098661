<div class="empty-state-container">
  <div [ngClass]="{'unset': paddingTop === 'unset', 'sm': paddingTop === 'small', 'md': paddingTop === 'medium', 'lg': paddingTop === 'large'}"
       class="empty-state-icon">
    <mat-icon svgIcon="{{svgIcon}}"
              [style.color]="iconColor"></mat-icon>
  </div>
  <h1>{{ title }}</h1>
  <div class="content-container">
    <ng-container
      *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate.template"/>
  </div>
</div>
