import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DbTopic, Topic, TopicType } from '../../models/topic';
import { SearchHelper } from '../../shared/helpers/search.helper';
import { ApiService } from '../../shared/services/api/api.service';
import { TerritoryWatchRelatedToTopic } from '../../models/territory-watch';
import { DbListItem } from '../../models/generic/list-item';
import { ExplainModuleEnum, ModuleManagerService } from "../../shared/services/module-manager.service";


@Component({
  selector: 'app-topic-list-template',
  templateUrl: './topic-list-template.component.html',
  styleUrls: ['./topic-list-template.component.scss']
})

export class TopicListTemplateComponent implements OnInit, OnChanges {
  @Input() topics: Array<Topic> = [];
  @Input() defaultTopics: Array<Topic> = [];
  @Input() type!: TopicType;
  @Output() deleteTopic = new EventEmitter<string>();
  @Output() renameTopic = new EventEmitter<DbTopic>();
  @Input() topicListLength = 0; // nombre total des topic custom or default qu'on a (sans filter)
  @Input() hasFilter = false ;
  TopicType = TopicType;
  forceUpdate = 0;
  public indexItemSelected!: number;
  public itemIdSelected!: string;
  currentOrder = {
    orderBy: 'name',
    reverse: false
  };
  TerritoryWatchRelatedToTopic: TerritoryWatchRelatedToTopic[] = [];
  formFromScratch = true;
  defaultTopicsFiltered: Array<Topic> = [];
  defaultTopicName: string | null = null;
  selectedTopic = null;
  show = false;
  stateDropDown = false;

  constructor(private modalService: NgbModal,
              private apiService: ApiService,
              private moduleManagerService: ModuleManagerService) {
  }

  ngOnInit(): void {
  }

  buildRouterLink(topicId: string) {
    const firstPart = this.moduleManagerService.currentModule === ExplainModuleEnum.TENDERS ? '/' + ExplainModuleEnum.TENDERS : ''
    return `${firstPart}/topic-form/${topicId}`;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.defaultTopicsFiltered = this.defaultTopics;
  }

  search(value: string) {
    this.show = true;
    this.stateDropDown = true;
    this.selectedTopic = null;
    this.defaultTopicsFiltered = this.defaultTopics.filter((v) => SearchHelper.searchRegExp(v.name, value));
  }

  hideOrOpen(event?: Event) {
    event?.stopPropagation();
    if (this.formFromScratch) {
      this.formFromScratch = false;
    }
    this.stateDropDown = !this.stateDropDown;
    this.show = this.stateDropDown;
  }

  hideIfOpen() {
    if (this.stateDropDown) {
      this.hideOrOpen();
    }
  }

  onItemClicked(topic: any) {
    if (this.formFromScratch) {
      return;
    }
    this.hideOrOpen();
    this.defaultTopicName = topic.name;
    this.selectedTopic = topic.id;
    this.defaultTopicsFiltered = this.defaultTopics;
  }

  changeOrder(value: string) {
    if (this.currentOrder.orderBy === value) {
      this.currentOrder.reverse = !this.currentOrder.reverse;
    } else {
      this.currentOrder = {
        orderBy: value,
        reverse: false
      };
      this.forceUpdate++;
    }
  }

  openDeleteModal(content: TemplateRef<any>, itemId: string, itemIndex: number) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true});
    this.indexItemSelected = itemIndex;
    this.itemIdSelected = itemId;
    this.apiService.territoryWatch.getTerritoryWatchRelatedToTopic(itemId)
      .subscribe(response => {
        this.TerritoryWatchRelatedToTopic = response.territoryWatches;
      });
  }

  openCreationModal(content: TemplateRef<any>) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true});
  }

  onRename(event: DbListItem) {
    this.topics[event.index].name = event.name;
    this.renameTopic.emit({id: event.id, name: event.name} as DbTopic);
  }

  onDelete(): void {
    this.deleteTopic.emit(this.itemIdSelected);
    this.topicListLength--;
    this.topics.splice(this.indexItemSelected, 1);
    this.modalService.dismissAll();
  }

}
