import { booleanAttribute, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ex-icon-and-text',
  templateUrl: './ex-icon-and-text.component.html',
  styleUrls: ['./ex-icon-and-text.component.scss']
})
export class ExIconAndTextComponent {
  // nom de l'icône telle qu'utilisée par l'icon.module.ts
  @Input() iconName!: string;
  @Input() text!: string;
  @Input() classIcon = 'icon-20';

  @Input({transform: booleanAttribute}) overflowEllipsis = false;
  @Input({transform: booleanAttribute}) flexWrap?: boolean;
  @Input() phosphorIcon?: string = '';

  @Input() externalLink?: string;

  // pour en faire un badge
  @Input({transform: booleanAttribute}) isBadge = false;
  @Input() badgeColor = 'var(--primary-500)';
  @Input() badgeHeight?: number;

  @Output() externalLinkClick = new EventEmitter<boolean>()

  onExternalLinkClick(link?: string) {
    if (link) this.externalLinkClick.emit(true);
  }
}
