<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="{$implicit: node}">
</ng-container>

<div class="tree-node-container">
  <div *ngFor="let child of node.children">
    <ex-tree-node
      class="child-node"
      [node]="child"
      [template]="template">
    </ex-tree-node>
  </div>
</div>
