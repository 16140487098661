import {
  DbDceDocument,
  DceDocument,
  TenderAvisInitial,
  TenderAvisPreinformation, TenderAvisRectificatif, TenderAvisResultatMarche,
  TenderAvisType
} from './dce-document';
import { TreeItem } from '../../models/generic/item';
import { DbTender, Tender } from './tender';

export interface TreeNode {
  name: string;
  children?: TreeNode[];
  value?: any;
  properties?: any;
}

/** Lorsque le back sera terminé, nous utiliserons de l'héritage pour lier les classes Tender et TenderDetail */
export interface DbTenderDetail extends DbTender {
  dce_documents: DbDceDocument[];
  dce_url?: string;
}

export class TenderDetail extends Tender {
  dceDocuments: DceDocument[];
  dceTree: TreeNode;
  condensedDceTree: TreeNode;
  dceUrl?: string;

  constructor(dbTenderDetail: DbTenderDetail) {
    super(dbTenderDetail);
    this.dceDocuments = dbTenderDetail.dce_documents.map((dbDceDocument: DbDceDocument) => new DceDocument(dbDceDocument)) || null;
    this.dceDocuments = this.putAvisUpFront(this.dceDocuments);
    this.dceTree = this.buildTree(this.dceDocuments);
    this.condensedDceTree = this.buildCondensedTree(this.dceDocuments);
    this.dceUrl = dbTenderDetail.dce_url;
  }

  putAvisUpFront(dceDocuments: DceDocument[]): DceDocument[] {
    // 1. On met les avis de type attribution en premier
    // 2. On met les avis de type rectification en second
    // 2. On met les avis de type initial en 3em
    // 2. On met les avis de type pré information en dernier
    let toReturn = this.sortConditionByPublicationTypeEnum(dceDocuments, TenderAvisPreinformation);
    toReturn = this.sortConditionByPublicationTypeEnum(toReturn, TenderAvisInitial);
    toReturn = this.sortConditionByPublicationTypeEnum(toReturn, TenderAvisRectificatif);
    toReturn = this.sortConditionByPublicationTypeEnum(toReturn, TenderAvisResultatMarche);
    return toReturn;
  }

  /** Méthode qui place les éléments dont le type de publication appartient à l'enum */
  sortConditionByPublicationTypeEnum(dceDocuments: DceDocument[], Enum: any): DceDocument[] {
    const condition = (dceDocument: DceDocument) => Object.values(Enum)
      .includes(dceDocument.publicationType as TenderAvisType);
    return dceDocuments.sort((a: DceDocument, b: DceDocument) => {
      if (condition(a)) return -1; // met a en premier s'il répond à la condition
      if (condition(b)) return 1;  // met b en premier s'il répond à la condition
      return 0;                    // sinon ne change rien
    });
  }

  buildTree(dceDocuments: DceDocument[]): TreeNode {
    const root: TreeNode = {name: 'root'};
    dceDocuments.forEach((dceDocument: DceDocument) => {
      this.addItemToNode(dceDocument, root);
    });
    return root;
  }

  buildCondensedTree(dceDocuments: DceDocument[]): TreeNode {
    let root: TreeNode = {name: 'root', value: {visible: true}, children: []};
    dceDocuments.forEach((dceDocument: DceDocument) => {
      if (dceDocument.isSmartGridDoc || Object.values(TenderAvisType).includes(dceDocument.publicationType as TenderAvisType)) {
        root = {...root, children: (root.children ?? []).concat({
            name: getDceDocumentDisplayedName(dceDocument) ?? 'Nom indisponible',
            value: {...dceDocument,
                    visible: true}
        })};
      }
    });
    root.children?.sort((a, b) => {
      if (Object.values(TenderAvisType).includes(b.value.publicationType as TenderAvisType)) {
        return 1;
      } else {
        return a.name.localeCompare(b.name);
      }
    });
    return root;
  }

  /** Methode fonctionnant avec treeItem (i.e. ayant un attribut name qui traduit l'arborescence via
   *  un nameSeparator qui vaut '/' par défaut) */
  addItemToNode(item: TreeItem, tree: TreeNode, nameSeparator: string = '/') {
    const segments = containsDateString(item.name) ? [item.name] : item.name.split(nameSeparator);
    const firstSegment = segments[0];

    // s'il n'y a qu'un seul segment, il s'agit d'un document
    if (segments.length === 1) {
      const node = {name: firstSegment, value: {...item, visible: true}};
      this.addNodeToParent(node, tree);
    } else { // sinon c'est un dossier
      const existingFolder = tree.children?.find((node: TreeNode) => node.name === firstSegment);
      const newName = segments.slice(1).join(nameSeparator);
      const newDocument = {...item, name: newName, visible: true, expanded: true};
      if (existingFolder) {
        // Ajout du document au dossier existant
        this.addItemToNode(newDocument, existingFolder);
      } else {
        // Création node du dossier
        const newNode = {name: firstSegment, value: {...item, visible: true, expanded: true}};
        this.addItemToNode(newDocument, newNode);
        this.addNodeToParent(newNode, tree);
      }
    }
  }

  addNodeToParent(node: TreeNode, parent: TreeNode) {
    if (!parent.children) {
      parent.children = [node];
    } else {
      parent.children.push(node);
    }
  }

  /** Donne le nombre de documents utilisés pour la grille d'analyse (les avis sont exclus du décompte). */
  get strictSmartDocumentNumber(): number {
    return this.condensedDceTree?.children?.filter(item =>
      !Object.values(TenderAvisType).includes(item.value.publicationType)
    ).length ?? 0;
  }

}

/** Vérifie si la string contient une date écrite comme (dd/mm/yyyy). */
function containsDateString(str: string): boolean {
  const regex = /\(\b\d{2}\/\d{2}\/\d{4}\b\)/;
  return regex.test(str);
}

/** Donne le nom du document à afficher dans le placeholder du sélecteur de documents. */
export function getDceDocumentDisplayedName(document: DceDocument) {
  return containsDateString(document.name) ? document.name : document.name.split('/').pop();
}

