import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18nService } from './custom-datepicker-i18n.service';
import { TranslateService } from '@ngx-translate/core';
import { Period } from '../../services/tenders-search.service';

export interface DbPeriod {
  from: NgbDate | null;
  to: NgbDate | null;
}

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
  styleUrls: ['./filter-date.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class FilterDateComponent implements AfterViewInit {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  maxDate: NgbDate;
  startDate: NgbDate;
  inputText = "";

  @Input() defaultPeriod?: DbPeriod;
  @Output() periodSelected = new EventEmitter<Period>();

  constructor(calendar: NgbCalendar, private translateService: TranslateService) {
    this.fromDate = calendar.getPrev(calendar.getToday(), 'd', 6);
    this.toDate = calendar.getToday();
    this.maxDate = calendar.getToday();
    this.startDate = calendar.getPrev(calendar.getToday(), 'm', 1);
    this.updatePeriodText()
  }

  ngAfterViewInit() {
    if (this.defaultPeriod) {
      this.fromDate = this.defaultPeriod.from;
      this.toDate = this.defaultPeriod.to;
      this.updatePeriodText()
    }
    this.updatePeriod();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
      this.updatePeriod();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.updatePeriodText()
  }

  updatePeriod() {
    this.periodSelected.emit(this.fromDate && this.toDate ? {
      from: `${this.fromDate?.year}-${this.fromDate?.month.toString().padStart(2, '0')}-${this.fromDate?.day.toString().padStart(2, '0')}`,
      to: `${this.toDate?.year}-${this.toDate?.month.toString().padStart(2, '0')}-${this.toDate?.day.toString().padStart(2, '0')}`
    } : undefined );
  }

  isHovered(date: NgbDate) {
    return (
        this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
        date.equals(this.fromDate) ||
        (this.toDate && date.equals(this.toDate)) ||
        this.isInside(date) ||
        this.isHovered(date)
    );
  }

  getReadableDate(date: NgbDate | null): string {
    return getFormattedNgbDate(date, 'day/month/year');
  }

  updatePeriodText() {
    this.inputText = this.getReadableDate(this.fromDate) + (this.toDate ? ' • ' + this.getReadableDate(this.toDate) : '')
  }

  exDropdownChangeMethodTriggered(event: any) {
    if (event === null) {
      this.fromDate = null;
      this.toDate = null;
      this.updatePeriod();
    }
  }

  dropdownToggled(state: boolean) {
    if (!state && !this.fromDate || !this.toDate) {
      this.inputText = this.translateService.instant('tenders.invalid-period')
      this.updatePeriod()
    }
  }
}

/** Format an NgbDate into the specified form : use worlds day, month and year in lower case to describe wanted format
 *  ex: day/month/year OR year-month-day */
export function getFormattedNgbDate(date: NgbDate | null, format: string): string {
  if (date === null) return '';
  return format.replace('day', date.day.toString().padStart(2, '0'))
      .replace('month', date.month.toString().padStart(2, '0'))
      .replace('year', `${date.year}`);
}
