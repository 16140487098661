import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockModel, BlockType } from '../../core/block/block.model';
import { ModalConfirmationComponent } from '../modal-confirmation/modal-confirmation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { ModalExplanationComponent } from '../modal-explanation/modal-explanation.component';
import { TopicService } from './topic.service';
import { ToastMessageStackService } from '../../shared/services/toast-message-stack.service';
import { firstValueFrom, forkJoin, of } from 'rxjs';
import { ApiService } from '../../shared/services/api/api.service';
import { JsonGettersService } from '../../shared/services/json-getters.service';
import { TerritoryWatchRelatedToTopic } from '../../models/territory-watch';
import { DbTopic, TopicType } from '../../models/topic';
import { ExplainAppAccess } from '../../models/explain-app-access';
import { ExplainModuleEnum, ModuleManagerService } from "../../shared/services/module-manager.service";

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['./topic-form.component.scss']
})
export class TopicFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('explicative', { static: false }) modalContentExplicative!: TemplateRef<any>;
  @ViewChild('saveDefaultTopicAsCustom', { static: false }) saveDefaultTopicAsCustom!: TemplateRef<any>;
  @ViewChild('saveTopicToast', { read: TemplateRef }) topicToastTpl!: TemplateRef<any>;
  @ViewChild('alertsModified', { read: TemplateRef }) alertsModifiedTpl!: TemplateRef<any>;
  BlockType = BlockType;

  shouldReplaceAlertTopic = true;

  public typeBlockToAdd: string | null = null;
  public blocks!: Array<BlockModel>;
  public excludeBlock: BlockModel | null = null;
  public name = 'Nouveau thème';
  public originalName = '';
  public description = '';
  public topicId!: string;
  private explicativeCounter = 1;
  private modalReference!: NgbModalRef;
  public alertsNotUpdated: string[] = [];
  isCopy = false;
  alerts: TerritoryWatchRelatedToTopic[] = [];

  topicComplexity = 0;
  topicComplexityMaxValue = 0;
  urlPrefix!: string;

  @Output() TopicSubmitted = new EventEmitter<DbTopic>();     // retourner un topic si un acces anonyme
  @Input() access = ExplainAppAccess.EXPLAIN_FRONT;               // 1 si acces authentifer, sinon 2
  @Input() csmTopicId: string | null = null;                                       // id de topic, est récupérer à partir d'un param si un acces est anonyme
  @Input() method: string | null = null;                                           // POST oubien 1 si un anonyme veut ecrire, sinon PUT oubien 2 pour modifier
  @Input() token: string | null = null;                                            // token de l'admin

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private jsonGettersService: JsonGettersService,
    private apiService: ApiService,
    private topicService: TopicService,
    public toastMessageStackService: ToastMessageStackService,
    public moduleManagerService: ModuleManagerService
) { }

  ngAfterViewInit() {
    // stop from opening the modal on page load - may be usefull later.
    // this.openAddBlockModal(this.modalContentExplicative, 'lg-modal');
    this.urlPrefix = (this.moduleManagerService.currentModule === ExplainModuleEnum.TENDERS) ? '/' + ExplainModuleEnum.TENDERS : '';
  }

  openModalSaveDefaultTopicAsCustom() {
    firstValueFrom(this.apiService.territoryWatch.getTerritoryWatchRelatedToTopic(this.topicId)).then(response => {
      this.alerts = response.territoryWatches;
    }).catch(error => {
      console.log('error', error);
    }).finally(() => {
      this.openAddBlockModal(this.saveDefaultTopicAsCustom, 'md-modal');
    });
  }

  public get includeBlocks() {
    return this.topicService.topicIncludeBlocks;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.access === ExplainAppAccess.EXPLAIN_CSM && this.token) {
      if (this.csmTopicId && this.csmTopicId !== '0' && this.method?.toLowerCase() === 'put') {
        this.getTopicDetailAsAnonymous(this.token, this.csmTopicId);
      }
    }
  }

  close() {
    this.router.navigateByUrl(`${this.urlPrefix}/topics`).then();
  }

  ngOnInit(): void {
    this.jsonGettersService.getComplexityLimiters()
      .pipe(first())
      .subscribe(data => {
        this.topicComplexityMaxValue = Number(data['topic_complexity_limitation']);
        this.topicService.topicComplexityMaxValue = this.topicComplexityMaxValue;
      });

    this.route.params.subscribe(params => {
      this.topicId = params['topicId'];
      if (this.access === ExplainAppAccess.EXPLAIN_FRONT && this.topicId) {
        this.apiService.topic.getTopic(this.topicId)
          .subscribe((topic) => {

            this.name = topic.name;
            this.originalName = this.name;
            this.description = topic.description;
            this.blocks = topic.designBody?.blocks || [];
            this.topicService.topicIncludeBlocks = [];
            this.isCopy = topic.type === TopicType.DEFAULT;
            const userId = localStorage.getItem('user_id');
            if (!this.isCopy && userId && +userId !== topic.ownerUserId) {
              this.modalReference.close();
              this.router.navigate([`${this.urlPrefix}/topics`]).then();
            }
            this.blocks.forEach(block => {
              if (block.type === BlockType.TERMS_TO_EXCLUDE) {
                this.excludeBlock = block;
              } else {
                this.topicService.topicIncludeBlocks.push(block);
              }
            });
            this.topicComplexity = this.topicService.evaluateTopicComplexity(this.topicService.topicIncludeBlocks);
          });
      }
    });
  }

  ngOnDestroy() {
    this.topicService.topicIncludeBlocks = [];
    this.topicService.topicComplexityMaxValue = 0;
    this.excludeBlock = null;
  }

  getTopicDetailAsAnonymous(token: string, topicId: string) {
    this.apiService.topic.getTopicAsAnonymous(token, topicId).subscribe((topic) => {
      this.name = topic.name;
      this.description = topic.description;
      this.blocks = topic.designBody?.blocks || [];
      this.topicService.topicIncludeBlocks = [];
      this.blocks.forEach(block => {
        if (block.type === BlockType.TERMS_TO_EXCLUDE) {
          this.excludeBlock = block;
        } else {
          this.topicService.topicIncludeBlocks.push(block);
        }
      });
      this.topicComplexity = this.topicService.evaluateTopicComplexity(this.topicService.topicIncludeBlocks);
    }, err => {
      console.log(err);
    });
  }

  openAddBlockModal(content: any, className = 'lg-modal'): void {
    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: className, centered: true });
  }

  incrementExplicativeCounter() {
    if (this.eCounter < 3) {
      this.eCounter++;
    } else {
      this.modalService.dismissAll();
    }
  }
  decrementExplicativeCounter() {
    if (this.eCounter > 1) {
      this.eCounter--;
    }
  }

  openModal(): void {
    const modal = this.modalService.open(ModalConfirmationComponent, { ariaLabelledBy: 'modal-basic-title', centered: true });
    modal.componentInstance.returnUrl = `${this.urlPrefix}/topics`;
    if (this.topicId) {
      modal.componentInstance.title = 'content.modal-edit-topic-cancel-title';
      modal.componentInstance.content = 'content.modal-edit-topic-cancel-content';
      modal.componentInstance.btnName = 'button.modal-edit-cancel-yes';
    } else {
      modal.componentInstance.title = 'content.modal-create-topic-cancel-title';
      modal.componentInstance.content = 'content.modal-create-topic-cancel-content';
      modal.componentInstance.btnName = 'button.modal-cancel-yes';
    }
  }

  openAlertModal(): void {
    const modal = this.modalService.open(ModalConfirmationComponent, { ariaLabelledBy: 'modal-basic-title', centered: true });
    modal.componentInstance.title = 'content.modal-alert-topic-title';
    modal.componentInstance.content = 'content.modal-alert-topic-content';
    modal.componentInstance.btnName = 'button.modal-alert-yes';
    modal.componentInstance.alertMode = true;
  }

  openComplexityAlertModal(): void {
    const modal = this.modalService.open(ModalExplanationComponent, { ariaLabelledBy: 'modal-basic-title', centered: true });
    modal.componentInstance.title = 'content.modal-alert-topic-complexity-title';
    modal.componentInstance.content = 'content.modal-alert-topic-complexity-content';
    modal.componentInstance.content2 = 'content.modal-alert-topic-complexity-content2';
    modal.componentInstance.mailto = 'content.modal-alert-topic-complexity-mailto';
    modal.componentInstance.btnName = 'button.modal-alert-topic-complexity-yes';
  }

  onAddNewBlock(): void {
    this.topicService.topicIncludeBlocks.push(<BlockModel> { type: this.typeBlockToAdd });
    this.typeBlockToAdd = null;
    this.modalService.dismissAll();
  }

  onIncludeTermUpdate(event: any): void {
    const { blockIndex, columns } = event;
    // Case the complexity limit is reached after adding the term.
    const newIncludeBlock = this.topicService.topicIncludeBlocks;
    newIncludeBlock[blockIndex].columns = columns;

    const newTopicComplexity = this.topicService.evaluateTopicComplexity(newIncludeBlock);
    if (newTopicComplexity > this.topicComplexityMaxValue) {
      // The term is not added
      this.openComplexityAlertModal();
    } else {
      // The term is added
      this.topicService.topicIncludeBlocks[blockIndex].columns = columns;
      // Topic complexity is upgraded
      this.topicComplexity = newTopicComplexity;
    }
  }

  onExcludeTermUpdate(event: any): void {
    const { columns } = event;
    this.excludeBlock = { columns: columns, type: BlockType.TERMS_TO_EXCLUDE };
  }

  onDeleteBlock(value: number): void {
    this.topicService.topicIncludeBlocks.splice(value, 1);
  }

  hideModal() {
    this.shouldReplaceAlertTopic = true;
    this.modalService.dismissAll();
  }

  /** Method to create a new topic (of type custom in gw_front or default in CSM) */
  createTopic(): void {
    if (!this.isValidBlock()) {
      this.openAlertModal();
      return;
    }
    if (this.excludeBlock?.columns?.length) {
      this.blocks = this.topicService.topicIncludeBlocks.concat(this.excludeBlock);
    } else {
      this.blocks = this.topicService.topicIncludeBlocks;
    }

    // Topic to create
    const newTopic: DbTopic = {
        name: this.name,
        modules: this.moduleManagerService.currentModule && [this.moduleManagerService.currentModule],
        id: Number.parseInt(this.topicId, 10),
        description: this.description,
        design_body: {
          blocks: this.blocks
        }
      };

    if (this.isCopy) {
      if (this.originalName.trim() === newTopic.name.trim()) {
        newTopic.name += ' (Copie)';
        this.name = newTopic.name;
      }

      this.apiService.topic.copyDefaultTopic(newTopic).subscribe((copiedTopic) => {
        // dans le cas où l'utilisateur choisit de mettre à jour les alertes qui contiennent le thème par défaut copié
        if (this.shouldReplaceAlertTopic) {
          const observables = [];
          this.alertsNotUpdated = [];
          for (let i = 0; i < this.alerts.length; i++) {
            const topicIds = [];
            for (let j = 0; j < this.alerts[i].topics.length; j++) {
              if (+this.alerts[i].topics[j].id === +this.topicId) {
                topicIds.push(copiedTopic.topic_id);
              } else {
                topicIds.push(this.alerts[i].topics[j].id);
              }
            }
            observables.push(
              this.apiService.territoryWatch.changeTerritoryWatchTopic(this.alerts[i].id, topicIds)
                .pipe(catchError(error => {
                    this.alertsNotUpdated.push(this.alerts[i].name);
                    return of(error);
                  }
                ))
            );
          }
          forkJoin(observables).subscribe(() => {
            const colorClass = this.alertsNotUpdated.length === 0 ? 'success-toast' : 'error-toast';
            this.toastMessageStackService.show(this.alertsModifiedTpl, {autohide: true, classname: colorClass + ' toast-shape'});
          });
        }
        this.modalService.dismissAll();
        this.toastMessageStackService.show(this.topicToastTpl, {autohide: true, classname: 'success-toast toast-shape'});
        this.router.navigate([`${this.urlPrefix}/topics`]).then();
        });
    } else {
      if (this.access === ExplainAppAccess.EXPLAIN_FRONT) {
        if (!!newTopic.id) {
          this.apiService.topic.updateTopic(newTopic).subscribe(_ =>
            this.showSuccessToastAndNavigate(),
            error => console.log('error', error));
        } else {
          delete newTopic.id;                           // No id communicated to Put inserTopic api method.
          this.apiService.topic.insertTopic(newTopic).subscribe(_ =>
              this.showSuccessToastAndNavigate(),
            error => console.log('error', error));
        }
      } else {
        if (this.access === ExplainAppAccess.EXPLAIN_CSM) {
          this.TopicSubmitted.emit(newTopic);
        }
      }
    }
  }

  showSuccessToastAndNavigate() {
    this.toastMessageStackService.show(this.topicToastTpl, {autohide: true, classname: 'success-toast toast-shape'});
    this.router.navigate([`${this.urlPrefix}/topics`]).then();
  }

  isValidForm(): boolean {
    return !!this.name && this.topicService.topicIncludeBlocks?.length > 0;
  }

  isValidBlock(): boolean {
    if (!this.topicService.topicIncludeBlocks?.length && !this.excludeBlock?.columns?.[0]?.length) {
      return false;
    }
    return !(this.notValidNbColumnBlockComplex() || this.notValidColumnEntry());
  }

  notValidNbColumnBlockComplex(): boolean {
    return this.topicService.topicIncludeBlocks
      .some(block =>
        [BlockType.NEARBY_TERMS, BlockType.EXPRESSIONS_COMBINATIONS].includes(block.type)
        && (!block.columns || block.columns?.length < 2)
      );
  }

  notValidColumnEntry(): boolean {
    return this.topicService.topicIncludeBlocks.some(block => (!block.columns || block.columns?.length < 1));
  }

  onBlockSelect(value: string): void {
    this.typeBlockToAdd = value;
  }

  get eCounter(): number {
    return this.explicativeCounter;
  }

  set eCounter(number: number) {
    this.explicativeCounter = number;
  }
}
