import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DbTerritoryWatchSettings, TerritoryWatchSettings } from '../../../../models/territory-watch-settings';
import {
  TerritoryWatchRelatedToTopicsPostResponseBody,
  TerritoryWatchWithUsers,
  DbTerritoryWatchRelatedToTopicsPostResponseBody, DbTerritoryWatchWithUsers,
  DisplayedTerritoryWatch
} from '../../../../models/territory-watch';
import { TerritoryWatchStatus } from '../../../../models/territory-watch/territory-watch';
import {
  TerritoryWatchDocumentPreview, TerritoryWatchDocumentPreviewPeriod,
  TerritoryWatchDocumentPreviewResponseBody,
  DbTerritoryWatchDocumentPreview
} from '../../../../models/territory-watch-document-preview';
import { DbEntityUpdatedPatchResponseBody, EntityUpdatedPatchResponseBody } from '../../../../models/generic/api-update';
import { TerritoryWatchOverviewParams } from '../../../../models/territory-watch/territory-watch-overview-params';

@Injectable({
  providedIn: 'root'
})
/**
 * Service taking care of all api calls concerning territory watch business object.
 */
export class ApiTerritoryWatchService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /* Common url to all territory watch relative endpoints */
  public apiTerritoryWatchesUrl = `${environment.explainApiUrl}territory_watches/`;

  /**
   * POST to create territory watch, or update it, from its settings.
   * @param body - TerritoryWatchSettings
   */
  createOrUpdateTerritoryWatch(body: Object): Observable<TerritoryWatchStatus> {
    return this.http
      .put(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((res: any) => {
          return new TerritoryWatchStatus(
            res['territory_watch_id'],
            res['territory_watch_name'],
            res['message']
          );
        }));
  }

  /**
   * PATCH to update territory watch from its settings.
   * @param body - TerritoryWatchSettings
   */
  updateTerritoryWatch(body: Object): Observable<any> {
    return this.http
      .patch(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.httpHeaders
      );
  }

  /**
   * DELETE to delete an territory watch.
   * Returns 204 - NO CONTENT
   */
  deleteTerritoryWatch(territoryWatchId: number): Observable<null| Object> {
    const userId = localStorage.getItem('user_id');

    return this.http
      .delete(
        this.apiTerritoryWatchesUrl + `${territoryWatchId}/user/${userId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map(response => response));
  }

  /**
   * GET to retrieve setting information of an territory watch.
   * @param territoryWatchId - the territory watch's id integer
   */
  getTerritoryWatchSetting(territoryWatchId: number): Observable<TerritoryWatchSettings> {
    return this.http
      .get(
        this.apiTerritoryWatchesUrl + `territory_watch_settings/${territoryWatchId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: any) => {
          return new TerritoryWatchSettings(response['territory_watch_settings'] as DbTerritoryWatchSettings);
        }));
  }

  /**
   * POST to retrieving all territory watches from the user.
   * @param fields - fields wanted in response
   * @param filters - filters to apply */
  getTerritoryWatches(fields: Array<string>, filters: object): Observable<TerritoryWatchWithUsers[]> {
    const body = {
      fields: fields,
      filters: filters,
      limit: 100
    };

    return this.http
      .post(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.noCachingHttpHeaders
      )
      .pipe(
        map((res: any) => {
          return res['data'].map((x: DbTerritoryWatchWithUsers) => new TerritoryWatchWithUsers(x));
        }));
  }

  /**
   * POST to retrieving all territory watches from the user.
   * @param fields - fields wanted in response
   * @param filters - filters to apply */
  getTerritoryWatchesAsDbTerritoryWatchWithUsers(fields: Array<string>, filters: object): Observable<DbTerritoryWatchWithUsers[]> {
    const body = {
      fields: fields,
      filters: filters,
      limit: 100
    };

    return this.http
      .post(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((res: any) => {
          return res['data'];
        }));
  }

  /**
   * Method to find the current user in every territory watch from its users array, and add it's informations on a new key 'user'.
   * The informations relative to the current user are needed to display the territory watch activation state
   * @param territoryWatches - DbTerritoryWatch[] user's territory watches. Each one may contain multiple users values.
   */
  addCurrentUserToTerritoryWatches(territoryWatches: TerritoryWatchWithUsers[]): DisplayedTerritoryWatch[] {
    const userId = localStorage.getItem('user_id');
    if (!userId) return [];

    const displayedTerritoryWatches = new Array<DisplayedTerritoryWatch>();

    territoryWatches.forEach(territoryWatch => {
      const territoryWatchUser = territoryWatch.users.find(elem => elem.id === +userId);
      if (territoryWatchUser) {
        displayedTerritoryWatches.push(
          new DisplayedTerritoryWatch(
            territoryWatch,
            territoryWatchUser
          )
        );
      }
    });

    return displayedTerritoryWatches;
  }

  /**
   * POST retrieving documents regarding a certain territory watch to display on the step overview
   */
  getTerritoryWatchDocumentPreviews(body: TerritoryWatchOverviewParams): Observable<TerritoryWatchDocumentPreviewResponseBody> {
    return this.http
      .post(
        this.apiTerritoryWatchesUrl + `territory_watch_preview`,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: any) => {
          return new TerritoryWatchDocumentPreviewResponseBody(
            response.data.map((territoryWatchDocumentPreviewRaw: DbTerritoryWatchDocumentPreview) => new TerritoryWatchDocumentPreview(territoryWatchDocumentPreviewRaw)),
            new TerritoryWatchDocumentPreviewPeriod(response.period),
            response.total_count
          );
        }));
  }

  /**
   * POST retrieving territory watches linked to a certain topic identified by its id.
   * @param topicId - Id of the concerned topic
   */
  getTerritoryWatchRelatedToTopic(topicId: string): Observable<TerritoryWatchRelatedToTopicsPostResponseBody> {
    const body = {
      fields: ['id', 'name', 'topics'],
      limit: 1000,
      filters: {
        topics_ids: [parseInt(topicId, 10)],
        user_ids: [parseInt(localStorage.getItem('user_id') || '', 10)]
      }
    };
    return this.http
      .post<DbTerritoryWatchRelatedToTopicsPostResponseBody>(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.httpHeaders)
      .pipe(
        map((response: DbTerritoryWatchRelatedToTopicsPostResponseBody) => {
            return new TerritoryWatchRelatedToTopicsPostResponseBody(response);
          }
        )
      );
  }

  /**
   * PATCH to replace on of some Topics from within an already set up territory watch.
   * This method is called when creating a custom Topic from a default Topic when the
   * user chooses to alter existing territory watches linked to the original default Topic.
   */
  changeTerritoryWatchTopic(territoryWatchId: string, topicIds: string[]): Observable<EntityUpdatedPatchResponseBody> {
    const body = {
      id: territoryWatchId,
      topics_ids: topicIds,
    };

    return this.http
      .patch<DbEntityUpdatedPatchResponseBody>(
        this.apiTerritoryWatchesUrl,
        body,
        this.headersService.httpHeaders)
      .pipe(map((response: DbEntityUpdatedPatchResponseBody) => {
        return new EntityUpdatedPatchResponseBody(response);
      }));
  }

}
