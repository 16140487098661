import { booleanAttribute, Component, Input } from '@angular/core';
import { TendersExportService } from '../../services/tenders-export.service';

@Component({
  selector: 'app-tenders-export-toggle',
  templateUrl: './tenders-export-toggle.component.html',
  styleUrls: ['./tenders-export-toggle.component.scss']
})
export class TendersExportToggleComponent {
  @Input({transform: booleanAttribute}) disabled: boolean = false;

  constructor(protected tendersExportService: TendersExportService) {
  }
}
