import { booleanAttribute, Component, Input } from '@angular/core';
import { ExplainModuleEnum } from "../../../shared/services/module-manager.service";
import { ActivatedRoute, Router } from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'ex-module-home',
  templateUrl: './ex-module-home.component.html',
  styleUrls: ['./ex-module-home.component.scss']
})
export class ExModuleHomeComponent {
  @Input({transform: booleanAttribute}) linkToOtherModule? = false;
  @Input({required: true}) currentModule = ExplainModuleEnum.TERRITORY_INTEL;
  @Input() alternativeSuggestedModule?: ExplainModuleEnum | null = null;
  phStyle = 'ph';
  moduleHomeUrls = {
    [ExplainModuleEnum.TERRITORY_INTEL]: 'search',
    [ExplainModuleEnum.TENDERS]: 'tenders/watch',
  }
  commonExplainModules = ['settings'];

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  navigateToOtherModule() {
    const url = (this.alternativeSuggestedModule === ExplainModuleEnum.TENDERS) ?
      `${environment.appBaseUrl}/tenders/watch` : `${environment.appBaseUrl}/search`;
    window.open(url, '_blank');
  }

  async navigateToHome(){
    const homeUrl = `/${this.route.firstChild?.snapshot.url}`;
    if (Object.values(this.commonExplainModules).includes(homeUrl.split('/').at(1) ?? '')) {
      await this.router.navigate([`/${this.moduleHomeUrls[this.currentModule]}`]);
    } else if (homeUrl) {
      await this.router.navigate([homeUrl]);
    }
  }
}
