import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeviewConfig, TreeviewItem } from '../../../lib/ngx-treeview';
import { TerritoryKind } from '../../../core/territory-kind/territory-kind.enum';
import { SearchHelper } from '../../../shared/helpers/search.helper';

@Component({
  selector: 'app-filter-territory',
  templateUrl: './filter-territory.component.html',
  styleUrls: ['./filter-territory.component.scss'],
})
export class FilterTerritoryComponent implements OnChanges {

  @Input({required: true}) items!: TreeviewItem; // éléments affichés
  @Input() alternativeDisplay = '';
  @Input() selectionCount?: number;
  @Input() maxCount: number = 0;
  @Input() defaultTerritories?: string[];
  @Output() territoriesSelected = new EventEmitter<string[]>();
  config = TreeviewConfig.create({
    hasFilter: true,
    maxHeight: 420
  });
  filterText = '';
  isCollapsed = false;


  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['items'] || changes['defaultTerritories']) && this.items) {
      this.items.setCheckedRecursive(true, (item) => {
        return this.defaultTerritories?.includes(item.value) ?? false;
      })
      const selectedValues = this.items.getSelection().checkedItems
        .filter((item) => !item.value.includes(TerritoryKind.REGION))
        .map((item) => item.value);
      this.selectionCount = selectedValues.length;
    }
  }

  /** Fonction appellée lors de la sélection de checkbox */
  onFunctionChange() {
    const selectedValues = this.items.getSelection().checkedItems
      .filter((item) => !item.value.includes(TerritoryKind.REGION))
      .map((item) => item.value);
    this.selectionCount = selectedValues.length;
    this.territoriesSelected.emit(selectedValues);
  }

  itemInSearch(text: string): boolean {
    return !!SearchHelper.searchRegExp(text, this.filterText);
  }

  hideItem(item: TreeviewItem): boolean {
    if (item.children && item.children.length > 0) {
      if (item.isRoot) {
        return false;
      } else {
        const childInSearch = item.children.some(x => this.itemInSearch(x.text));
        return !childInSearch && !this.itemInSearch(item.text);
      }
    } else {
      return !this.itemInSearch(item.text);
    }
  }

  resetPerimeter() {
    if(!this.selectionCount) return;
    this.items.setCheckedRecursive(false);
    this.onFunctionChange();
    this.filterText = '';
  }
}
