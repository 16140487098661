<div class="container-fluid nopadding">
    <div class="row nopadding d-flex justify-content-center">
        <div class="col-12 pb-3">
          <div class="titleblock1 mt-3">
            {{"explicative.block-3.title"|translate}}
          </div>
          <div class="descriptionblock3 mt-2">
            {{"explicative.block-3.description-1"|translate}}
          </div>
          <div class="descriptionblock1 mt-2">
            {{"explicative.block-3.description-2"|translate}}
          </div>
          <div class="mt-2">
            <div class="block3Style1">{{"explicative.block-3.exemple"|translate}}</div>
            <div class="d-flex align-items-center">
              <div class="block3Style2">{{"explicative.block-3.exemple-1.word-1"|translate}}</div>
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/arrow-right-solid.svg" width="15px" alt="arrow-right-solid">
              </div>
              <div class="d-flex align-items-center">
                <div *ngFor="let item of list1" class="w-100 d-flex align-items-center">
                  <div *ngIf="item['correct']"  class="block3Style3" [innerHTML]="item.text | translate"></div>
                  <div *ngIf="!item['correct']"  class="block3Style4" [innerHTML]="item.text | translate"></div>
                  <div class="virgule">,</div>
                </div>
                <div class="virgule">.....</div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="block3Style2">{{"explicative.block-3.exemple-2.word-1"|translate}}</div>
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/arrow-right-solid.svg" width="15px" alt="arrow-right-solid">
              </div>
              <div class="d-flex align-items-center">
                <div *ngFor="let item of list2" class="w-100 d-flex align-items-center">
                  <div *ngIf="item['correct']"  class="block3Style3" [innerHTML]="item.text | translate"></div>
                  <div *ngIf="!item['correct']"  class="block3Style4" [innerHTML]="item.text | translate"></div>
                  <div class="virgule">,</div>
                </div>
                <div class="virgule">.....</div>
              </div>
            </div>
          </div>
          <div class="descriptionblock3 mt-4">
            {{"explicative.block-3.description-3"|translate}}
          </div>
          <div class="descriptionblock1 mt-2">
            {{"explicative.block-3.description-4"|translate}}
          </div>

          <div class="row nopadding" style="min-height: 1px;">
            <div class="col-4 nopadding">
              <div class="w-100 mt-3 mb-3">
                <img src="assets/images/block3explication.png" width="100%" alt="block3explanation">
              </div>
            </div>
          </div>

          <div class="mt-2">
            <div class="block3Style1">{{"explicative.block-3.exemple"|translate}}</div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/icon-unlock.svg" width="15px" alt="icon-unlock">
              </div>
              <div style="width:15px;color:#c5cee0;">
                l
              </div>
              <div class="block3Style2">{{"explicative.block-3.exemple-3.word-1"|translate}}</div>
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/arrow-right-solid.svg" width="15px" alt="arrow-right-solid">
              </div>
              <div class="d-flex align-items-center">
                <div *ngFor="let item of list3" class="w-100 d-flex align-items-center">
                  <div *ngIf="item['correct']"  class="block3Style3" [innerHTML]="item.text | translate"></div>
                  <div *ngIf="!item['correct']"  class="block3Style4" [innerHTML]="item.text | translate"></div>
                  <div class="virgule">,</div>
                </div>
                <div class="virgule">.....</div>
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/icon-lock-fill.svg" width="15px" alt="icon-lock-fill">
              </div>
              <div style="width:15px;color:#c5cee0;">
                l
              </div>
              <div class="block3Style2">{{"explicative.block-3.exemple-3.word-1"|translate}}</div>
              <div class="d-flex align-items-center me-2 ms-2">
                <img src="assets/images/arrow-right-solid.svg" width="15px" alt="arrow-right-solid">
              </div>
              <div class="d-flex align-items-center">
                <div *ngFor="let item of list4" class="w-100 d-flex align-items-center">
                  <div *ngIf="item['correct']"  class="block3Style3" [innerHTML]="item.text | translate"></div>
                  <div *ngIf="!item['correct']"  class="block3Style4" [innerHTML]="item.text | translate"></div>
                  <div class="virgule">,</div>
                </div>
                <div class="virgule">.....</div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
