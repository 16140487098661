import { Component, Input } from '@angular/core';
import { ApiUserInteractionService, UserInteractionEvent } from '../../services/api/feedback/api-user-interaction.service';
import { Tender } from '../../models/tender';

@Component({
  selector: 'app-relevance-feedback',
  templateUrl: './relevance-feedback.component.html',
  styleUrls: ['./relevance-feedback.component.scss']
})
export class RelevanceFeedbackComponent {
  @Input({required: true}) tender!: Tender;
  @Input({required: true}) topics?: number[];
  state?: 'positive' | 'negative' | 'loading'

  constructor(
    private userInteractionService: ApiUserInteractionService,
  ) { }

  submitFeedback(value: boolean) {
    if (this.tender.isRelevant !== undefined || this.state === 'loading') return;
    this.state = 'loading';
    this.userInteractionService.createUserInteraction(
      this.tender.id,
      {
        event_category: UserInteractionEvent.FEEDBACK,
        event_metadata: {
          cluster_ids: this.tender.cluster_id,
          indexation_date: this.tender.indexationDate,
          topic_ids: this.topics,
        },
        is_event_positive: value,
      }
    )
      .then(() => {
        this.state = value ? 'positive' : 'negative';
      })
      .catch(() => {
        this.state = undefined;
      });
  }

  cancelFeedback() {
    if (this.tender.isRelevant !== undefined) return;
    const previousState = this.state;
    this.state = 'loading';
    this.userInteractionService.deleteUserInteraction(this.tender.id)
      .then(() => {
        this.state = undefined;
      })
      .catch(() => {
        this.state = previousState;
      });
  }
}
