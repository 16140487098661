import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const ICON_TYPES = ['outline', 'fill', 'duotone'] as const;

@Component({
  selector: 'ex-button',
  templateUrl: './ex-button.component.html',
  styleUrls: ['./ex-button.component.scss']
})
export class ExButtonComponent implements OnChanges {
  @Input() size: 'small' | 'medium' | 'x-large' | 'giant' = 'medium';
  @Input() type: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'ai' = 'primary';
  @Input() variant: 'raised' | 'ghost' | 'tonal' = 'raised';
  @Input() disabled = false;
  @Input() hovered = false;
  /**
   * @Input icon: The name of the icon to be displayed.
   * The icon name must be from the Phosphor icon library followed by the icon type.
   * For example, 'bell' or 'bell-fill'.
   */
  @Input() icon?: string;
  @Input() iconPosition: 'left' | 'right' = 'left';

  iconName?: string;
  iconType: 'ph' | 'ph-fill' | 'ph-duotone' = 'ph';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['icon']) {
      if (this.icon) {
        const iconSegments = this.icon.split('-');
        const iconType = iconSegments.pop();
        switch (iconType) {
          case 'fill':
            this.iconType = 'ph-fill';
            this.iconName = 'ph-' + iconSegments.join('-');
            break;
          case 'duotone':
            this.iconType = 'ph-duotone';
            this.iconName = 'ph-' + iconSegments.join('-');
            break;
          case 'outline':
            this.iconType = 'ph';
            this.iconName = 'ph-' + iconSegments.join('-');
            break;
          default:
            this.iconType = 'ph';
            this.iconName = 'ph-' + this.icon;
            break;
        }
      }
    }
  }
}
