import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class ItemsService {

  private _item: any;
  private _orderBy: any;
  private _limit!: number;
  private _pageIndex!: number;
  private _reverse!: boolean;
  private _currentPage!: number;
  private _subject!: Subject<any>;

  constructor() {
  }

  sentPageIndex(pageIndex: number) {
  }

  get item(): any {
    return this._item;
  }

  set item(value: any) {
    this._item = value;
  }

  get orderBy(): any {
    return this._orderBy;
  }

  set orderBy(value: any) {
    this._orderBy = value;
  }

  get limit(): number {
    return this._limit;
  }

  set limit(value: number) {
    this._limit = value;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  set pageIndex(value: number) {
    this._pageIndex = value;
  }

  get reverse(): boolean {
    return this._reverse;
  }

  set reverse(value: boolean) {
    this._reverse = value;
  }

  get currentPage(): number {
    return this._currentPage;
  }

  set currentPage(value: number) {
    this._currentPage = value;
  }

  get subject(): Subject<any> {
    return this._subject;
  }

  set subject(value: Subject<any>) {
    this._subject = value;
  }
}
