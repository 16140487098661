import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HeadersService } from '../headers/headers.service';
import { map } from 'rxjs/operators';
import { DbTopic, Topic } from '../../../../models/topic';
import { Item } from '../../../../models/generic/item';
import { ExplainModuleEnum } from "../../module-manager.service";

@Injectable({
  providedIn: 'root'
})
export class ApiTopicService {

  constructor(private http: HttpClient,
              private headersService: HeadersService) { }

  /**
   * GET method to retrieve info of a Topic with its id.
   */
  getTopic(topicId: string): Observable<Topic> {
    return this.http
      .get<DbTopic>(
        `${environment.explainApiUrl}topics?id=${topicId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((res: DbTopic) => {
          return new Topic(res);
        }));
  }

  /**
   * PUT method to create a topic
   * @param topic infos of the topic
   * @param token x-api-token. This method can be used from CSM or gw_front.
   */
  insertTopic(topic: DbTopic, token?: string): Observable<Item> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .post<any>(
        `${environment.explainApiGatewayUrl}topic-initializer`,
        this.getBodyWithXApiKey(topic, token),
        headers
      ).pipe(
        map((itemCreated: Item) => {
          return itemCreated;
        }
      ));
  }

  getBodyWithXApiKey(body: any, token?: string): any {
    return {
      x_api_key: token ?? window.localStorage.getItem('api_token'),
      topic: {...body}
    }
  }

  renameTopic(topic: DbTopic): Observable<{message: string}> {
    const headers = this.headersService.httpHeaders;
    return this.http
      .patch<any>(
        `${environment.explainApiUrl}topics`,
        topic,
        headers
      );
  }


  /**
   * PATCH method to update a topic
   * @param topic infos of the topic
   * @param token x-api-token. This method can be used from CSM or gw_front.
   */
  updateTopic(topic: DbTopic, token?: string): Observable<Item> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .post<any>(
        `${environment.explainApiGatewayUrl}topic-initializer`,
        this.getBodyWithXApiKey(topic, token),
        headers
      ).pipe(
        map((itemUpdated: Item) => {
          return itemUpdated;
        }));
  }

  /**
   * DELETE method to delete a topic from its Id.
   * @param topicId : id of the topic to delete.
   */
  deleteTopic(topicId: string): Observable<null> {
    return this.http
      .delete<null>(
        `${environment.explainApiUrl}topics?id=${topicId}`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: null) => response));
  }

  /***
   * POST method retrieving all user's topics.
   * Topics retrieved have light information (no design_body)
   */
  getUserTopics(module?: ExplainModuleEnum | undefined): Observable<Topic[]> {
    const userId = localStorage.getItem('user_id');
    const body = {
      'fields': [
        'id',
        'name',
        'type',
        'description',
        'updated_at',
        'created_at'
      ],
      'filters': {
        'owner_user_id': userId,
        'modules': module && [module]
      },
      'limit': 1000,
      'offset': 0
    };
    return this.http
      .post<DbTopic>(
        `${environment.explainApiUrl}topics`,
        body,
        this.headersService.httpHeaders)
      .pipe(
        map((data: any) => {
          return data['data'].map((dbTopic: DbTopic) => {
            return new Topic(dbTopic);
          });
        }));
  }

  /**
   * PUT method to copy a default_topic as a custom_topic
   */
  copyDefaultTopic(topic: DbTopic): Observable<{message: string, topic_id: string}> {
    delete topic.id;
    const headers = this.headersService.httpHeaders;
    return this.http
      .post<any>(
        `${environment.explainApiGatewayUrl}topic-initializer`,
        this.getBodyWithXApiKey(topic),
        headers
      ).pipe(
        map((copiedTopic) => {
          return copiedTopic;
        }));
  }

  /**
   * GET method to retrieve topic information when not logged in.
   * This method is useful for the Explain CSM application.
   */
  getTopicAsAnonymous(token: string, topicId: string): Observable<Topic> {
    const headers = this.headersService.getHeadersForAdminRequest(token);
    return this.http
      .get<DbTopic>(
        `${environment.explainApiUrl}topics?id=` + topicId,
        headers
      )
      .pipe(
        map((dbTopic: DbTopic) => {
          return new Topic(dbTopic);
        }));
  }

}
