import { Item } from './generic/item';
import { Topic } from './topic';
import { Pagination } from './generic/pagination';

/**
 * Structure of a territory watch as retrieved from api.
 * snake_case attributes.
 */
export interface DbTerritoryWatchUser {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  territory_watch_is_active: boolean;
  created_at: string;
  updated_at: string;
}

/**
 * Class implementing DbTerritoryWatchUser interface.
 * camelCase attributes. Constructor using interface.
 */
export class TerritoryWatchUser {
  private _id: number;
  private _email: string;
  private _firstName: string;
  private _lastName: string;
  private _territoryWatchIsActive: boolean;
  private _createdAt: string;
  private _updatedAt: string;

  constructor(dbTerritoryWatchUser: DbTerritoryWatchUser) {
    this._id = dbTerritoryWatchUser?.id;
    this._email = dbTerritoryWatchUser?.email;
    this._firstName = dbTerritoryWatchUser?.first_name;
    this._lastName = dbTerritoryWatchUser?.last_name;
    this._territoryWatchIsActive = dbTerritoryWatchUser?.territory_watch_is_active;
    this._createdAt = dbTerritoryWatchUser?.created_at;
    this._updatedAt = dbTerritoryWatchUser?.updated_at;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get territoryWatchIsActive(): boolean {
    return this._territoryWatchIsActive;
  }

  set territoryWatchIsActive(value: boolean) {
    this._territoryWatchIsActive = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }
}

/**
 * Structure of a territory watch and it's linked users.
 * snake_case attributes.
 */
export interface DbTerritoryWatchWithUsers {
  id: number;
  name: string;
  is_obsolete: boolean;
  created_at: string;
  updated_at: string;
  users: TerritoryWatchUser[];
}

/**
 * Class implementing DbTerritoryWatchWithUsers interface.
 * camelCase attributes. Constructor using interface.
 */
export class TerritoryWatchWithUsers extends Item {
  private _isObsolete: boolean;
  private _createdAt: string;
  private _updatedAt: string;
  private _users: TerritoryWatchUser[];

  constructor(dbTerritoryWatchWithUsers: DbTerritoryWatchWithUsers) {
    super(dbTerritoryWatchWithUsers.id, dbTerritoryWatchWithUsers.name);
    this._isObsolete = dbTerritoryWatchWithUsers.is_obsolete;
    this._createdAt = dbTerritoryWatchWithUsers.created_at;
    this._updatedAt = dbTerritoryWatchWithUsers.updated_at;
    this._users = dbTerritoryWatchWithUsers.users;
  }

  get isObsolete(): boolean {
    return this._isObsolete;
  }

  set isObsolete(value: boolean) {
    this._isObsolete = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get users(): TerritoryWatchUser[] {
    return this._users;
  }

  set users(value: TerritoryWatchUser[]) {
    this._users = value;
  }
}

/**
 * Manipulation interface to create a territory watch that can be displayed with its activation state.
 * camelCase attributes.
 */
export interface DbDisplayedTerritoryWatch extends TerritoryWatchWithUsers {
  user: DbTerritoryWatchUser;
}

/**
 * Displayed territory watch: territory watch so it can be displayed with it's activation state.
 * camelCase attributes. Constructor using classes.
 */
export class DisplayedTerritoryWatch extends Item {
  private _isObsolete: boolean;
  private _createdAt: string;
  private _updatedAt: string;
  private _users: TerritoryWatchUser[];
  private _user: TerritoryWatchUser;

  constructor(territoryWatchWithUsers: TerritoryWatchWithUsers, user: TerritoryWatchUser) {
    super(territoryWatchWithUsers.id, territoryWatchWithUsers.name);
    this._isObsolete = territoryWatchWithUsers.isObsolete;
    this._createdAt = territoryWatchWithUsers.createdAt;
    this._updatedAt = territoryWatchWithUsers.updatedAt;
    this._users = territoryWatchWithUsers.users;
    this._user = user;
  }

  get isObsolete(): boolean {
    return this._isObsolete;
  }

  set isObsolete(value: boolean) {
    this._isObsolete = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get users(): TerritoryWatchUser[] {
    return this._users;
  }

  set users(value: TerritoryWatchUser[]) {
    this._users = value;
  }

  get user(): TerritoryWatchUser {
    return this._user;
  }

  set user(value: TerritoryWatchUser) {
    this._user = value;
  }
}


/**
 * Structure of the a territory watch extending Item and its corresponding topics.
 * snake_case attributes.
 */
export interface DbTerritoryWatchRelatedToTopic extends Item {
  topics: Topic[];
}

/**
 * Class implementing DbTerritoryWatchRelatedToTopic interface.
 * camelCase attributes. Constructor using attributes.
 */
export class TerritoryWatchRelatedToTopic extends Item implements DbTerritoryWatchRelatedToTopic {
  private _topics: Topic[];

  constructor(id: any, name: string, topics: Topic[]) {
    super(id, name);
    this._topics = topics;
  }

  get topics(): Topic[] {
    return this._topics;
  }

  set topics(value: Topic[]) {
    this._topics = value;
  }
}

/**
 * Structure of the response returned by method retrieving territory watches linked to a certain Topic.
 * snake_case attributes.
 */
export interface DbTerritoryWatchRelatedToTopicsPostResponseBody {
  data: TerritoryWatchRelatedToTopic[];
  pagination: Pagination;
}

/**
 * Class implementing DbTerritoryWatchRelatedToTopicsPostResponseBody interface.
 * camelCase attributes. Constructor using interface.
 */
export class TerritoryWatchRelatedToTopicsPostResponseBody {
  private _territoryWatches: TerritoryWatchRelatedToTopic[];
  private _pagination: Pagination;

  constructor(interfaceObject: DbTerritoryWatchRelatedToTopicsPostResponseBody) {
    this._territoryWatches = interfaceObject.data;
    this._pagination = interfaceObject.pagination;
  }

  get territoryWatches(): TerritoryWatchRelatedToTopic[] {
    return this._territoryWatches;
  }

  set territoryWatches(value: TerritoryWatchRelatedToTopic[]) {
    this._territoryWatches = value;
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  set pagination(value: Pagination) {
    this._pagination = value;
  }
}
