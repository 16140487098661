import {NgxSkeletonLoaderConfig} from "ngx-skeleton-loader";

export const SKELETON_LINE_PARAMS: Partial<NgxSkeletonLoaderConfig> = {
  count: 2,
  appearance: 'line',
  theme: {
    // Enabling theme combination
    extendsFromRoot: true,
    // ... list of CSS theme attributes
    cursor: 'default',
    height: '13px',
    width: '100%',
    background: 'var(--transparent-basic-8)',
    margin: 0,
  },
}
