import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-new-watch',
  templateUrl: './modal-delete-watch.component.html',
  styleUrls: ['./modal-delete-watch.component.scss']
})
export class ModalDeleteWatchComponent {
  @Input() watchName!: string;
  @Input() watchUsersLength!: number;

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  confirm() {
    this.activeModal.close();
  }
}
