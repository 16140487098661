import { Component, Input, OnInit } from '@angular/core';
import { Folder } from '../../models/folder';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookmarkEntityType } from '../../models/bookmark-folder';
import { first } from 'rxjs/operators';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-modal-delete-folder',
  templateUrl: './modal-delete-folder.component.html',
  styleUrls: ['./modal-delete-folder.component.scss']
})
export class ModalDeleteFolderComponent implements OnInit {
  @Input() translationFileSection!: string;
  @Input() folder!: Folder;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService
  ) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close(false);
  }

  async onDelete() {
    try {
      await this.apiService.bookmark.deleteBookmarkEntity(this.folder.id, BookmarkEntityType.FOLDER).pipe(first()).toPromise();
      this.activeModal.close(true);
    } catch (e) {
      console.log('@modal-delete-folder error onDelete:', e);
    }
  }

}
