<ex-dropdown noInput size="small">
  <ng-template exDropdownDisplay>
    <div class="assignment-filter-header">
      <div *ngIf="usersFilter.length" class="header-content">
        <ex-user-badge [user]="usersFilter[0]" size="small" />
        <div class="text-truncate">{{ usersFilter[0].name }}</div>
        <div *ngIf="usersFilter.length > 1" class="count">+{{ usersFilter.length - 1 }}</div>
      </div>
      <div *ngIf="!usersFilter.length" class="header-placeholder">
        {{'tenders.assignment.assigned-to-all' | translate}}
      </div>
    </div>
  </ng-template>
  <ng-template exDropdownContent>
    <app-assignment-list multiple [(selection)]="usersFilter"/>
  </ng-template>
</ex-dropdown>
