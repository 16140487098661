import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TendersExportService } from '../../services/tenders-export.service';
import { TemplatePortal } from '@angular/cdk/portal';
import { TendersSearchService } from '../../services/tenders-search.service';
import { TenderEntityService } from '../../services/tender-entity.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tenders-export-bar',
  templateUrl: './tenders-export-bar.component.html',
  styleUrls: ['./tenders-export-bar.component.scss']
})
export class TendersExportBarComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input({required: true}) tendersSearchService!: TendersSearchService;

  @ViewChild('exportBar') exportBar!: TemplateRef<any>;
  overlayRef!: OverlayRef;
  serviceListener!: Subscription;

  constructor(private overlay: Overlay,
              private viewContainerRef: ViewContainerRef,
              protected tendersExportService: TendersExportService,
              protected tenderEntityService: TenderEntityService
  ) { }

  ngAfterViewInit() {
    const positionStrategy = this.overlay.position().global().centerHorizontally().bottom("64px");
    const overlayConfig = new OverlayConfig({
      positionStrategy
    })
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.attach(new TemplatePortal(this.exportBar, this.viewContainerRef));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tendersSearchService']) {
      if (this.serviceListener) this.serviceListener.unsubscribe();
      this.serviceListener = this.tendersSearchService._filters$.subscribe((filters) => {
        if (!filters?.offset && this.tendersExportService.selection) this.tendersExportService.selection = [];
      })
    }
  }

  ngOnDestroy() {
    this.serviceListener.unsubscribe();
  }
}
