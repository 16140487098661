import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { Folder } from '../../models/folder';
import { first } from 'rxjs/operators';
import { ModalDeleteFolderComponent } from '../modal-delete-folder/modal-delete-folder.component';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api/api.service';

@Component({
  selector: 'app-modal-edit-folder',
  templateUrl: './modal-edit-folder.component.html',
  styleUrls: ['./modal-edit-folder.component.scss']
})
export class ModalEditFolderComponent implements OnInit {
  @Input() translationFileSection!: string;
  @Input() folder!: Folder;

  editItemNameForm: FormControl;
  displayAlreadyExists: boolean;
  displayNameNotAllowed: boolean;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private apiService: ApiService,
    private router: Router
  ) {
    this.displayAlreadyExists = false;
    this.displayNameNotAllowed = false;
    this.editItemNameForm = new FormControl('',
      [Validators.required,
        Validators.minLength(1),
        Validators.maxLength(40)]
    );
  }

  ngOnInit(): void {
    this.editItemNameForm.setValue(this.folder?.name);
    this.editItemNameForm.valueChanges.subscribe(_ => {
      if (this.displayAlreadyExists || this.displayNameNotAllowed) {
        this.displayAlreadyExists = false;
        this.displayNameNotAllowed = false;
      }
    });
  }

  async modifyItem() {
    try {
      await this.apiService.bookmark.renameBookmarkFolder(this.folder.id, this.f.value.trim()).pipe(first()).toPromise();
      this.activeModal.close(this.f.value.trim());
    } catch (e) {
      if (e === 'Entity name not allowed!') {
        this.displayNameNotAllowed = true;
      }
      if (e === 'Entity already exist!') {
        this.displayAlreadyExists = true;
      } else {
        console.log('@modal-creation.component.ts error editing item', e);
      }
    }
  }


  openDeleteModal() {
    const modalRef = this.modalService.open(ModalDeleteFolderComponent,
      {ariaLabelledBy: 'modal-delete-folder', centered: true, windowClass: 'modal-width-560'});
    modalRef.componentInstance.translationFileSection = 'folder';
    modalRef.componentInstance.folder = this.folder;
    modalRef.result.then((deleted: boolean) => {
      if (deleted) {
        this.dismissModal();
        this.router.navigateByUrl('folder').then();
      }
    });
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.editItemNameForm;
  }
}
