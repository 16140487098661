<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    {{ title | translate}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()">

  </button>
</div>

<div class="modal-body d-flex flex-column">
  <div class="message">
    {{content | translate}}
  </div>
  <div class="message">
    <span>{{content2 | translate}}</span> <a href="mailto:{{mailto | translate}}">{{mailto | translate}}</a>.
  </div>
</div>

<div class="modal-footer">
  <ng-container>
    <button type="button" class="btn confirm-delete d-flex" (click)="close()">
      <div class="text-uppercase fw-bold align-self-center">
        {{btnName | translate}}
      </div>
    </button>
  </ng-container>
</div>
