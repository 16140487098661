import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() nameValue = '';
  @Input() minValue = 0;
  @Input() currentValue = 0;
  @Input() maxValue = 0;
  @Input() progressBarWidth = 132;
  @Input() progressBarClass = '';

  constructor() { }

  ngOnInit(): void {
  }

  get ProgressionPartWidth(): string {
    if (this.currentValue >= this.maxValue) {
      return '100%';
    }
    const computedWidth = 100 * (this.currentValue) / (this.maxValue - this.minValue);
    if (computedWidth > 10) {
      return computedWidth + '%';
    } else if (this.currentValue > this.minValue) {
      return 10 + '%';
    } else {
      return '0';
    }
  }

}
