import { Component, Input } from '@angular/core';

@Component({
  selector: 'ex-user-badge',
  templateUrl: './ex-user-badge.component.html',
  styleUrls: ['./ex-user-badge.component.scss']
})
export class ExUserBadgeComponent {
  @Input({required: true}) user!: { name: string, color: string, initials: string };
  @Input() size: 'small' | 'medium' = 'medium'
}
