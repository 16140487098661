import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../../../../shared/services/api/headers/headers.service';
import { Observable, retry } from 'rxjs';
import { DbGridQuestion, GridQuestion, GridQuestionTypeEnum } from '../../../models/grid-question';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiSmartGridService {

  constructor(private http: HttpClient,
              private headerService: HeadersService) {
  }

  getTenderGridQuestions(dceProjectUid: string, type: GridQuestionTypeEnum): Observable<GridQuestion[]> {
    return this.http.post<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        project_uid: dceProjectUid,
        user_id: localStorage.getItem('user_id'),
        user_goodwill_token: localStorage.getItem('api_token'),
        task: 'workload_triggering',
        type: type,
        is_active: true
      },
      this.headerService.httpHeaders)
      .pipe(map(res => {
          if (!res) {
            return [];
          }
          if (res['response_body']) {
            res = res['response_body'];
          }
          return (res as DbGridQuestion[]).map(question => new GridQuestion(question));
        }),
        retry(3));
  }


  getTenderGridAnswers(dceProjectUid: string, type: GridQuestionTypeEnum): Observable<GridQuestion[]> {
    return this.http.post<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        project_uid: dceProjectUid,
        user_id: localStorage.getItem('user_id'),
        user_goodwill_token: localStorage.getItem('api_token'),
        task: 'workload_answering',
        type: type
      },
      this.headerService.httpHeaders)
      .pipe(map(res => {
        if (res['response_body']) {
          res = res['response_body'];
        }
        return (res as DbGridQuestion[]).map(question => new GridQuestion(question));
      }));
  }

  addGridQuestion(question: string, displayedName: string): Observable<any> {
    return this.http.put<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        user_id: localStorage.getItem('user_id'),
        user_token: localStorage.getItem('api_token'),
        question: {
          name: question,
          displayed_name: displayedName,
          type: GridQuestionTypeEnum.PRIVATE
        }
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }

  editGridQuestion(questionId: number, question: string, displayedName: string): Observable<any> {
      return this.http.put<any>(
          `${environment.explainApiGatewayUrl}reading-grid`,
          {
              user_id: localStorage.getItem('user_id'),
              user_token: localStorage.getItem('api_token'),
              question: {
                  id: questionId,
                  name: question,
                  displayed_name: displayedName
                }
              },
          {
              headers: this.headerService.httpHeaders.headers
            }
          );
  }

  deleteGridQuestion(questionId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.explainApiGatewayUrl}reading-grid`,
      {
        user_id: localStorage.getItem('user_id'),
        user_token: localStorage.getItem('api_token'),
        question: {
          id: questionId,
          is_active: false
        }
      },
      {
        headers: this.headerService.httpHeaders.headers
      }
    );
  }
}
