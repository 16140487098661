<div class="group-container"
     [style.padding.px]="isBadge ? 8 : 0"
     [style.background-color]="isBadge ? badgeColor : 'unset'"
     [style.border-radius.px]="isBadge ? 4 : 8"
     [style.height.px]="badgeHeight ? badgeHeight : 'unset'"
>
  <mat-icon *ngIf="!phosphorIcon" svgIcon="{{iconName}}"
            class="{{classIcon}} d-flex align-items-center justify-content-center">
  </mat-icon>
  <i *ngIf="phosphorIcon" class="ph-fill ph-{{phosphorIcon}}"></i>

  <ng-template [ngIf]="text">
    <div *ngIf="!externalLink; else externalLinkTemplate"
         class="text-container"
         [ngClass]="{'text-truncate': overflowEllipsis}"
         ngbTooltip="{{text}}"
         placement="bottom auto"
         overflowTooltip
    >{{ text }}
    </div>
  </ng-template>
</div>

<ng-template #externalLinkTemplate id="externalLinkTemplate">
  <a [href]="externalLink" target="_blank" rel="noreferrer" (click)="onExternalLinkClick(externalLink)"
     class="text-container d-flex align-items-center font-12px fw-bold">
    {{text}}
    <mat-icon svgIcon="open-new-tab"
              class="mgl-4px icon-12 d-flex">
    </mat-icon>
  </a>
</ng-template>
