import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isSameDay, ngbDateToDate, stringToNgbDate } from '../../../shared/helpers/date-helper';
import { NgbDate, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18nService } from '../filter-date/custom-datepicker-i18n.service';
import { isEqual } from 'lodash';


let ngbAllowedDates: NgbDate[] = [];

@Component({
  selector: 'app-day-swiper',
  templateUrl: './day-swiper.component.html',
  styleUrls: ['./day-swiper.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: CustomDatepickerI18nService}]
})
export class DaySwiperComponent implements OnChanges {
  @Input() defaultDate?: string;
  @Input() lowerBoundaryDate?: string;
  @Input() marketWatchDates!: string[];
  @Input() editMode?: boolean = false;
  @Input() disabled?: boolean = false;

  date: Date = new Date();
  minDate: Date = new Date();

  allowedDates: Date[] = [];
  maxNgbDate: NgbDate = new NgbDate(2024, 1, 1);
  minNgbDate: NgbDate = new NgbDate(2024, 1, 1);

  forceToggle$ = new EventEmitter<boolean>();

  @Output() daySelect = new EventEmitter<Date>();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultDate'] && this.defaultDate) {
      this.date = new Date(this.defaultDate);
    }
    if (changes['lowerBoundaryDate'] && this.lowerBoundaryDate) {
      this.minDate = new Date(this.lowerBoundaryDate);
    }
    if ((changes['marketWatchDates'] || changes['editMode']) && !this.editMode) {
      if (this.marketWatchDates.length > 0) {
        this.allowedDates = this.marketWatchDates.map((date) => new Date(date));
        this.minDate = this.allowedDates[0];

        ngbAllowedDates = this.marketWatchDates.map(date => stringToNgbDate(date));
        this.minNgbDate = ngbAllowedDates[0];
        this.maxNgbDate = ngbAllowedDates[ngbAllowedDates.length - 1];
      } else {
        this.allowedDates = []
        ngbAllowedDates = []
      }
    }
  }

  isDayFocused(date: NgbDate) {
    return isSameDay(ngbDateToDate(date), this.date)
  }

  isDayDisabled(date: NgbDateStruct, current?: { year: number; month: number; } | undefined) {
    const isDateAllowed = ngbAllowedDates.find(d => isEqual(d, date));
    return !isDateAllowed
  }

  isFirstWatchDay(date: Date) {
    // If we are in editMode, there is no 'past' bound for the date : all past days are enabled.
    // Else, the date is disabled if it equals minDate or if allowedDates is empty.
    return !this.editMode && (isSameDay(date, this.minDate) || this.allowedDates.length === 0);
  }

  isLastWatchDay() {
    if (!this.editMode) {
      const currentDate = this.allowedDates.find(d => isSameDay(d, this.date)) ?? new Date();
      // The date is disabled if it's the last element of allowedDates or if allowedDates is empty.
      return this.allowedDates.indexOf(currentDate) === this.allowedDates.length - 1 || this.allowedDates.length === 0;
    } else {
      // If we are in editMode, the 'future' bound is the current day.
      return isSameDay(this.date, new Date());
    }
  }

  updatePeriod() {
    this.daySelect.emit(this.date);
  }

  nextDay() {
    if (this.editMode) {
      this.date.setDate(this.date.getDate() + 1);
    } else {
      const date = this.allowedDates.filter((d) => isSameDay(d, this.date))[0]
      this.date = this.allowedDates[this.allowedDates.indexOf(date) + 1]
    }
    this.updatePeriod();
  }

  prevDay() {
    if (this.editMode) {
      this.date.setDate(this.date.getDate() - 1);
    } else {
      const date = this.allowedDates.filter((d) => isSameDay(d, this.date))[0]
      this.date = this.allowedDates[this.allowedDates.indexOf(date) - 1]
    }
    this.updatePeriod();
  }

  getWeekDayString() {
    return this.date.toLocaleDateString('fr-FR', {weekday: 'short'});
  }

  getDayString() {
    return this.date.toLocaleDateString('fr-FR', {day: 'numeric'});
  }

  getMonthString() {
    return this.date.toLocaleDateString('fr-FR', {month: 'short'});
  }

  onDateSelection(ngbDate: NgbDate) {
    const date = ngbDateToDate(ngbDate);
    if (!isSameDay(date, this.date)) {
      this.date = date;
      this.updatePeriod();
    }
    this.forceToggle$?.emit(false);
  }
}
