import { SubKind, TerritoryKind } from '../core/territory-kind/territory-kind.enum';
import { TreeviewConfig, TreeviewItem } from '../lib/ngx-treeview';
import { AdminDocSourceEnum, ProjectDocumentSourceEnum } from './admin-document';

interface TerritoryWatchTerritoryDb {
  territory: string;
  total_nb_locations: number;
  values: Array<string>;
}

export class TerritoryWatchTerritory {
  private _territory: string;
  private _totalNbLocations: number;
  private _values: Array<string>;

  constructor(territoryDb: TerritoryWatchTerritoryDb) {
    this._territory = territoryDb.territory;
    this._totalNbLocations = territoryDb.total_nb_locations;
    this._values = territoryDb.values;
  }

  toStringForPostNewTerritoryWatch() {
    return {
      territory: this.territory,
      total_nb_locations: this.totalNbLocations,
      values: this.values
    };
  }

  get territory(): string {
    return this._territory;
  }

  set territory(value: string) {
    this._territory = value;
  }

  get totalNbLocations(): number {
    return this._totalNbLocations;
  }

  set totalNbLocations(value: number) {
    this._totalNbLocations = value;
  }

  get values(): Array<string> {
    return this._values;
  }

  set values(value: Array<string>) {
    this._values = value;
  }
}

export interface TerritoryWatchDayOfWeek {
  value: string;
  selected: boolean;
}

export enum ArticleSourceEnum {
  PRESS = 'PRESS'
}

// Combinaison des enum existantes
export enum TerritoryWatchIssuerKindEnum {
  PRESS  = ArticleSourceEnum.PRESS,
  FRCOMM = AdminDocSourceEnum.FRCOMM,
  FREPCI = AdminDocSourceEnum.FREPCI,
  SYNDICAT = AdminDocSourceEnum.SYNDICAT,
  EPTP = AdminDocSourceEnum.EPTP,
  MRAE = ProjectDocumentSourceEnum.MRAE,
  PREFECTURE = ProjectDocumentSourceEnum.PREFECTURE
}

export enum TerritoryWatchProjectPublicationTypeEnum {
  AVIS_MRAE = 'avis_mrae',
  AVIS_ENQUETE_PUBLIQUE = 'avis_enquete_publique',
  ENQUETE_PUBLIQUE = 'enquete_publique',
  ARRETE = 'arrete_prefectoral',
  NON_DETECTED = 'non_detected'
}

export interface TerritoryWatchPublicationTerritoryKind {
  value: TerritoryKind | SubKind;
  checked: boolean;
  show: boolean;
}

export class TerritoryWatchCheckedIssuerKind {
  value!: TerritoryKind | SubKind | TerritoryWatchIssuerKindEnum;
  checked!: boolean;

}

/**
 * C'est le DTO : il doit respecter le format de la réponse de l'API.
 */
export interface DbTerritoryWatchSettings {
  id: string;
  name: string;
  project_publication_type: Array<TerritoryWatchProjectPublicationTypeEnum>;
  issuer_kind: Array<TerritoryWatchIssuerKindEnum>;
  territories: Array<TerritoryWatchTerritoryDb>;
  syndicates: Array<string>;
  topics_ids: Array<number>;
  day_of_week: string;
  week_freq?: number;
  week_offset?: number;
}

export const creationDbTerritoryWatchSettings: DbTerritoryWatchSettings = {
  id: '1',
  name: '',
  project_publication_type: [],
  issuer_kind: [],
  territories: [],
  syndicates: [],
  topics_ids: [],
  day_of_week: '',
  week_freq: 1,
  week_offset: 0
};


const days = [
  {label: 'L', value: 'mon'},
  {label: 'M', value: 'tue'},
  {label: 'M', value: 'wed'},
  {label: 'J', value: 'thu'},
  {label: 'V', value: 'fri'},
  {label: 'S', value: 'sat'},
  {label: 'D', value: 'sun'},
];

export interface DbIssuerGroup {
  publicationTypes: Array<TerritoryWatchProjectPublicationTypeEnum | TerritoryWatchIssuerKindEnum>;
  name: string;
  title: string;
  subTitle: string;
  containsTreeview: boolean;
  isAccordion: boolean;
  opened?: boolean;
  treeviewItems: TreeviewItem[];
  config: TreeviewConfig;
  selectedItems: any[];
  checked: boolean;
  itemsStore: any[];
  issuerTemplateClassName: string;
}

export class IssuerGroup {
  publicationTypes: Array<TerritoryWatchProjectPublicationTypeEnum | TerritoryWatchIssuerKindEnum>;
  name: string;
  title: string;
  subTitle: string;
  containsTreeview: boolean;
  isAccordion: boolean;
  opened: boolean;
  treeviewItems: TreeviewItem[];
  config: TreeviewConfig;
  selectedItems: any[];
  checked: boolean;
  itemsStore: any[];
  issuerTemplateClassName: string;

  constructor() {
    this.publicationTypes = [];
    this.name = '';
    this.title = '';
    this.subTitle = '';
    this.containsTreeview = false;
    this.isAccordion = false;
    this.treeviewItems = [];
    this.config = TreeviewConfig.create({});
    this.selectedItems = [];
    this.checked = false;
    this.itemsStore = [];
    this.issuerTemplateClassName = '';
    this.opened = false;
  }
}
export interface DbSourceStepSection {
  sectionNumber: number;
  sectionTitle: string;
  issuerGroups?: Map<string, DbIssuerGroup>;
}

/**
 * C'est la classe, telle que nous voulons l'utiliser en front.
 */
export class TerritoryWatchSettings {
  private _id: number;
  private _selectedDayOfWeek: Array<TerritoryWatchDayOfWeek> = days.map(day => {
    return {
      value: day.value,
      selected: false
    };
  });
  private _dayOfWeek: string;
  private _name: string;
  private _issuerKind: Array<TerritoryWatchIssuerKindEnum>;
  private _projectPublicationType: Array<TerritoryWatchProjectPublicationTypeEnum>;
  private _territories: Array<TerritoryWatchTerritory>;
  private _syndicates: Array<string>;
  private _topicsIds: Array<number>;
  private _sourceStepSections = new Map<string, DbSourceStepSection>();

  constructor(TerritoryWatchSettingsDb: DbTerritoryWatchSettings) {
    this._id = Number(TerritoryWatchSettingsDb.id) || 1;
    this._name = TerritoryWatchSettingsDb.name || '';
    this._topicsIds = TerritoryWatchSettingsDb.topics_ids || [];
    this._territories = TerritoryWatchSettingsDb.territories.map(territoryDb => {
      return new TerritoryWatchTerritory(territoryDb);
    }) || [];
    this._syndicates = TerritoryWatchSettingsDb.syndicates || [];
    for (const day of this._selectedDayOfWeek) {
      if (TerritoryWatchSettingsDb.day_of_week?.includes(day.value)) {
        day.selected = true;
      }
    }
    this._dayOfWeek = TerritoryWatchSettingsDb.day_of_week || '';
    this._issuerKind = TerritoryWatchSettingsDb.issuer_kind || [];
    this._projectPublicationType = TerritoryWatchSettingsDb.project_publication_type || [];
    this._sourceStepSections = new Map<string, DbSourceStepSection>();
    this._sourceStepSections.set('articles', this.getEmptySourceStepSection());
  }

  //region getters and setters
  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get selectedDayOfWeek(): Array<TerritoryWatchDayOfWeek> {
    return this._selectedDayOfWeek;
  }

  set selectedDayOfWeek(value: Array<TerritoryWatchDayOfWeek>) {
    this._selectedDayOfWeek = value;
  }

  get dayOfWeek(): string {
    return this._dayOfWeek;
  }

  set dayOfWeek(value: string) {
    this._dayOfWeek = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get territories(): Array<TerritoryWatchTerritory> {
    return this._territories;
  }

  set territories(value: Array<TerritoryWatchTerritory>) {
    this._territories = value;
  }

  get syndicates(): Array<string> {
    return this._syndicates;
  }

  get topicsIds(): Array<number> {
    return this._topicsIds;
  }

  set topicsIds(value: Array<number>) {
    this._topicsIds = value;
  }

  get issuerKind(): Array<TerritoryWatchIssuerKindEnum> {
    return this._issuerKind;
  }

  set issuerKind(value: Array<TerritoryWatchIssuerKindEnum>) {
    this._issuerKind = value;
  }

  get projectPublicationType(): Array<TerritoryWatchProjectPublicationTypeEnum> {
    return this._projectPublicationType;
  }

  set projectPublicationType(value: Array<TerritoryWatchProjectPublicationTypeEnum>) {
    this._projectPublicationType = value;
  }

  get sourceStepSections(): Map<string, DbSourceStepSection> {
    return this._sourceStepSections;
  }

  set sourceStepSections(value: Map<string, DbSourceStepSection>) {
    this._sourceStepSections = value;
  }

  //endregion

  toStringForPostNewTerritoryWatch() {
    return {
        day_of_week: this.selectedDayOfWeek.map((x: TerritoryWatchDayOfWeek) => {
        if (x.selected) {
          return x.value;
        }
        return null
      }).filter(elm => elm).join(','),
      name: this.name,
      issuer_kind: this.issuerKind,
      project_publication_type: this.projectPublicationType,
      territories: this.territories.map((x) => x.toStringForPostNewTerritoryWatch()),
      syndicates: this.syndicates,
      topics_ids: this.topicsIds,
    };
  }

  public getEmptySourceStepSection() {
    const issuerGroups = new Map<string, DbIssuerGroup>();
    issuerGroups.set('', new IssuerGroup());
    return {
      sectionNumber: 0,
      sectionTitle: '',
      issuerGroups: issuerGroups
    };
  }
}
