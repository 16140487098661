import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { DbListItem } from '../../models/generic/list-item';

@Component({
  selector: 'app-modal-rename-item',
  templateUrl: './modal-rename-item.component.html',
  styleUrls: ['./modal-rename-item.component.scss']
})
export class ModalRenameItemComponent implements OnInit {

  item!: DbListItem;
  itemNamesList!: Array<string>;

  editItemNameForm: FormControl;
  displayAlreadyExists = false;
  displayNameNotAllowed = false;
  nameHasChanged = false;
  nameIsEmpty = false;
  maxLength!: number;

  @Output() itemRenamed = new EventEmitter<DbListItem>();

  constructor(
    private activeModal: NgbActiveModal
  ) {
    this.editItemNameForm = new FormControl('',
      [Validators.required,
        Validators.minLength(1)]
    );
  }

  ngOnInit(): void {
    if (this.maxLength) {
      this.editItemNameForm.addValidators(Validators.maxLength(this.maxLength));
   }
    this.editItemNameForm.setValue(this.item?.name);
    this.editItemNameForm.valueChanges.subscribe(name => {
      this.nameHasChanged = name.trim() !== this.item.name;
      this.displayAlreadyExists = this.itemNamesList.includes(name.trim().toLowerCase());
      this.displayNameNotAllowed = this.item.item_type === 'folder' && name.includes('/');
      this.nameIsEmpty = this.editItemNameForm.value.trim().length === 0;
    });
  }

  renameItem() {
    if (this.nameHasChanged && !this.displayAlreadyExists && !this.displayNameNotAllowed
                            && !this.nameIsEmpty && !this.editItemNameForm.errors) {
      this.item.name = this.editItemNameForm.value.trim();
      this.itemRenamed.emit(this.item);
      this.dismissModal();
    }
    if (!this.nameHasChanged) {
      this.dismissModal();
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  close() {
    this.activeModal.close();
  }
}
