<div class="new-item-modal">
    <div class="modal-header">
        <h4 class="modal-title">
          {{updateMode && (translationFileSection + '.modal-edit-item-title' | translate) }}
          {{deleteMode && (translationFileSection + '.modal-delete-item-title' | translate) }}
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-sub-title">
          {{ updateMode && (translationFileSection + '.modal-edit-item-sub-title' | translate) }}
          {{ deleteMode && (translationFileSection + '.modal-delete-item-sub-title' | translate) }}
        </div>
        <div class="modal-checkbox">
            <label class="container d-flex">
                <span class="checkbox-label">
                  {{updateMode && (translationFileSection + '.modal-edit-item-checkbox' | translate) }}
                  {{deleteMode && (translationFileSection + '.modal-delete-item-checkbox' | translate) }}
                </span>
                <input type="checkbox" class="form-check-input"
                       [checked]="checked"
                       (change)="onCheckedChange()"
                >
                <span class="checkmark"></span>

            </label>

        </div>
    </div>

    <div class="modal-footer justify-content-end">

        <button class="ex-btn-optional-transparent ex-btn"
                (click)="dismissModal()"
        >
            {{translationFileSection + '.modal-cancel' | translate}}
        </button>
      <button *ngIf="updateMode" [disabled]="!checked" type="button" class="ex-btn-strong ex-btn"
              (click)="confirm()"
      >
        <span class="text-uppercase fw-bold align-self-center">{{translationFileSection + '.modal-edit-save' | translate}}</span>
      </button>
      <button *ngIf="deleteMode" [disabled]="!checked" type="button" class="ex-btn-danger ex-btn"
              (click)="confirm()"
      >
        <span class="text-uppercase fw-bold align-self-center">{{translationFileSection + '.modal-delete' | translate}}</span>
      </button>
    </div>
</div>
