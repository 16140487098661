<div class="d-flex flex-column h-100">

  <ex-module-home class="module-home-button"
                  [currentModule]="currentModule">
  </ex-module-home>

  <div class="d-flex flex-column">
    <ng-container *ngFor="let item of displayedMenuItems">
      <ex-menu-item
        class="menu-item"
        [label]="item.label"
        [iconName]="item.phIconName"
        [module]="''+item.module"
        [menuItemRouterLink]="item.routerLink"
      ></ex-menu-item>
    </ng-container>
  </div>

  <div class="d-flex flex-grow-1"></div>

  <div class="d-flex flex-column">
    <ng-container *ngFor="let item of accountMenuItems">
      <ex-menu-item
        class="account-menu-item"
        [label]="item.label"
        [iconName]="item.phIconName"
        [module]="''+item.module"
        [menuItemRouterLink]="item.routerLink"
        (click)="onClickAccountMenuItem(item)"
      ></ex-menu-item>
    </ng-container>
  </div>

  <ex-module-home *ngIf="alternativeSuggestedModule" linkToOtherModule
                  [currentModule]="currentModule"
                  [alternativeSuggestedModule]="alternativeSuggestedModule">
  </ex-module-home>
</div>
