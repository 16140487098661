import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortText'
})
export class SortTextPipe  implements PipeTransform {
  transform(array: any[], field: string, reverse: boolean = false, forceUpdate = 0): any[] {
    if (!array) {
      return [];
    }
    array.sort((a: any, b: any) => {

      if (reverse) {
        return -1 * a[field].localeCompare(b[field]);
      } else {
        return a[field].localeCompare(b[field]);
      }
    });
    return array;
  }
}
