import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';
import { TendersComponent } from './components/tenders/tenders.component';
import { TendersSearchComponent } from "./components/tenders-search/tenders-search.component";
import { TendersWatchComponent } from './components/tenders-watch/tenders-watch.component';
import {TenderDetailComponent} from "./components/tender-detail/tender-detail.component";
import { TopicsPageComponent } from "../components/topics-page/topics-page.component";
import { TopicFormComponent } from "../components/topic-form/topic-form.component";
import { TendersBoardComponent } from './components/tenders-board/tenders-board.component';
import { AuthGuard } from "../auth.guard";

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class TendersRoutingModule { }

export const tenderRoutes: Routes = [
  {
    path: '', component: TendersComponent,
    data: {module: 'tenders'},
    children: [
      {
        path: '',
        redirectTo: 'watch',
        pathMatch: 'full'
      },
      {
        path: 'search',
        component: TendersSearchComponent,
        children: [
          {
            path: ':tenderId',
            component: TenderDetailComponent,
          },
          {
            path: ':tenderId/share/:shareId',
            component: TenderDetailComponent,
          }
        ]
      },
      {
        path: 'watch',
        component: TendersWatchComponent,
        children: [
          {
            path: ':tenderId',
            component: TenderDetailComponent,
          }
        ]
      },
      {
        path: 'board',
        component: TendersBoardComponent,
        children: [
          {
            path: ':tenderId',
            component: TenderDetailComponent,
          }
        ]
      },
      {
        path: 'topics',
        component: TopicsPageComponent,
        data: {
          breadcrumb: 'breadcrumb.topic',
          data: {module: 'tenders'},
        }
      },
      {
        path: 'topic-form',
        canActivate: [AuthGuard],
        component: TopicFormComponent,
        data: {
          displayNavBar: false,
          module: 'tenders'
        }
      },
      {
        path: 'topic-form/:topicId',
        canActivate: [AuthGuard],
        component: TopicFormComponent,
        data: {
          displayNavBar: false,
          module: 'tenders'
        }
      }
    ]
  },
  { path: '**', redirectTo: 'tenders/search', pathMatch: 'full'}
];
