export const environment = {
  /** GENERAL */
  ROLLBAR_ENV: 'staging',
  production: true,
  appBaseUrl: 'https://staging-app.explain.fr',

  /** ENDPOINTS */
  explainApiUrl: 'https://beta.api.staging-goodwill.explain.fr/api/v1/',
  explainApiGatewayUrl: 'https://o1h36tl8il.execute-api.eu-west-3.amazonaws.com/api-explain/', // api-dev-explain-gateway

  /** BUCKETS */
  s3DigestResultsBaseUrl: `https://s3-dev-digest-results.s3.eu-west-3.amazonaws.com`,

  /** NPS */
  npsFormShouldBeDisplayed: true,
  npsFormUrl: 'https://satisfaction.staging-goodwill.explain.fr/',
  retrieveResponseUrl: 'https://scagn3utp8.execute-api.eu-west-3.amazonaws.com/staging',
  npsMinAge: 30,
  npsMinDate: '2022-06-20',
  npsMaxDate: '2022-07-22',
};
