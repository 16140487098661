import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { ExplainStatistics } from '../../../../models/explain-statistics';
import { AccountInfo } from '../../../../models/account-info';

@Injectable({
  providedIn: 'root'
})
/**
 * Api Service in charge of retrieving and delivering Accounts informations and statistics to the rest of the application.
 * 05/09/2022 : Set methods are called once, in app.component.ts
 */
export class ApiExStatisticsService {

  private readonly accountInfoReplaySubject: ReplaySubject<AccountInfo>;
  private readonly explainStatisticsReplaySubject: ReplaySubject<ExplainStatistics>;
  private accountInfo!: AccountInfo;
  private statistics!: ExplainStatistics;

  constructor(private http: HttpClient,
              private headersService: HeadersService) {
    this.accountInfoReplaySubject = new ReplaySubject<AccountInfo>(1);
    this.explainStatisticsReplaySubject = new ReplaySubject<ExplainStatistics>(1);
  }

  /** ReplaySubject allowing external components to access last stored value of AccountInfo object */
  get AccountInfoReplaySubject(): ReplaySubject<AccountInfo> {
    return this.accountInfoReplaySubject;
  }

  /** ReplaySubject allowing external components to access last stored value of ExplainStatistics object */
  get ExplainStatisticsReplaySubject(): ReplaySubject<ExplainStatistics> {
    return this.explainStatisticsReplaySubject;
  }

  get Statistics(): ExplainStatistics {
    return this.statistics;
  }

  get AccountInfo(): AccountInfo {
    return this.accountInfo;
  }

  /**
   * GET method to retrieve all Explain Statistics
   */
  getExplainStatistics(): Observable<ExplainStatistics> {
    return this.http.get(
      `${environment.explainApiUrl}statistics`,
      this.headersService.httpHeaders
    )
      .pipe(
        map((response: any) => {
          return new ExplainStatistics(response);
        }));
  }

  /**
   * Method setting Explain Statistics and store it into service.
   */
  setExplainStatistics(): void {
    this.getExplainStatistics()
      .subscribe((response) => {
        this.statistics = response;
        this.explainStatisticsReplaySubject.next(this.statistics);
      });
  }

  /**
   * GET method to retrieve user's Account Info
   */
  getAccountInfos(): Observable<AccountInfo> {
    return this.http
      .get(
        `${environment.explainApiUrl}account/info`,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: any) => {
          return new AccountInfo(response);
        }));
  }

  /**
   * Method setting user's Account Info and store it into service.
   */
  setAccountInfos(): void {
    this.getAccountInfos()
      .subscribe((response) => {
        this.accountInfo = response;
        this.accountInfoReplaySubject.next(this.accountInfo);
      });
  }
}
