import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { IpService } from '../ip.service';
import { HeadersService } from '../api/headers/headers.service';
import { EventTypeName, TrackParameters, TrackProperties, TrackUserDetails, UserTrackerBody } from '../../../models/user-tracker';
import { DbImpacter, TrackImpacter } from '../../../models/impacter/impacter';
import { TrackPositions } from '../../../models/impacter/impacter-position';
import { TenderEventType } from '../../directives/behaviourDirective/tracking.directive';
import { UserSessionService } from "../user-session.service";

/**
 * Service in charge of sending user data to the lambda lambda-user-tracker through an api-gateway.
 * The idea is to intercept every call to the api using http request, copy the info, and send it to the lambda.
 */
@Injectable({
  providedIn: 'root'
})
export class UserTrackerService {

  constructor(private http: HttpClient,
              private router: Router,
              private ipService: IpService,
              private userSessionService: UserSessionService,
              private headersService: HeadersService) {}

  /** Map dispatching methods according to the Type of the tracked event */
  private responseBodyBuildMethodsMap = new Map<string, (eventBody: any) => any>([
    [EventTypeName.IMPACTER_SEARCH, (eventBody) => this.buildResponseBodyIMPACTER_SEARCH(eventBody)],
    [EventTypeName.IMPACTER_OPENING, (eventBody) => this.buildResponseBodyIMPACTER_OPENING(eventBody)],
    [EventTypeName.IMPACTER_ARTICLE_SEARCH, (eventBody) => this.buildResponseBodyIMPACTER_ARTICLE_SEARCH(eventBody)]
  ]);

  /** Map dispatching methods according to the Type of the tracked event */
  private parametersBuildMethodsMap = new Map<string, (params: HttpParams) => any>([
    [EventTypeName.IMPACTER_SEARCH, () => this.buildParametersIMPACTER_SEARCH()],
    [EventTypeName.IMPACTER_OPENING, () => this.buildParametersIMPACTER_OPENING()],
    [EventTypeName.DOCUMENT_OPENING_FROM_ALERT, (params) => this.buildParametersDOCUMENT_OPENING_FROM_ALERT(params)]
  ]);

  /** Track method to send all transaction data to lambda
   * @param body - tailored JSON body */
  track(body: UserTrackerBody | any) {
    return this.http.post(`${environment.explainApiGatewayUrl}tracking`, body);
  }

  trackEvent(eventType: EventTypeName | TenderEventType, eventName: string, eventLocation = '', eventValue = '', eventDetail = '') {
    const userId = localStorage.getItem('user_id');
    if (!userId) { return; }
    const body = {
      event_type: eventType,
      event_timestamp: (new Date()).toISOString(),
      user_id: +userId,
      email: localStorage.getItem('email'),
      event_location: eventLocation,
      event_name: eventName,
      event_value: eventValue.toString(),
      event_detail: eventDetail.toString(),
    };
    this.track(body).toPromise();
  }

  /** Method building the body to send to the userTracker lambda's api */
  buildUserTrackerJSON(request: HttpRequest<any>, event: HttpResponse<any>): UserTrackerBody | null {
    const eventTypeName = this.getEventTypeName(request);
    if (eventTypeName) {
      const userTrackerBody = new UserTrackerBody(eventTypeName);

      // PROPERTIES
      userTrackerBody.properties = this.buildProperties(request);

      // PARAMETERS
      if (this.parametersBuildMethodsMap.has(eventTypeName)) {
        const getParams = this.parametersBuildMethodsMap.get(eventTypeName);
        if (getParams) {
        userTrackerBody.parameters = getParams(request.params);
        }
      } else {
        userTrackerBody.parameters = {};
      }

      // REQUEST HEADERS
      userTrackerBody.request_headers = this.buildHeaders(request.headers);

      // REQUEST BODY
      userTrackerBody.request_body = request.body;

      // RESPONSE HEADERS
      userTrackerBody.response_headers = this.buildHeaders(event.headers);

      // RESPONSE BODY
      if (this.responseBodyBuildMethodsMap.has(eventTypeName)) {
        const getResponseBody = this.responseBodyBuildMethodsMap.get(eventTypeName);
        if (getResponseBody) {
        userTrackerBody.response_body = getResponseBody(event.body);
        }
      } else {
        userTrackerBody.response_body = event.body;
      }

      return userTrackerBody;
    }
  return null;
  }

  getEventTypeName(request: HttpRequest<any>): string | null {
    return request.headers.get('tracked-method-name');
  }

  buildProperties(request: HttpRequest<any>): TrackProperties {
    const consumedEndpointType = request.method;
    const consumedEndpointUrl = request.urlWithParams;
    const currentFrontendUrl = this.buildCurrentFrontUrl();
    const ip = this.getUserIp();
    const userDetails = new TrackUserDetails(
      this.getUserExplainToken(),
      this.getUserEmail()
    );

    return new TrackProperties(
      consumedEndpointType,
      consumedEndpointUrl,
      currentFrontendUrl,
      ip,
      userDetails
    );
  }

  /** Methode getting user info in case of manually triggered tracking (not using interceptor) */
  buildBasicUserInformations() {
    const userId = localStorage.getItem('user_id');
    const groupAccountId = localStorage.getItem('group_account_id');
    return {
      user_id: userId ? + userId : null,
      group_account_id: groupAccountId ? +groupAccountId : null,
      email: localStorage.getItem('email')
    };
  }

  getTrackUserDetails() {
    return new TrackUserDetails(
      this.getUserExplainToken(),
      this.getUserEmail()
    );
  }

  getUserIp(): string {
    return this.ipService.UserIp;
  }
  getUserExplainToken(): string {
    return this.userSessionService.explainToken;
  }
  getUserEmail(): string | undefined {
    return localStorage.getItem('email')?.trim().toLowerCase();
  }
  getRouterUrl(): string {
    return this.router.url;
  }

  buildParametersIMPACTER_SEARCH(): TrackParameters {
    const trackParams = new TrackParameters();
    trackParams.exemple = 'exemple';

    return trackParams;
  }

  buildParametersIMPACTER_OPENING(): TrackParameters {
    const array = this.getRouterUrl().split('/');
    const trackParams = new TrackParameters();
    trackParams.search_territory_uid = array[array.findIndex((val) => val === 'search') + 1];
    trackParams.impacter_id = parseInt(array[array.findIndex((val) => val === 'impacter') + 1], 10);

    return trackParams;
  }

  buildParametersDOCUMENT_OPENING_FROM_ALERT(params: HttpParams): TrackParameters {
    const trackParams = new TrackParameters();
    const rank = params.get('rank');
    const articleCount = params.get('article_count');
    const adminDocCount = params.get('admin_doc_count');
    const documentType = params.get('document_type');
    const openSourceId = params.get('open_source_id');
    const collectiveOrderId = params.get('collective_order_id');

    if (rank) { trackParams.rank = +rank; }
    if (articleCount) { trackParams.article_count = parseInt(articleCount, 10); }
    if (adminDocCount) { trackParams.admin_doc_count = parseInt(adminDocCount, 10); }
    if (documentType) { trackParams.document_type = documentType; }
    if (openSourceId) { trackParams.territory_watch_id = parseInt(openSourceId, 10); }
    if (collectiveOrderId) { trackParams.collective_order_id = parseInt(collectiveOrderId, 10); }

    return trackParams;
  }

  buildHeaders(httpHeaders: HttpHeaders) {
    return this.headersService.jsonFromHttpHeaders(httpHeaders);
  }

  buildResponseBodyIMPACTER_SEARCH(eventBody: any) {
    return eventBody;
  }

  buildResponseBodyIMPACTER_OPENING(eventBody: DbImpacter): TrackImpacter {
    const impacter = eventBody;
    // @ts-ignore - tslint throw an error but it's allowed. Booleans are converted to 1 and 0s.
    // Sorting the array so false values come first and true values come last.
    const orderedPositions = impacter.positions.sort((a, b) => (a.is_old - b.is_old));

    return new TrackImpacter(
      impacter.id,
      impacter.name,
      new TrackPositions(orderedPositions)
    );
  }

  buildResponseBodyIMPACTER_ARTICLE_SEARCH(eventBody: any) {
    // Need to flatten objet into lists to avoid to many columns after tracking ETL process
    eventBody['territory_count'] = this.flattenObject(eventBody['territory_count']);
    return eventBody;
  }

  /** Building the current front Url as displayed in the browser */
  buildCurrentFrontUrl() {
    return `${environment.appBaseUrl}` + this.router.url;
  }

  /** Method to flatten an object into 2 lists of keys and values */
  flattenObject(toFlatten: Object): { keys: Array<any>, values: Array<any> } {
    return { keys: Object.keys(toFlatten), values: Object.values(toFlatten) };
  }

}

