export enum TopicType {
  DEFAULT = 'default_topic',
  CUSTOM = 'custom_topic'
}

export interface DbTenderTopic {
  id: number;
  name: string;
  owner_user_id?: number;
  type?: TopicType;
  created_at?: Date;
  updated_at?: Date;
  is_active?: boolean;
  description?: string;
}

export class TenderTopic {
  id: number;
  name: string;
  owner_user_id?: number;
  type?: TopicType;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
  description?: string;

  constructor(dbTenderTopic: DbTenderTopic) {
    this.id = dbTenderTopic.id;
    this.name = dbTenderTopic.name;
    this.owner_user_id = dbTenderTopic.owner_user_id;
    this.type = dbTenderTopic.type;
    this.createdAt = dbTenderTopic.created_at;
    this.updatedAt = dbTenderTopic.updated_at;
    this.isActive = dbTenderTopic.is_active;
    this.description = dbTenderTopic.description;
  }
}
