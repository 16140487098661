<div class="metadata-content d-flex flex-column ls-m1">

  <!-- dates de publication et de fin -->
  <div class="metadata-group dates-container">
    <div class="d-flex align-items-center">
      <i class="ph-fill ph-calendar-check flex-center-start"></i>
      <span>
        {{ 'tenders.metadata.from' | translate }}
      </span>
      <!--date de publication définie-->
      <span *ngIf="tenderDetail.indexationDate.getTime() > 0" class="important ls-m2">
      {{ tenderDetail.indexationDate | date:'dd/MM/yyyy' }}</span>
      <!--date de publication nulle ou undef-->
      <span *ngIf="tenderDetail.indexationDate.getTime() === 0" class="null-value ls-m2">
      {{ 'tenders.unset-values.undetected' | translate }}</span>
    </div>

    <div class="d-flex">
      <span>
        {{ 'tenders.metadata.to' | translate }}
      </span>
      <!--date de fermeture définie-->
      <ng-container *ngIf="tenderDetail.closingDate && (tenderDetail.closingDate.getTime() > 0)">
        <span *ngIf="tenderDetail.closingDate && hasNonZeroTime(tenderDetail.closingDate)" class="important ls-m2">
          {{ tenderDetail.closingDate | date:'dd/MM/yyyy à HH:mm' }}</span>
        <span *ngIf="tenderDetail.closingDate && !hasNonZeroTime(tenderDetail.closingDate)" class="important ls-m2">
          {{ tenderDetail.closingDate | date:'dd/MM/yyyy' }}</span>
      </ng-container>
      <!--date de fermeture nulle ou undef-->
      <span *ngIf="tenderDetail.tenderType === TenderTypesEnum.ATTRIBUTION">-</span>
      <span *ngIf="tenderDetail.tenderType !== TenderTypesEnum.ATTRIBUTION &&
              (!tenderDetail.closingDate || tenderDetail.closingDate && (tenderDetail.closingDate?.getTime() === 0))"
            class="null-value"
      >
        {{ 'tenders.unset-values.undetected' | translate }}
      </span>
    </div>
  </div>

  <div class="d-flex metadata-item ls-m2 fw-semibold">
    <i class="flex-center-start ph-fill ph-users"></i>
    <span>{{ tenderDetail.inquirers?.join(',') || 'tenders.unset-values.inquirers' | translate }}</span>
  </div>

  <div class="metadata-group">
    <div class="d-flex metadata-item"
         [ngClass]="{'null-value' : !tenderDetail.territories.length}"
         [ngbTooltip]="tenderTerritoriesTextService.getString(tenderDetail.territories)"
         [disableTooltip]="tenderDetail.territories.length <= MAX_SHOWN_TERRITORIES"
         tooltipClass="tender-territories-tooltip"
         placement="bottom auto">
      <i class="flex-center-start ph-fill ph-map-pin"></i>
      <span>{{ tenderDetail.territories.length ?
          tenderTerritoriesTextService.getCompactString(tenderDetail.territories, MAX_SHOWN_TERRITORIES) :
          'tenders.unset-values.territories' | translate }}</span>
    </div>
  </div>



</div>
