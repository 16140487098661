import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[infiniteScroll]',
  standalone: true
})
export class InfiniteScrollDirective {
  @Input() scrollThreshold = 120;
  lastScrollTop = 0;
  heightEmittedAt = 0;

  @Output() endScroll: EventEmitter<void> = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event.target'])
  scrollEvent(event: KeyboardEvent) {
    if (this.el.nativeElement.scrollTop > this.lastScrollTop) {
      const heightOfElement = this.el.nativeElement.scrollHeight;
      const currentScrolledY = this.el.nativeElement.scrollTop + this.el.nativeElement.clientHeight;

      const spaceLeft = heightOfElement - currentScrolledY;

      if (spaceLeft < this.scrollThreshold && this.heightEmittedAt !== heightOfElement) {
        this.endScroll.emit();
        this.heightEmittedAt = heightOfElement;
      }
    }
    this.lastScrollTop = this.el.nativeElement.scrollTop;
  }
}
