import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TerritoryWatchDayOfWeek} from "../../../models/territory-watch-settings";

@Component({
  selector: 'digest-week-days-selector',
  templateUrl: './digest-week-days-selector.component.html',
  styleUrls: ['./digest-week-days-selector.component.scss']
})
export class DigestWeekDaysSelectorComponent {
  @Input() days!: Array<TerritoryWatchDayOfWeek>;
  @Output() daySelected = new EventEmitter();


  onSelectDay(i: number) {
    this.daySelected.emit(i);
  }
}
