import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localDate'
})
export class ToLocalDatePipe implements PipeTransform {

  transform(value: string | undefined): string {
    const date = new Date(value ?? '');
    return date.toLocaleDateString();
  }

}
