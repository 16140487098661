export interface DbBookmarkPath {
  entity_id?: number;
  date?: Date;
  folder: {
    entity_id: number;
    entity_label: string;
  };
}

export class BookmarkPath {
  private _bookmarkId?: number;
  private _bookmarkFolderId: number;
  private _bookmarkFolderName: string;
  private _date?: Date;


  constructor(dbBookmarkPath: DbBookmarkPath) {
    this._bookmarkId = dbBookmarkPath.entity_id;
    this._bookmarkFolderId = dbBookmarkPath.folder.entity_id;
    this._bookmarkFolderName = dbBookmarkPath.folder.entity_label;
    this._date = dbBookmarkPath.date;
  }

  get bookmarkId(): number | undefined {
    return this._bookmarkId;
  }

  set bookmarkId(value: number | undefined) {
    this._bookmarkId = value;
  }

  get bookmarkFolderId(): number {
    return this._bookmarkFolderId;
  }

  set bookmarkFolderId(value: number) {
    this._bookmarkFolderId = value;
  }

  get bookmarkFolderName(): string {
    return this._bookmarkFolderName;
  }

  set bookmarkFolderName(value: string) {
    this._bookmarkFolderName = value;
  }

  get date(): Date | undefined {
    return this._date;
  }

  set date(value: Date) {
    this._date = value;
  }

}
