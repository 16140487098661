import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewChecked, TemplateRef
} from '@angular/core';
import { TermModel } from '../../core/term/term.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import blockType from '../../../assets/data/blockType.json';
import { BlockType } from '../../core/block/block.model';

@Component({
  selector: 'app-topic-block',
  templateUrl: './topic-block.component.html',
  styleUrls: ['./topic-block.component.scss']
})
export class TopicBlockComponent implements AfterViewChecked, OnChanges {
  @Input() blockType!: string;
  @Input() canDelete!: boolean;
  @Input() blockIndex!: number;
  @Input() columns: Array<Array<TermModel>> = [];
  @Input() topicComplexity!: number;
  @Input() topicComplexityMaxValue!: number;
  @Input() topicIncludeBlocks?: any;
  @Output() onTermUpdate = new EventEmitter<{ blockIndex: number, columns: Array<Array<TermModel>> }>();
  @Output() onDeleteBlockEvent = new EventEmitter<number>();
  public typeBlock = blockType.data;
  public requestList: Array<Array<TermModel>> = [];
  public requestList_encours: Array<Array<TermModel>> = [];
  public generatedTexte = '';
  public columns2: Array<Array<TermModel>> = [];
  @ViewChild('content', {static: false}) elementView!: ElementRef;
  @ViewChild('gr', {static: false}) gr!: ElementRef;
  public heightGR = 0;
  public height = 0;
  private testeGR = false;
  public showBadgeGR = false;
  public shouldIShowMoreButton = false;
  public shouldIShowlines: boolean = false;
  public clic = true;
  private test = false;

  constructor(private modalService: NgbModal,
              private cdRef: ChangeDetectorRef) {
  }


  ngAfterViewChecked(): void {
    if (this.gr) {
      this.heightGR = this.gr.nativeElement.offsetHeight;
      if (this.heightGR > 24) {
        if (!this.testeGR) {
          this.testeGR = true;
          this.showBadgeGR = true;
        }
      } else {
      }
    }
    if (this.elementView) {
      this.height = this.elementView.nativeElement.offsetHeight;
      if (this.height >= 80) {
        this.test = true;
        if (!this.clic) {
          this.shouldIShowMoreButton = true;
          this.shouldIShowlines = false;
        }
      } else {
        if (!this.test) {
          this.shouldIShowlines = true;
          this.clic = false;
          this.shouldIShowMoreButton = false;
        }

        if (this.height <= 40) {
          this.test = false;
          this.shouldIShowMoreButton = false;
          this.shouldIShowlines = true;
          this.clic = false;
        }

      }
    }
    this.cdRef.detectChanges();
  }

  showMore() {
    this.shouldIShowMoreButton = false;
    this.clic = true;
    this.shouldIShowlines = true;
    this.cdRef.detectChanges();
  }

  showLimit() {
    this.clic = false;
  }

  onTermsChange(event: any): void {
    const {columnIndex, terms} = event;
    if (!terms.length) {
      if (!this.columns[columnIndex]) {
        return;
      } else {
        this.columns.splice(columnIndex, 1);
      }
    } else {
      this.columns[columnIndex] = terms;
    }

    this.onTermUpdate.emit({
      blockIndex: this.blockIndex,
      columns: this.columns
    });
    this.generateRequest();
  }

  onTermsChangeGenerated(event: any, columnIndex: number): void {
    const {value, index} = event;
    const term = <TermModel> {
      term: value,
      locked: false
    };
    const term_empty = <TermModel> {
      term: '...',
      locked: false
    };
    this.generatedTexte = value;
    this.columns2 = JSON.parse(JSON.stringify(this.columns));
    if (this.columns2[index] == null) {
      this.columns2[index] = new Array<TermModel>();
    }
    this.columns2[index] = new Array<TermModel>();
    this.columns2[index].push(term);

    if (this.columns2[1] == null) {
      this.columns2[1] = new Array<TermModel>();
      this.columns2[1].push(term_empty);
    }

    const array: TermModel[][] = [];
    this.concatColumns_encours(array, columnIndex , []);
    this.requestList_encours = array;
  }

  openModalDelete(content: TemplateRef<any>) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true});
  }


  isForOneColumn(): boolean {
    return this.blockType === BlockType.TERMS_TO_EXCLUDE || this.blockType === BlockType.SIMPLE_LIST;
  }

  generateRequest(): void {
    const array: TermModel[][] = [];
    this.concatColumns(array, 0, []);
    this.requestList = array;
  }

  concatColumns(array: TermModel[][], indexColumn: number, value: TermModel[]) {
    if (indexColumn >= this.columns.length) {
      array.push(value);
      return;
    }
    this.columns[indexColumn].forEach(column => {
      const concatValue = [...value, column];
      this.concatColumns(array, indexColumn + 1, concatValue);
    });
  }

  concatColumns_encours(array: TermModel[][], indexColumn: number, value: TermModel[]) {
    if (indexColumn >= this.columns2.length) {
      array.push(value);
      return;
    }
    this.columns2[indexColumn].forEach(column => {
      const concatValue = [...value, column];
      this.concatColumns_encours(array, indexColumn + 1, concatValue);
    });
  }

  onDeleteBlock(): void {
    this.onDeleteBlockEvent.emit(this.blockIndex);
    this.modalService.dismissAll();
  }

  isBlockActive(): boolean {
    if (!this.columns || !this.columns.length) {
      return false;
    }

    return this.columns.some(column => column.length > 0);
  }

  getTerm(termIndex: number): Array<TermModel> {
    return this.columns?.length && this.columns[termIndex] ? this.columns[termIndex] : [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']?.currentValue) {
      this.columns = changes['columns'].currentValue;
      this.generateRequest();
    }
  }
}
