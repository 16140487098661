export enum TerritoryKind {
  DEPARTMENT = 'FRDEPA',
  EPCI = 'FREPCI',
  COMMUNE = 'FRCOMM',
  PAYS = 'FRPAYS',
  REGION = 'FRREGI',
}

export enum SubKind {
  SYNDICAT = 'SYND',
  EPTP = 'EPTP'
}
