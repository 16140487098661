import { Component, OnInit } from '@angular/core';
import { DbTopic, Topic, TopicType } from '../../models/topic';
import { FormControl } from '@angular/forms';
import { RegexpSearch } from 'src/app/shared/helpers/regex';
import { ApiService } from '../../shared/services/api/api.service';
import { first } from 'rxjs/operators';
import { ModuleManagerService } from "../../shared/services/module-manager.service";

@Component({
    selector: 'app-topics-page',
    templateUrl: './topics-page.component.html',
    styleUrls: ['./topics-page.component.scss']
})
export class TopicsPageComponent implements OnInit {
    public topics: Array<Topic> = [];
    public filteredTopics: Array<Topic> = [];
    public pages = new Array<number>();
    topicFilteredName = new FormControl() ;
    hasFilter = false ;
    showClearIcon = false ;
    TopicType = TopicType;

    constructor(
      private apiService: ApiService,
      private moduleManagerService: ModuleManagerService
    ) {
    }

    ngOnInit(): void {
      this.retrieveUserTopics();
      this.topicFilteredName.valueChanges.subscribe(val => {
        if ((val != null) && (val.length > 0)) {
          this._filteredTopics(val);
          this.hasFilter = true ;
          this.showClearIcon = true ;
        } else {
          this._resetTopics();
          this.hasFilter = false;
          this.showClearIcon = false;
        }
      }) ;
    }

  _filteredTopics(str: string) {
    this.filteredTopics = this.topics.filter(item => RegexpSearch.normalizedSearch(item.name, str));
  }

  _resetTopics() {
    this.filteredTopics = this.topics;
  }

  clearText() {
    this.topicFilteredName.setValue('') ;
  }

  retrieveUserTopics() {
    this.apiService.topic.getUserTopics(this.moduleManagerService.currentModule)
      .pipe(first())
      .subscribe((topics) => {
        this.topics = topics;
        this.filteredTopics = topics;
      });
  }

  renameTopic(value: DbTopic): void {
    this.apiService.topic.renameTopic(value)
      .toPromise().then();
  }

  deleteTopic(topicId: string): void {
    this.apiService.topic.deleteTopic(topicId).toPromise().then(
      () => {
        this.topics.splice(this.topics.findIndex(topic => topic.id === topicId), 1);
      },
      () => {
      }
    );
  }
}
