import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class TenderEntityService {

  tenderId$ = new BehaviorSubject<string>('');

  constructor() { }


}
