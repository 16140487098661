import { Component, Input, OnInit } from '@angular/core';
import { TendersSearchService } from '../../services/tenders-search.service';
import { DbTendersUser } from '../../../models/user';
import { TendersModuleService } from '../../services/tenders-module.service';
import { UserSettingsService } from '../../../common-explain/services/user-settings.service';

@Component({
  selector: 'app-filter-assignment',
  templateUrl: './filter-assignment.component.html',
  styleUrls: ['./filter-assignment.component.scss']
})
export class FilterAssignmentComponent implements OnInit {
  @Input({required: true}) tendersSearchService!: TendersSearchService;

  users!: DbTendersUser[];

  constructor(protected tendersModuleService: TendersModuleService, private userSettingsService: UserSettingsService) { }

  async ngOnInit() {
    this.users = (await this.tendersModuleService.groupAccountUsers).concat(await this.tendersModuleService.user);
    const storedFilter = this.userSettingsService.get('board-assignment-filter');
    if (storedFilter) this.tendersSearchService.selectedUserIds = storedFilter;
  }

  get usersFilter() {
    return this.tendersSearchService.selectedUserIds.reduce((prev, curr) => {
        const user = this.users.find((user) => user.id === curr);
        return user ? [...prev, user] : prev;
    }, new Array<DbTendersUser>());
  }

  set usersFilter(users) {
    const selectedIds = users.map((user) => user.id);
    this.tendersSearchService.selectedUserIds = selectedIds;
    this.userSettingsService.set('board-assignment-filter', selectedIds);
  }
}
