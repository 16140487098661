import { Component, Input } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-modal-unsubcribe-watch',
  templateUrl: './modal-unsubcribe-watch.component.html',
  styleUrls: ['./modal-unsubcribe-watch.component.scss']
})
export class ModalUnsubcribeWatchComponent {
  @Input() ownerUser: { name: string, email: string } = { name: '', email: '' };

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  cancel() {
    this.activeModal.dismiss();
  }

  confirm() {
    this.activeModal.close();
  }
}
