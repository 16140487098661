export interface DbImpacterPosition {

  end_date: string;
  is_old: boolean;
  nb_positions: number;
  role: string;
  start_date: string;
  territory: {
    name: string;
    uid: string;
  };
}

export class ImpacterPosition {
  endDate: string;
  isOld: boolean;
  nbPositions: number;
  role: string;
  startDate: string;
  territory: {
    name: string;
    uid: string;
  };

  constructor(dbImpacterPosition: DbImpacterPosition) {
    this.endDate = dbImpacterPosition.end_date;
    this.isOld = dbImpacterPosition.is_old;
    this.nbPositions = dbImpacterPosition.nb_positions;
    this.role = dbImpacterPosition.role;
    this.startDate = dbImpacterPosition.start_date;
    this.territory = dbImpacterPosition.territory;
  }
}

export class ImpacterPositionLight {
  is_old: boolean;
  role: string;
  territory_uid: string;
  territory_name: string;

  /** create light version of ImpacterPosition from an ImpacterPosition */
  constructor(impacterPosition: DbImpacterPosition) {
    this.is_old = impacterPosition.is_old;
    this.role = impacterPosition.role;
    this.territory_uid = impacterPosition.territory.uid;
    this.territory_name = impacterPosition.territory.name;
  }
}

export class TrackPositions {
  one: ImpacterPositionLight;
  two?: ImpacterPositionLight;

  constructor(positionsLight: Array<DbImpacterPosition>) {
    this.one = new ImpacterPositionLight(positionsLight[0]);
    if (positionsLight.length > 1) {
      this.two = new ImpacterPositionLight(positionsLight[1]);
    }
  }
}
