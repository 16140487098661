<div class="backdrop"
     [ngClass]="{'show': show,
      'in-view': inView,
       'in-impacter-view': inImpacterView
        }">
</div>
<div class="loader" [className]=topChoice [ngClass]="{'show': show, 'in-view': inView, 'center not-margin': center}" >
  <div class="loader-shape">
    <svg *ngIf="!noResult" class="loader-icon" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
      <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate attributeName="r"
                   begin="1.5s" dur="3s"
                   values="6;22"
                   calcMode="linear"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
                   begin="1.5s" dur="3s"
                   values="1;0" calcMode="linear"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-width"
                   begin="1.5s" dur="3s"
                   values="2;0" calcMode="linear"
                   repeatCount="indefinite" />
        </circle>
        <circle cx="22" cy="22" r="6" stroke-opacity="0">
          <animate attributeName="r"
                   begin="3s" dur="3s"
                   values="6;22"
                   calcMode="linear"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-opacity"
                   begin="3s" dur="3s"
                   values="1;0" calcMode="linear"
                   repeatCount="indefinite" />
          <animate attributeName="stroke-width"
                   begin="3s" dur="3s"
                   values="2;0" calcMode="linear"
                   repeatCount="indefinite" />
        </circle>
        <circle cx="22" cy="22" r="8">
          <animate attributeName="r"
                   begin="0s" dur="1.5s"
                   values="6;1;2;3;4;5;6"
                   calcMode="linear"
                   repeatCount="indefinite" />
        </circle>
      </g>
    </svg>
    <svg *ngIf="noResult" class="loader-icon" width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>no-results</title>
      <g id="A.0.4---FINAL---MARS-2021" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M21.333037,0 C33.0955403,0 42.6660741,9.57066667 42.6660741,21.3333333 C42.6660741,26.256 40.9727643,30.7813333 38.1648033,34.392 L38.1648033,34.392 L47.2180109,43.448 C48.260663,44.4906667 48.260663,46.176 47.2180109,47.2186667 C46.6980181,47.7386667 46.0153609,48 45.3327037,48 C44.6500465,48 43.9673893,47.7386667 43.4473966,47.2186667 L43.4473966,47.2186667 L34.3915223,38.1653333 C30.7809058,40.9733333 26.2556353,42.6666667 21.333037,42.6666667 C9.57053374,42.6666667 0,33.096 0,21.3333333 C0,9.57066667 9.57053374,0 21.333037,0 Z M21.333037,5.33333333 C12.5091596,5.33333333 5.33325926,12.5093333 5.33325926,21.3333333 C5.33325926,30.1573333 12.5091596,37.3333333 21.333037,37.3333333 C30.1569145,37.3333333 37.3328148,30.1573333 37.3328148,21.3333333 C37.3328148,12.5093333 30.1569145,5.33333333 21.333037,5.33333333 Z M24.5805405,14.1146667 C25.6231927,13.072 27.3085026,13.072 28.3511548,14.1146667 C29.3938069,15.1573333 29.3938069,16.8426667 28.3511548,17.8853333 L28.3511548,17.8853333 L24.9032027,21.3333333 L28.3511548,24.7813333 C29.3938069,25.824 29.3938069,27.5093333 28.3511548,28.552 C27.831162,29.072 27.1485048,29.3333333 26.4658476,29.3333333 C25.7831904,29.3333333 25.1005332,29.072 24.5805405,28.552 L24.5805405,28.552 L21.1325884,25.104 L17.6846362,28.552 C17.1646435,29.072 16.4819863,29.3333333 15.7993291,29.3333333 C15.1166719,29.3333333 14.4340147,29.072 13.9140219,28.552 C12.8713698,27.5093333 12.8713698,25.824 13.9140219,24.7813333 L13.9140219,24.7813333 L17.3619741,21.3333333 L13.9140219,17.8853333 C12.8713698,16.8426667 12.8713698,15.1573333 13.9140219,14.1146667 C14.9566741,13.072 16.6419841,13.072 17.6846362,14.1146667 L17.6846362,14.1146667 L21.1325884,17.5626667 Z" id="no-results" fill="#FC4C02"></path>
      </g>
    </svg>
    <div class="fw-bold loader-label">
      <span *ngIf="!inView">{{'loader' | translate}}</span>
      <span *ngIf="inView">
        <ng-container *ngIf="!inSearch">{{'loader-doc' | translate}}</ng-container>
        <ng-container *ngIf="inSearch">
          <span *ngIf="!noResult">{{'loader-search-doc' | translate}}</span>
          <span *ngIf="noResult">{{'loader-search-not-found-doc' | translate}}</span>
        </ng-container>
      </span>

    </div>
  </div>
</div>
