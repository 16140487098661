import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../../../../shared/services/api/headers/headers.service';
import { environment } from '../../../../../environments/environment';
import { EventTypeName } from '../../../../models/user-tracker';
import { map } from 'rxjs/operators';
import { DbTenderDetail } from '../../../models/tender-detail';
import { UserTrackerService } from '../../../../shared/services/tracking/user-tracker.service';
import { firstValueFrom } from 'rxjs';

const ALLOWED_SOURCES = ['external', 'search', 'watch'];

@Injectable({
  providedIn: 'root'
})
export class ApiTenderDetailService {

  constructor(private http: HttpClient,
              private headerService: HeadersService,
              private userTrackerService: UserTrackerService,
  ) {
  }

  getTenderDetail(tenderId: string, source: string = 'external'): Promise<DbTenderDetail> {
    const opening_source = ALLOWED_SOURCES.includes(source) ? source : 'external';
    return firstValueFrom(
      this.http
        .get<DbTenderDetail>(
          `${environment.explainApiUrl}tenders?uid=${tenderId}`,
          this.headerService.httpHeaders
        ).pipe(
        map((res) => {
          firstValueFrom(
            this.userTrackerService.track({
              event_type: EventTypeName.TENDER_DETAIL_OPENING,
              event_timestamp: (new Date()).toISOString(),
              user_id: localStorage.getItem('user_id'),
              email: localStorage.getItem('email'),
              ...res,
              opening_source
            })
          )
          return res;
        })
      )
    );
  }

}
