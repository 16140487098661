
<ex-button *ngIf="displayShareIcon" class="share-button" type="tertiary" size="small" icon="share-network-fill"
           (click)="shareMarket()"/>
<ex-button *ngIf="displayShareText" (click)="shareMarket()">
  {{'tenders.share.share-text' | translate}}
</ex-button>

<!--toast for all possible action from user on tenderList entity : share|... -->
<ng-template #tenderListActionToast let-toast="toast" let-toastService="toastService">
  <div class="position-relative d-flex w-100 h-100">
    <div class="icon icon--close position-absolute align-self-center" (click)="toastService.remove(toast)"></div>
    <!--icon displayed when success only-->
    <div class="d-flex flex-column align-items-start justify-content-start">
      <i *ngIf="!toast['classname'].includes('error')"
         class="ph ph-check-circle align-self-center"></i>
    </div>
    <div class="fw-bold align-self-center toast-text pdr-16px">
      <!--error message-->
      <ng-container *ngIf="toast['classname'].includes('error'); else success">
        <p class="m-0">{{'tenders.market-watches.toast.'+toast['action']+'-error' | translate}}</p>
      </ng-container>
      <!--success message-->
      <ng-template #success>
        <p class="m-0">{{'tenders.market-watches.toast.'+toast['action']+'-success' | translate:({name: toast['name']})}}</p>
      </ng-template>
    </div>
  </div>
</ng-template>
