import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../../../../models/user';
import { HttpClient } from '@angular/common/http';
import { HeadersService } from '../headers/headers.service';
import { Observable } from 'rxjs';
import {
  DbGetContextFromShareResponseBody, DbGetGuestUserResponseBody,
  DbPutShareResponseBody,
  PutShareResponseBody,
  SharedDocument
} from '../../../../models/share/share';
import { DocumentSharingTypeEnum, DocumentType } from '../../../../models/document-entity';

@Injectable({
  providedIn: 'root'
})
/**
 * Service managing all api calls for Share related features
 */
export class ApiShareService {

  constructor(
    private http: HttpClient,
    private headersService: HeadersService) {}

  /**
   * Method to retrieve all users linked to the sharing_user
   * i.e. users from it's company and users he already shared content with
   */
  searchSharedWithUsers(): Observable<User[]> {
    const body = {
      'fields': [
        'id',
        'shared_with_user',
        'sharing_user_id'
      ],
      'filters': {
        'sharing_user_id': +(localStorage.getItem('user_id') ?? 0)
      },
      'limit': 1000,
      'offset': 0
    };
    return this.http
      .post(
        `${environment.explainApiUrl}shares`,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((response: any) => {
        return response['data'].map((item: any) => new User(item['shared_with_user']));
      }));
  }

  /**
   * Method called to trigger the share, from the sharing_user to the shared_with_user
   */
  insertShare(document: SharedDocument, message: string, emailsTo: string[], sendCopy: boolean): Observable<PutShareResponseBody> {
    const docShared: any = {
      document_uid: document?.documentUid,
      snippet: document?.snippet,
      title: document?.title,
      type: document?.type,
      publication_date: document?.publicationDate
    };
    // cas article
    if (docShared.type === DocumentType.ARTICLE) {
      docShared['territories'] = document?.territories;
      docShared['source'] = document?.source;
      if (document?.nbImages) {
        docShared['nb_images'] = document.nbImages;
      } else {
        docShared['nb_images'] = 0;
      }
    }
    // cas admin_doc ou project_document
    if ([DocumentSharingTypeEnum.ADMIN_DOC, DocumentSharingTypeEnum.PROJECT_DOCUMENT].includes(docShared.type as DocumentSharingTypeEnum)) {
      docShared['displayed_publication_type'] = document?.displayedPublicationType;
      docShared['match_stems'] = document?.matchStems;
      docShared['territories'] = document?.territories;
      docShared['inquirers'] = document?.inquirers;
    }
    if (docShared.type === DocumentSharingTypeEnum.TENDER_DOCUMENT) {
      docShared['indexation_date'] = document?.indexationDate;
      docShared['closing_date'] = document?.closingDate;
      docShared['territories'] = document?.territories;
      docShared['inquirers'] = document?.inquirers;
      docShared['tender_type'] = document?.tenderType;
      docShared['market_type'] = document?.marketType;
      docShared['note'] = document?.note;
      docShared['status'] = document?.status;
      docShared['assigned_user'] = document?.assignedUser;
    }
    const body = {
      documents: [docShared],
      message: message,
      users_email: emailsTo,
      send_copy: sendCopy
    };
    return this.http
      .put(
        `${environment.explainApiUrl}shares`,
        body,
        this.headersService.httpHeaders
      )
      .pipe(
        map((res) => {
          return new PutShareResponseBody(res as DbPutShareResponseBody);
        }));
  }

  /**
   * Method to retrieve informations on guest user.
   */
  getGuestUser(shareToken: string): Observable<User> {
    const body = {
      'fields': [
        'id',
        'shared_with_user'
      ],
      'filters': {
        'shared_document': {
          'share_token': shareToken
        }
      },
      'limit': 1
    };
    return this.http
      .post(
        `${environment.explainApiUrl}shares`,
        body,
        this.headersService.getSharedHeaders(shareToken)
      )
      .pipe(
        map((response) => {
          const res = response as DbGetGuestUserResponseBody;
          return new User(res.data[0].shared_with_user);
        }));
  }

  /**
   * Method to retrieved sharedDocument from a shareId.
   */
  getSharedDocument(shareId: string, shareToken  = ''): Observable<SharedDocument> {
    const body = {
      'fields': [
        'id',
        'shared_document'
      ],
      'filters': {
        'id': shareId
      },
      'limit': 1
    };
    return this.http
      .post(
        `${environment.explainApiUrl}shares`,
        body,
        // shareToken ? case guest_user : case explain user.
        shareToken ? this.headersService.getSharedHeaders(shareToken) : this.headersService.httpHeaders
      )
      .pipe(
        map((response) => {
          const res = response as DbGetContextFromShareResponseBody;
          return new SharedDocument(res.data[0].shared_document);
        }));
  }

}
