import { DbPagination } from '../pagination';
import { DbUser } from '../user';
import { TerritoryForArticle } from '../territory/territory-utils';
import { MatchStem } from '../match-stem';

export interface Document {
  document_uid: string;
  type: string;
  title: string;
}

export interface DbSharedDocument extends Document {
  snippet?: string;
  publication_date?: Date;
  territories?: Array<TerritoryForArticle>;
  source?: string;
  nb_images?: number;
  displayed_publication_type?: string;
  match_stems?: MatchStem[];
  inquirers?: string[];

  // tender fields
  indexation_date?: Date;
  closing_date?: Date;
  note?: string;
  market_type?: string;
  status?: string;
  tender_type?: string;
  assigned_user?: string;
}

export interface SharedTender extends Document{
  "indexation_date": Date,
  "closing_date": Date,
  "note": string,
  "market_type": string,
  "status": string,
  "inquirers": string[],
  "tender_type": string,
  "territories": any
}

export class SharedDocument {
  private _documentUid: string;
  private _type: string;
  private _title: string;
  private _snippet?: string;
  private _publicationDate?: Date;
  private _territories?: Array<TerritoryForArticle>;
  private _inquirers?: string[];
  private _source?: string;
  private _nbImages?: number;
  private _displayedPublicationType?: string;
  private _matchStems?: MatchStem[];

  public indexationDate?: Date;
  public closingDate?: Date;
  public note?: string;
  public marketType?: string;
  public status?: string;
  public tenderType?: string;
  public assignedUser?: string;

  constructor(dbSharedDocument: DbSharedDocument) {
    this._documentUid = dbSharedDocument.document_uid;
    this._type = dbSharedDocument.type;
    this._title = dbSharedDocument.title;
    this._snippet = dbSharedDocument.snippet;
    this._publicationDate = dbSharedDocument.publication_date;
    this._source = dbSharedDocument.source;
    this._nbImages = dbSharedDocument.nb_images;
    this._displayedPublicationType = dbSharedDocument.displayed_publication_type;
    this._territories = dbSharedDocument.territories ?? [];
    this._matchStems = dbSharedDocument.match_stems ?? [];
    this._inquirers = dbSharedDocument.inquirers ?? [];

    this.indexationDate = dbSharedDocument.indexation_date;
    this.closingDate = dbSharedDocument.closing_date;
    this.note = dbSharedDocument.note;
    this.marketType = dbSharedDocument.market_type;
    this.status = dbSharedDocument.status;
    this.tenderType = dbSharedDocument.tender_type;
    this.assignedUser = dbSharedDocument.assigned_user;
  }

  get documentUid(): string {
    return this._documentUid;
  }

  set documentUid(value: string) {
    this._documentUid = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get snippet(): string | undefined {
    return this._snippet;
  }

  set snippet(value: string) {
    this._snippet = value;
  }

  get publicationDate(): Date | undefined {
    return this._publicationDate;
  }

  set publicationDate(value: Date) {
    this._publicationDate = value;
  }

  get territories(): Array<TerritoryForArticle> | undefined  {
    return this._territories;
  }

  set territories(value: Array<TerritoryForArticle>) {
    this._territories = value;
  }

  get source(): string | undefined {
    return this._source;
  }

  set source(value: string) {
    this._source = value;
  }

  get nbImages(): number | undefined {
    return this._nbImages;
  }

  set nbImages(value: number) {
    this._nbImages = value;
  }

  get displayedPublicationType(): string | undefined {
    return this._displayedPublicationType;
  }

  set displayedPublicationType(value: string) {
    this._displayedPublicationType = value;
  }

  get matchStems(): MatchStem[] | undefined {
    return this._matchStems;
  }

  set matchStems(value: MatchStem[]) {
    this._matchStems = value;
  }

  get inquirers(): string[] | undefined {
    return this._inquirers;
  }

  set inquirers(value: string[]) {
    this._inquirers = value;
  }
}

export interface DbPutShareResponseBody {
  errored_email: string[];
  message: string;
  share_id: number[];
}

export class PutShareResponseBody {
  private _erroredEmail: string[];
  private _message: string;
  private _shareId: number[];

  constructor(dbPutShareResponseBody: DbPutShareResponseBody) {
    this._erroredEmail = dbPutShareResponseBody.errored_email;
    this._message = dbPutShareResponseBody.message;
    this._shareId = dbPutShareResponseBody.share_id;
  }

  get erroredEmail(): string[] {
    return this._erroredEmail;
  }

  set erroredEmail(value: string[]) {
    this._erroredEmail = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }

  get shareId(): number[] {
    return this._shareId;
  }

  set shareId(value: number[]) {
    this._shareId = value;
  }
}

export interface DbGetContextFromShareResponseBody {
  data: Array<{id: number, shared_document: DbSharedDocument}>;
  pagination: DbPagination;
}

export interface DbGetGuestUserResponseBody {
  data: Array<{id: number, shared_with_user: DbUser}>;
  pagination: DbPagination;
}
