<div class="topic-form-container">
  <div class="no-select top-topic-form-header w-100">
    <!--cancel button-->
    <div class="topic-cancel-button-container d-flex align-self-center">
      <div *ngIf="!isCopy" class="align-self-center d-flex cancel-button" (click)="openModal()">
        <div class="align-self-center icon icon--close"></div>
        <div class="align-self-center">{{'button.cancel' | translate | uppercase}}</div>
      </div>
      <div *ngIf="isCopy" class="align-self-center d-flex cancel-button" (click)="close()">
        <div class="align-self-center icon icon--close"></div>
        <div class="align-self-center">{{'button.close' | translate | uppercase}}</div>
      </div>
    </div>
    <!--topic complexity-->
    <div class="title-topic-form align-self-center d-flex">
      <span class="topic-size-text no-select"> {{'topic-form.topic-size' | translate}} </span>
      <app-progress-bar
        class="no-select"
        [hidden]="topicComplexityMaxValue===0"
        [maxValue]="topicComplexityMaxValue"
        [minValue]="0"
        [currentValue]="topicComplexity"
        [nameValue]="'topic-form.rules' | translate"
        [progressBarWidth]="148">
      </app-progress-bar>
    </div>
    <!--saving button-->
    <div *ngIf="!isCopy"
         class="wizard-buttons-header d-flex justify-content-between align-items-center">
      <button data-cy="create-topic-validation-button"
              class="save-topic-button align-self-center btn-next d-flex"
              [disabled]="!isValidForm()"
              appPreventDoubleClick
              (throttledClick)="createTopic()"
              [throttleTime]="1000"
      >
        <div class="align-self-center">{{ 'button.create-topic' | translate }}</div>
        <div class="align-self-center icon-arrow-circle"></div>
      </button>
    </div>
    <div *ngIf="isCopy"
         class="wizard-buttons-header d-flex justify-content-between align-items-center">
      <button data-cy="create-copied-topic-validation-button"
              class="save-topic-button btn-next d-flex tooltip_red align-items-center"
              tooltipClass="tooltip_max_width_300"
              type="button" [disabled]="!isValidForm()" (click)="openModalSaveDefaultTopicAsCustom()"
              placement="bottom" ngbTooltip="{{'button.tooltip-create-topic-default'|translate}}" container="body"
              triggers="hover">
        {{ 'button.create-topic-default' | translate }}<em class="icon-duplicate-topic"></em>
      </button>
    </div>

  </div>
  <div class="topic-form-header d-flex">
    <div class="col-10">
      <div class="d-flex">
        <div class="label-block-1">
          <label class="align-self-center w-100 pdr-16px">
            <div>{{ 'wizard.topic-name' | translate }}</div>
            <input data-cy="topic-name-input"
                   type="text" class="align-self-center" maxlength="50" [(ngModel)]="name">
          </label>
        </div>
        <div class="label-block-2">
          <label class="align-self-center w-100">
            <div>{{ 'wizard.topic-description' | translate }}<span class="fst-italic">({{
                'wizard.optional' |
                  translate
              }})</span></div>
            <input data-cy="topic-description-input"
                   type="text" class="align-self-center description" maxlength="100" [(ngModel)]="description">
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="topic-form-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-10">
          <div class="term">
            <div class="term-header term-include d-flex">
              <div class="align-self-center icon-wizard icon-wizard--checkmark term-icon"></div>
              <div class="fw-bold align-self-center">
                <div class="title text-uppercase">{{'wizard.term-include' | translate}}</div>
                <div class="sub-title">{{'wizard.term-include-subtitle' | translate}}</div>
              </div>
            </div>
            <div class="term-container">
              <ng-container *ngFor="let block of includeBlocks; let i = index">
                <app-topic-block
                  data-cy="topic-block-include-component"
                  [blockType]="block.type"
                  [blockIndex]="i"
                  [columns]="block.columns ? block.columns : []"
                  [canDelete]="true"
                  [topicComplexity]="topicComplexity"
                  [topicComplexityMaxValue]="topicComplexityMaxValue"
                  (onTermUpdate)="onIncludeTermUpdate($event)"
                  (onDeleteBlockEvent)="onDeleteBlock($event)">
                </app-topic-block>
              </ng-container>
              <button data-cy="add-include-block-button" class="add-include-block-button d-flex" (click)="openAddBlockModal(addBlockModal)">
                <div class="align-self-center icon-wizard icon-wizard--close icon-plus"></div>
                <div
                  class="align-self-center fw-bold text-uppercase">{{'button.add-block' | translate}}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-10">
          <div class="term">
            <div class="term-header term-exclude d-flex">
              <div class="align-self-center icon icon--forbidden term-icon"></div>
              <div class="fw-bold align-self-center">
                <div class="title text-uppercase">{{'wizard.term-exclude' | translate}}</div>
                <div class="sub-title">{{'wizard.term-exclude-subtitle' | translate}}</div>
              </div>
            </div>
            <div class="term-container">
              <app-topic-block
                data-cy="topic-block-exclude-component"
                [blockType]="BlockType.TERMS_TO_EXCLUDE"
                [canDelete]="false"
                [columns]="excludeBlock?.columns ?? []"
                [blockIndex]="0"
                [topicComplexity]="topicComplexity"
                (onTermUpdate)="onExcludeTermUpdate($event)">
              </app-topic-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #addBlockModal let-modal>
  <div class="modal-header">
    <div>
      <div class="modal-title color-basic-800">{{'content.choose-block-to-add' | translate}}</div>
      <div class="modal-sub-title">{{'content.choose-block-to-add-sub' | translate}}</div>
    </div>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">

    </button>
  </div>
  <div class="modal-body with-border-top">
    <app-topic-block-select (onBlockSelect)="onBlockSelect($event)"></app-topic-block-select>
  </div>
  <div class="modal-footer">
    <button data-cy="block-modal-cancel-button" type="button" class="cancel fw-bold"
            (click)="modal.close(); typeBlockToAdd = null">{{'alert.cancel' | translate}}</button>
    <button data-cy="block-modal-validation-button" type="button" class="btn btn-orange confirm-delete d-flex" (click)="onAddNewBlock()"
            [disabled]="!typeBlockToAdd">
      <div class="icon icon--plus-circle align-self-center"></div>
      <div class="text-uppercase fw-bold align-self-center">{{'button.add-this-block' | translate}}</div>
    </button>
  </div>
</ng-template>

<ng-template #explicative let-modal>
  <div class="d-flex flex-column justify-content-between">
    <div class="modal-header">
      <div>
        <div class="modal-title color-basic-800">{{'explicative.title' | translate}}</div>
        <div class="modal-sub-title mt-2">
        </div>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()">

      </button>
    </div>
    <div class="modal-body with-border-top">
      <div class="w-100">
        <app-explicative-block-one *ngIf="eCounter==1"></app-explicative-block-one>
        <app-topic-block-select *ngIf="eCounter==2" [isPrevue]="true" (onBlockSelect)="onBlockSelect($event)">
        </app-topic-block-select>
        <app-explicative-block-two *ngIf="eCounter==3"></app-explicative-block-two>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
      <div *ngIf="eCounter==1"></div>
      <div *ngIf="eCounter>1">
        <button (click)="decrementExplicativeCounter()" style="text-transform: uppercase;" type="button"
                class="explicativeButtonBorder fw-bold">
          <img src="assets/images/chevron-left-solid.svg" alt="icon-chevron-left" height="12px"
               style="padding-bottom: 3px;margin-right:10px;">
          {{'explicative.precedent' | translate}}
        </button>
      </div>
      <div class="d-flex">
        <button type="button" class="cancel fw-bold"
                (click)="modal.close(); typeBlockToAdd = null">{{'explicative.cancel' | translate}}</button>
        <button type="button" class="btn explicativeButton" (click)="incrementExplicativeCounter()">
          <div class="text-uppercase fw-bold align-self-center w-100">
            <div class="d-flex align-items-center" *ngIf="eCounter<3">
              {{'explicative.suivant' | translate}}
              ({{eCounter}}/3)
              <img src="assets/images/chevron-right-solid.svg" alt="icon-chevron-right" height="12px"
                   style="margin-left:10px;margin-top: 1px;">
            </div>
            <div *ngIf="eCounter==3">
              {{'explicative.terminer' | translate}}
              ({{eCounter}}/3)
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #saveDefaultTopicAsCustom let-modal>
  <div class="m-2 p-1 isCopy">
    <div class="bold title mb-3">
      {{ 'content.modal-save-topic-as-custom.title' | translate}}
    </div>
    <div class="pg mb-2">
      {{ 'content.modal-save-topic-as-custom.paragraph-1' | translate}}
    </div>
    <div class="pg mb-3">
      {{ 'content.modal-save-topic-as-custom.paragraph-2' | translate}}
    </div>
    <div class="subtitle bold mb-2" *ngIf="alerts.length === 1">
      {{ 'content.modal-save-topic-as-custom.paragraph-3-equal-one-alert' | translate : ({number: alerts.length})}}
    </div>
    <div class="subtitle bold mb-2" *ngIf="alerts.length > 1">
      {{ 'content.modal-save-topic-as-custom.paragraph-3' | translate : ({number: alerts.length})}}
    </div>
    <div>
      <ul>
        <li class="pg" *ngFor="let item of alerts">{{item.name}}</li>
      </ul>
    </div>
    <div class="pg2 mb-3" *ngIf="alerts.length === 1">
      {{ 'content.modal-save-topic-as-custom.paragraph-4-equal-one-alert' | translate }}
    </div>
    <div class="pg2 mb-3" *ngIf="alerts.length > 1">
      {{ 'content.modal-save-topic-as-custom.paragraph-4-more-than-one-alert' | translate :  ({number: alerts.length})}}
    </div>
    <div *ngIf="alerts.length>0">
      <div class="form-check pg3 mb-2 radio-item ">
        <input class="form-check-input custom-radio-button"
               [(ngModel)]="shouldReplaceAlertTopic"
               type="radio"
               [value]="true"
               name="flexRadioTrue"
               id="flexRadioTrue"
        >
        <label class="form-check-label" for="flexRadioTrue">
          {{ 'content.modal-save-topic-as-custom.option-1' | translate }}
          <span class="recommended">
            {{ 'content.modal-save-topic-as-custom.recommended' | translate }}
          </span>
        </label>
      </div>
      <div class="form-check pg3 mb-2 radio-item">
        <input class="form-check-input"
               [(ngModel)]="shouldReplaceAlertTopic"
               type="radio"
               [value]="false"
               name="flexRadioFalse"
               id="flexRadioFalse"
        >
        <label class="form-check-label" for="flexRadioFalse">
          {{ 'content.modal-save-topic-as-custom.option-2' | translate }}
        </label>
      </div>
    </div>
    <div>
      <hr class="hr">
      <div class="w-100 d-flex justify-content-end">
        <button type="button" (click)="hideModal()"
                class="btn btn2 btn-outline-primary">{{ 'content.modal-save-topic-as-custom.annuler' | translate }}</button>
        <button type="button" class="btn btn1 btn-primary" data-cy="topic-save-as-custom-button"
                (click)="createTopic()">{{ 'content.modal-save-topic-as-custom.valider' | translate | uppercase }}</button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #saveTopicToast let-toast="toast" let-toastService="toastService">
  <div class="position-relative d-flex w-100 h-100">
    <div class="position-absolute toast-icon-div icon--close align-self-center" (click)="toastService.remove(toast)">
    </div>
    <img src="assets/images/icon-checkmark-circle.svg" class="align-self-center toast-image" alt="icon-checkmark">
    <div class="fw-bold align-self-center toast-text">
      <ng-container *ngIf="topicId && !isCopy; else create">
        <p class="m-0">{{ 'content.topic-toast-edit-content' | translate:({name: name})}}</p>
      </ng-container>
      <ng-template #create>
        <p class="m-0">{{ 'content.topic-toast-content' | translate:({name: name})}}</p>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-template #alertsModified let-toast="toast" let-toastService="toastService">
  <div class="position-relative d-flex w-100 h-100">
    <div class="position-absolute toast-icon-div icon--close align-self-center" (click)="toastService.remove(toast)">
    </div>
    <ng-container *ngIf="alertsNotUpdated.length == 0; else errorUpdate">
      <img src="assets/images/icon-checkmark-circle.svg" class="align-self-center toast-image" alt="icon-checkmark">
      <div class="fw-bold align-self-center toast-text">
        {{ 'content.topic-alerts-successfully-modified' | translate }}
      </div>
    </ng-container>
    <ng-template #errorUpdate>
      <img src="../../../assets/images/icon-alert-triangle-white.svg" class="align-self-center toast-image"
           alt="icon-alert">
      <div class="fw-bold align-self-center toast-text">
        <p class="m-0">{{ 'content.topic-alerts-error-on-modification' | translate:({name: name})}}</p>
        <ul class="toast-ul" *ngFor="let alert of alertsNotUpdated">
          <li>{{alert}}</li>
        </ul>
      </div>
    </ng-template>
  </div>
</ng-template>

