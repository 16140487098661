<div class="container">
  <div class="smartgrid-container">
    <div class="loader-container" *ngIf="showLoader">
      <app-simple-loader
        [loaderText]="'tenders.smart-grid.grid-loader' | translate"
        [vertical]="true">
      </app-simple-loader>
    </div>
    <div *ngIf="!showLoader" class="not-available">
      <ex-empty-state *ngIf="!tenderDceProjectUid"
                      [svgIcon]="'close-circle-fill'"
                      [title]="'tenders.smart-grid.grid-not-available-title' | translate">
        <div *exEmptyStateContent class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-no-dce' | translate}}</span>
        </div>
      </ex-empty-state>
      <ex-empty-state *ngIf="tenderDceProjectUid && checkIfTenderOpenedAfterLimitDate()"
                      [svgIcon]="'close-circle-fill'"
                      [title]="'tenders.smart-grid.grid-not-available-title' | translate">
        <div *exEmptyStateContent class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-expired-market' | translate}}</span>
        </div>
      </ex-empty-state>
    </div>

    <div *ngIf="!showLoader && tenderDceProjectUid && !checkIfTenderOpenedAfterLimitDate()">
      <div *ngIf="!publicQuestions.length && !privateQuestions.length" class="not-available">
        <div class="not-available-title">
          <span>{{'tenders.smart-grid.grid-not-available-title' | translate}}</span>
        </div>
        <div class="not-available-text">
          <span>{{'tenders.smart-grid.grid-not-available-failed' | translate}}</span>
        </div>
      </div>
      <ng-container
        *ngTemplateOutlet="questionCard;
            context: {questions: getCctpQuestion(), type: GridQuestionTypeEnum.PUBLIC}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="questionCard;
        context: {questions: privateQuestions, type: GridQuestionTypeEnum.PRIVATE}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="questionCard;
        context: {questions: getNonCctpQuestions(), type: GridQuestionTypeEnum.PUBLIC}">
      </ng-container>
    </div>


  </div>
</div>
<ng-template #tooltipCopy>
  <span>{{'common.copy-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipCopied>
  <span>{{'common.copied-to-clipboard' | translate}}</span>
</ng-template>
<ng-template #tooltipEditDelete>
  <span>{{'common.edit-delete' | translate}}</span>
</ng-template>

<ng-template #questionCard let-questions="questions" let-type="type">
  <div *ngIf="questions.length || type == GridQuestionTypeEnum.PRIVATE">
    <div class="header">
      <div class="header-title">
        <span
          *ngIf=" type == GridQuestionTypeEnum.PUBLIC && questions[0].isCctpQuestion()">{{ questions[0].displayedName | uppercase }}
        </span>
        <span
          *ngIf="type == GridQuestionTypeEnum.PUBLIC && !questions[0].isCctpQuestion()">{{ 'tenders.smart-grid.type.public' | translate | uppercase }}
        </span>
        <div class="private-question-header" *ngIf="type == GridQuestionTypeEnum.PRIVATE">
          <span>{{ 'tenders.smart-grid.type.private' | translate | uppercase }}</span>
          <button class="add-question-button"
                  [ngClass]="{'disabled': privateQuestions.length >= 5}"
                  *ngIf="tenderDceProjectUid"
                  (click)="onClickAddQuestion()"
                  [disabled]="privateQuestions.length >= 5"
                  [ngbTooltip]="privateQuestions.length >= 5
                ? ('tenders.smart-grid.max-questions' | translate) :'' "
                  tooltipClass="simple-tooltip"
                  placement="bottom auto" [openDelay]="500"
          >
            <mat-icon svgIcon="plus"></mat-icon>
            <span>{{'tenders.tender-detail.add' | translate}}</span>
          </button>
        </div>

      </div>
    </div>
    <div class="cards-container">
      <div class="text no-private-questions" *ngIf="type == GridQuestionTypeEnum.PRIVATE && !questions.length">
        {{'tenders.smart-grid.no-private-questions' | translate}}
      </div>
      <div *ngFor="let question of questions; trackBy: trackByQuestionId"
           class="card-item"
           [ngClass]="{
                    'active': checkIfQuestionIsSelected(question),
                    'disabled': (!question.answer && (type === GridQuestionTypeEnum.PUBLIC || !type) && !question.isCctpQuestion()),
                    'disabled-private': !question.answer && type === GridQuestionTypeEnum.PRIVATE,
                    'cctp-question': question.isCctpQuestion(),
                    'not-available-answer': question.isGenerationFinished && !question.answer && question.isCctpQuestion(),
                 }"
           (click)="onClickQuestion(question)"
      >
        <div class="question-card-content">
          <div class="text no-private-questions"
               *ngIf="question.isCctpQuestion() && !question.answer &&  question.isGenerationFinished &&  question.isGenerationFinished">
            {{'tenders.smart-grid.no-cctp-answer' | translate}}
          </div>
          <div class="question-card-header"  *ngIf="!question.isCctpQuestion()">
            <div [ngClass]="{'not-available-answer': question.isGenerationFinished && !question.answer}"
                 class="question-card-title">
              {{question.displayedName}}
              <img
                *ngIf="type == GridQuestionTypeEnum.PRIVATE"
                [ngbTooltip]="tooltipEditDelete"
                tooltipClass="custom-tooltip-smart-grid-cards"
                placement="top"
                class="icon-edit"
                src="/assets/images/icon-pencil-filled.svg" alt="arrow-circle" height="16"
                width="16"
                (click)="editQuestion(question)">
              <div
                *ngIf="!question.answer && question.isGenerationFinished && question.isGenerationFailed  && !question.isCctpQuestion()"
                class="text not-available-answer-text">
                ({{'tenders.smart-grid.card-not-available-failed' | translate}})
              </div>
              <div *ngIf="!question.answer && question.isGenerationFinished && !question.isGenerationFailed"
                   class="text not-available-answer-text">
                ({{'tenders.smart-grid.card-not-available-expired-market' | translate}})
              </div>
            </div>
            <div class="card-selected-icons-container">
              <i
                *ngIf="!isCopied"
                class="ph-fill ph-copy copy-to-clipboard"
                (click)="copyToClipboard($event, question)"
                [ngbTooltip]="tooltipCopy"
                tooltipClass="custom-tooltip-smart-grid-cards"
                placement="bottom"
              ></i>
              <i
                *ngIf="isCopied"
                class="ph-fill ph-copy copy-to-clipboard copied"
                (click)="copyToClipboard($event, question)"
                [ngbTooltip]="tooltipCopied"
                tooltipClass="custom-tooltip-smart-grid-cards"
                placement="bottom"
              ></i>
              <button *ngIf="!isEqual(clickedAnswer, question.answer) && question.answer">
                {{ 'common.view' | translate }}
              </button>
              <button *ngIf="question.answer && isEqual(clickedAnswer, question.answer)">
                {{ 'common.close' | translate }}
              </button>
            </div>
          </div>
          <div *ngIf="!question.isGenerationFinished || question.answer" class="question-card-body"
               [ngClass]="question.isCctpQuestion() ? 'cctp-card-body' : ''"
          >
            <div class="loaders" *ngIf="!question.isGenerationFinished">
              <ngx-skeleton-loader/>
              <ngx-skeleton-loader *ngIf="question.isCctpQuestion()"/>
              <ngx-skeleton-loader *ngIf="question.isCctpQuestion()"/>
            </div>
            <div *ngIf="question.isGenerationFinished && question.answer" class="answer-container">
              <div>

                <div class="text"
                     [ngClass]="{
                                  'cropped-answer': !checkIfQuestionIsSelected(question) && !question.isCctpQuestion(),
                                  'full-answer': checkIfQuestionIsSelected(question) || question.isCctpQuestion()
                                }">
                  <div *ngIf="question.answer.textWithRefs">
                    <ng-container *ngFor="let item of question.answer.textWithRefs">{{item.text}}<a
                      *ngIf="item.ref" class="answer-ref"
                      (click)="onClickRef(item, question)"
                      [ngClass]="{ 'active': clickedRefIndex === item.index }">{{item.index + 1}}</a>
                    </ng-container>
                  </div>
                  <div *ngIf="!question.answer.textWithRefs.length">
                    {{question.answer.text}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
