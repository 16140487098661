import { Territory } from './territory';

export class TerritoryManager {
  territory: Territory;
  references: {[uid: string]: Territory} = {};
  syndicates: {[scope: string]: Territory[]} = {};
  citySyndicates: {[uid: string]: string[]} = {};

  constructor(territory: Territory) {
    this.references[territory.uid] = territory;
    for (const item of territory.territories) {
      this.references[item.uid] = item;
      if (item.isSyndicate) {
        for (const scope of item.syndicateScopes ?? []) {
          this.syndicates[scope] = [...this.syndicates[scope] ?? [], item];
        }
        item.territories.forEach((city: Territory) => {
          if (city.name === 'inside_department') {
            city.territories.forEach((subCity: any) => {
              this.citySyndicates[subCity.uid] = [...this.citySyndicates[subCity.uid] ?? [], item.uid];
            });
            return;
          }
          this.citySyndicates[city.uid] = [...this.citySyndicates[city.uid] ?? [], item.uid];
        });
      }
    }
    territory.territories = territory.territories.filter((child: Territory) => !child.isSyndicate);
    this.territory = territory;
    this.syndicates = TerritoryManager.sortSyndicates(this.syndicates);
  }

  static sortSyndicates(syndicates: {[scope: string]: Territory[]}): {[scope: string]: Territory[]} {
   return Object.keys(syndicates).sort().reduce(
      (res: any, key: string) => {
        res[key] = syndicates[key];
        res[key].sort((a: any, b: any) => a.name < b.name ? -1 : (a.name > b.name ? 1 : 0) );
        return res;
      },
      {}
    );
  }

  getCorrespondingSyndicates(cities: Array<string>): Array<string> {
    let syndicates: Array<string> = [];
    for (const city of cities) {
      syndicates = [...syndicates, ...this.citySyndicates[city] ?? []];
    }
    return [...new Set(syndicates)];
  }
}
