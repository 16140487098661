import { Component, Input, OnInit } from '@angular/core';
import { ApiTenderService } from "../../services/api/api-tender.service";
import { Tender } from "../../models/tender";

@Component({
  selector: 'in-watch-occurrence',
  templateUrl: './in-watch-occurrence.component.html',
  styleUrls: ['./in-watch-occurrence.component.scss']
})
export class InWatchOccurrenceComponent implements OnInit {
  @Input({required: true}) tender!: Tender;

  constructor(private apiService: ApiTenderService) {
  }

  ngOnInit() {
    if (this.tender.id) {
      if (this.tender.watchAppearance) return;
      this.apiService.marketWatch.tenderAppearedInMarketWatches(this.tender.id)
        .then((res) => {
          this.tender.watchAppearance = res.data.at(0)?.appeared_in_watch ?? null;
        })
    }
  }
}
