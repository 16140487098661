<div class="modal-header">
  <h4 class="modal-title-rename" id="modal-basic-title" >
    {{item.item_type + '.modal-rename-title' | translate}}
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()">

  </button>
</div>
<div class="modal-body-rename">
  <div class="fw-bold message">
    {{item.item_type + '.modal-rename-body-title' | translate}}</div>
  <div class="input-name">
    <div class="input-container">
      <input
        autocomplete="off"
        type="text"
        data-cy="modal-name-input"
        [formControl]="editItemNameForm"
        [value]="editItemNameForm.value"
        ngbAutofocus
        (keyup.enter)="renameItem()"
      >
    </div>
    <div class="d-flex">
      <div *ngIf="editItemNameForm.dirty && (editItemNameForm?.errors || nameIsEmpty || displayAlreadyExists  || displayNameNotAllowed)"
           class="error-container d-inline align-items-baseline">

        <div *ngIf="nameIsEmpty" class="d-flex align-items-baseline">
          <div class="error-icon">
            <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
          </div>
          <div class="error-text">
            {{item.item_type + '.modal-rename-error-required' | translate}}
          </div>
        </div>
        <div *ngIf="editItemNameForm && editItemNameForm.errors && editItemNameForm.errors['maxlength']"
             class="d-flex align-items-baseline">
          <div class="error-icon">
            <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
          </div>
          <div class="error-text">
            {{item.item_type + '.modal-rename-error-maxlength' | translate}}
          </div>
        </div>
        <div *ngIf="displayAlreadyExists" class="d-flex align-items-baseline">
          <div class="error-icon">
            <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
          </div>
          <div class="error-text">
            {{item.item_type + '.modal-rename-error-existing-name' | translate}}
          </div>
        </div>
        <div *ngIf="displayNameNotAllowed" class="d-flex align-items-baseline">
          <div class="error-icon">
            <img src="../../../assets/images/icon-alert-triangle.svg" alt="error-icon">
          </div>
          <div class="error-text">
            {{item.item_type + '.modal-rename-error-not-allowed-name' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="cancel fw-bold" (click)="close()">{{item.item_type + '.cancel' | translate}}</button>
  <div>
    <button [disabled]="editItemNameForm.errors || nameIsEmpty || displayAlreadyExists || displayNameNotAllowed" type="button" class="btn confirm-rename d-flex" (click)="renameItem()">
      <div class="text-uppercase fw-bold align-self-center">{{item.item_type + '.modal-edit-save' | translate}}</div>
    </button>
  </div>
</div>
